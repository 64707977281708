import React, { Suspense, useMemo, useContext } from 'react';
import GlobalMessageContextProvider from 'features/context/GlobalMessageContext';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";
import RequireAuth from 'features/login/RequireAuth';
import CommonProvider from 'features/context/CommonProvider';
import SupportProvider from 'features/context/SupportProvider';
import NewsProvider from 'features/context/NewsProvider';
import AnnouncementProvider from 'features/context/AnnouncementProvider';
import { SnackbarProvider } from 'notistack'
import CheckAppVersion from 'features/checkAppVersion/CheckAppVersion';
import {
  CircularProgress,
  Backdrop,
  Box,
} from '@mui/material';

// Pages
import Login from 'features/login/Login';
import Layout from 'features/layout/Layout';
import Home from 'features/home/Home';
import News from 'features/news/News';
import Wallet from 'features/wallet/Wallet';
import Games from 'features/games/Games';
import Game from 'features/games/Game';
import Deposit from 'features/deposit/Deposit';
import Withdrawal from 'features/withdrawal/Withdrawal';
import Redeem from 'features/redeem/Redeem';
import LuckyDraw from 'features/luckyDraw/LuckyDraw';
import WalletHistory from 'features/wallet/History';
import Referral from 'features/referral/Referral';
import Contact from 'features/contact/Contact';
import Account from 'features/account/Account';
import Settings from 'features/settings/Settings';
import IPUnblock from 'features/ipUnblock/IPUnblock';
import SocialChannels from 'features/socialChannels/SocialChannels';
import Chats from 'features/chats/Chats';
import PWAHelmet from 'features/pwa/Helmet';
import ThemeContext from 'features/context/themeContext';
import { createTheme } from '@mui/material/styles';
import { ProvideChats } from 'hooks/useChats';
import { ProvideUserFavourite } from 'hooks/useUserFavourite';

// Themes
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  const { theme: selectedTheme, fontImportUrls } = useContext(ThemeContext);

  const muiTheme = useMemo(
    () => {
      if (!selectedTheme) {
        return null;
      }

      const theme = createTheme(selectedTheme);
      return theme;
    }, [selectedTheme]
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/unblock" element={
          <>
            <PWAHelmet />
            <IPUnblock />
          </>
          }>
          <Route path="/unblock/:unblockCode" element={<PWAHelmet />}/>
        </Route>
        <Route path="/login" element={
          <>
            <PWAHelmet />
            <SupportProvider>
              <AnnouncementProvider>
                <Login />
              </AnnouncementProvider>
            </SupportProvider>
          </>
        }>
          <Route path="/login/:token" element={
            <>
              <PWAHelmet />
              <SupportProvider>
                <AnnouncementProvider>
                  <Login />
                </AnnouncementProvider>
              </SupportProvider>
            </>
          } />
        </Route>
        <Route path="/" element={
          <RequireAuth>
            <PWAHelmet />
            <CommonProvider>
              <NewsProvider>
                <AnnouncementProvider>
                  <ProvideChats>
                    <Layout />
                  </ProvideChats>
                </AnnouncementProvider>
              </NewsProvider>
            </CommonProvider>
          </RequireAuth>
        }>
          <Route index element={<Home />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="wallet/deposit">
            <Route path="" element={<Deposit />} />
            <Route path=":amount/:rewardFlag" element={<Deposit />} />
            <Route path=":amount/:rewardFlag/:bankId" element={<Deposit />} />
          </Route>
          <Route path="wallet/withdrawal" element={<Withdrawal />} />
          <Route path="wallet/history" element={<WalletHistory />} />
          <Route path="games" element={
            <ProvideUserFavourite>
              <Games />
            </ProvideUserFavourite>
          } />
          <Route path="games/:gameType" element={
            <ProvideUserFavourite>
              <Game />
            </ProvideUserFavourite>
          } />
          <Route path="redeem" element={<Redeem />} />
          <Route path="free" element={<LuckyDraw />} />
          <Route path="referral" element={
            <SupportProvider>
              <Referral />
            </SupportProvider>
          } />
          <Route path="contact" element={
            <SupportProvider>
              <Contact />
            </SupportProvider>
          } />
          <Route path="social-channels" element={<SocialChannels />} />
          <Route path="account" element={<Account />} />
          <Route path="settings" element={<Settings />} />
          <Route path="news/:newsId" element={<News />} />
          <Route path="chat" element={<Chats />} />
          <Route path="*" element={<Navigate to='/' replace />} />
        </Route>
      </>
    )
  );

  function Loader() {
    return (
      <Backdrop open={true} style={{ zIndex: 9999 }} sx={{
        bgcolor: 'rgba(0, 0, 0, .95)',
      }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      </Backdrop>
    );
  }

  if (!muiTheme) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <MuiThemeProvider theme={muiTheme}>
        <style>
          {fontImportUrls.map((url, index) => {
            return `@import url('${url}');`;
          })}
        </style>
        <CssBaseline />
        <SnackbarProvider maxSnack={1}>
          <GlobalMessageContextProvider>
            <CheckAppVersion />
            <RouterProvider router={router} />
          </GlobalMessageContextProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </Suspense>
  );
}

export default App;
