import * as React from 'react';
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton';
import useImageDownloader from 'hooks/useImageDownloader';

export default function ProofOfTransfer(props) {
  const { potId, ...rest } = props;
  const imageSx = props?.sx || {};

  const { image, imageReady } = useImageDownloader({
    serviceName: 'proof-of-transfers',
    id: potId
  });

  if (!imageReady) return <Skeleton sx={imageSx} pulse='wave' variant='rectangular' width='100%' height='320px' />;
  return <Box {...rest} component='img' alt={potId} src={image} />
}
