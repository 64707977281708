import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object().shape({
  unblockCode: Yup.string()
    .required('Required')
    .matches(/^[0-9]{5,6}$/, 'Must be 5-6 digits')
});

export default function UnblockForm() {
  const { unblockCode } = useParams();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { t } = useTranslation();

  const handleSubmit = async ({ unblockCode }) => {
   try {
      await feathers.service('ip-unblock').remove(unblockCode);
      setGlobalMessage({
        message: t('Success'),
        severity: 'success'
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <Card sx={{ my: 2 }}>
        <CardContent>
          <Formik
            initialValues={{ unblockCode }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Field
                  as={TextField}
                  required
                  id="unblock-code"
                  name="unblockCode"
                  label={t('Unblock Code')}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={{ marginBottom: '16px' }}
                  error={touched.unblockCode && Boolean(errors.unblockCode)}
                  helperText={touched.unblockCode && errors.unblockCode}
                />
                <Button type="submit" variant="contained" color="primary">
                  {t('Submit')}
                </Button>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}
