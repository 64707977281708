import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useAuth } from 'hooks/useAuth';
import { get, find, includes } from 'lodash';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import CommonContext from 'features/context/commonContext';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/RefreshTwoTone';
import RenewIcon from '@mui/icons-material/AutorenewTwoTone';
import Decimal from 'decimal.js';
import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/ContentCopyTwoTone';
import CopyToClipboard from 'features/copyToClipboard/CopyToClipboard';
import MoreIcon from '@mui/icons-material/MoreVertTwoTone';
import DownloadIcon from '@mui/icons-material/DownloadForOfflineTwoTone';
import GameIcon from '@mui/icons-material/NoAdultContentTwoTone';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import KioskCaptcha from '../Captcha.js';
import { keyframes } from '@mui/system';
import DownloadDialog from '../DownloadDialog';
import CountUp from 'react-countup';
import AddIcon from '@mui/icons-material/AddTwoTone';
import MinusIcon from '@mui/icons-material/RemoveTwoTone';
import RecentPayoutsCard from '../RecentPayoutsCard.js';
import isIOS from 'utils/isIOS.js';
import useGameLogo from 'hooks/useGameLogo';
import BalanceTransferDialog from 'features/balanceTransferDialog/BalanceTransferDialog.js';
import FavouriteGame from './FavouriteGame';

const CAPTCHA_KIOSKS = [
  '1slot',
  '918kaya',
  'ace333',
  'king855',
  'mario-club',
  'xe88',
];
const MIN_AMOUNT = 0.01;
const MAX_AMOUNT = 30000;

const AUTO_START_GAMES = [
  'ace333',
  'csc',
  'gw99',
  'live22',
  'mario-club',
  'playboy',
  'vpower',
];

export default function Game() {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { gameType } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { wallet, walletReady, games, gamesReady, gameIds, gameIdsReady } = useContext(CommonContext);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [selectedDownload, setSelectedDownload] = useState(null);
  const [cuBalance, setCuBalance] = useState({ start: 0, end: 0 });
  const passwordRef = useRef();
  const [notFound, setNotFound] = useState(false);
  const [status, setStatus] = useState('idle');
  const { getGameImagePath } = useGameLogo();
  const [ openBalanceTransferDialog, setOpenBalanceTransferDialog ] = useState({
    open: false,
    mode: '',
    minAmount: 0,
    maxAmount: 0,
    presetAmountMultiplier: [0.5, 1]
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isIdle = useMemo(
    () => {
      return (status === 'idle') ? true : false;
    }, [status]
  );

  const showLinearProgress = useMemo(
    () => {
      const supported = ['create', 'patch-password', 'patch-release', 'startGame'];
      return includes(supported, status);
    }, [status]
  );

  const showBalanceProgress = useMemo(
    () => {
      const supported = ['transfer', 'patch-balance'];
      return includes(supported, status);
    }, [status]
  );

  const captchaPattern = useMemo(
    () => {
      if (
        includes([
          'ace333',
          'xe88',
        ], gameType))
        return /^[0-9]{5}$/;

      if (
        includes([
          '1slot',
          '918kaya',
          'mario-club',
        ], gameType))
        return /^[0-9]{4}$/;

      if (
        includes([
          'king855',
        ], gameType))
        return /^[0-9a-zA-Z]{4}$/;

      return /^[0-9]{6}$/;
    }, [gameType]
  );

  const gameId = useMemo(
    () => {
      if (!gameIdsReady) return null;
      const findGameId = find(gameIds, { gameType: gameType });

      if (!findGameId) return null;
      return findGameId;
    }, [gameIds, gameIdsReady, gameType]
  );

  const gameBalance = useMemo(
    () => {
      if (!gameId) return 0;
      const gameBalance = new Decimal(get(gameId, 'balance.$numberDecimal', '0'));
      return gameBalance.toNumber();
    }, [gameId]
  );

  const supportAutoStart = useMemo(
    () => {
      return includes(AUTO_START_GAMES, gameType) && !!gameId;
    }, [gameType, gameId]
  );

  useEffect(() => {
    setCuBalance(prev => {
      if (prev?.end === gameBalance) {
        return prev;
      }
      return {
        start: prev?.end, end: gameBalance
      }
    });
  }, [gameBalance]);

  const gameUsername = useMemo(
    () => {
      if (!gameId) return '';
      return gameId?.gameId;
    }, [gameId]
  );

  const gamePassword = useMemo(
    () => {
      if (!gameId) return '';
      return gameId?.gamePassword;
    }, [gameId]
  );

  const decCashBalance = useMemo(
    () => {
      if (!walletReady) new Decimal(0);
      const cashBalance = new Decimal(get(wallet, 'cashBalance.$numberDecimal', '0'));
      return cashBalance;
    }, [walletReady, wallet]
  );

  const decMinDpAmount = useMemo(
    () => {
      const min = new Decimal(MIN_AMOUNT);
      return decCashBalance.lt(min) ? decCashBalance : min;
    }, [decCashBalance]
  );

  const decMaxDpAmount = useMemo(
    () => {
      const max = new Decimal(MAX_AMOUNT);
      return decCashBalance.gt(max) ? max : decCashBalance;
    }, [decCashBalance]
  );

  const decMinWdAmount = useMemo(
    () => {
      const min = new Decimal(MIN_AMOUNT);
      const decGameBalance = new Decimal(gameBalance);
      return decGameBalance.lt(min) ? decGameBalance : min;
    }, [gameBalance]
  );

  const decMaxWdAmount = useMemo(
    () => {
      const max = new Decimal(MAX_AMOUNT);
      const decGameBalance = new Decimal(gameBalance);
      return decGameBalance.gt(max) ? max : decGameBalance;
    }, [gameBalance]
  );

  const game = useMemo(
    () => {
      if (!gamesReady) return null;
      const findGame = find(games, { type: gameType, isEnabled: true });
      if (!findGame) {
        setNotFound(true);
        return null;
      } else {
        return findGame;
      }
    }, [games, gamesReady, gameType]
  );

  const recentPayouts = useMemo(
    () => {
      const { recentPayouts = [] } = game || {};
      return recentPayouts;
    }, [game]
  );

  useEffect(() => {
    if (!notFound) return;
    navigate('/games', { replace: true });
  }, [notFound, navigate]);

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('balance-transfers');

    const onPatched = (data) => {
      const state = get(data, 'state');
      const type = get(data, 'type');

      if (isMounted) {
        setStatus('idle');
        if (state === 'done') {
          setGlobalMessage({
            message: t(`point ${type} approved`),
            severity: 'success'
          });
        } else if (state === 'canceled') {
          setGlobalMessage({
            message: t(`point ${type} rejected`),
            severity: 'error'
          });
        } else if (state === 'manual') {
          setGlobalMessage({
            message: t(`point ${type} manual`),
            severity: 'info'
          });
        }
      }
    };

    const onRemoved = (data) => {
      const type = get(data, 'type');
      if (isMounted) {
        setStatus('idle');
        setGlobalMessage({
          message: t(`point ${type} rejected`),
          severity: 'error'
        });
      }
    };

    service.on('patched', onPatched);
    service.on('removed', onRemoved);

    return () => {
      isMounted = false;
      service.removeListener('patched', onPatched);
      service.removeListener('removed', onPatched);
    };

  }, [setGlobalMessage, t]);

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('game-ids');

    const setStatusToIdle = () => {
      if (isMounted) setStatus('idle');
    };

    const onFailed = (data) => {
      if (isMounted) {
        const { error } = data;
        setStatus('idle');
        setGlobalErrorMessage({ err: error });
      }
    };

    service.on('created', setStatusToIdle);
    service.on('patched', setStatusToIdle);
    service.on('removed', setStatusToIdle);
    service.on('failed', onFailed);

    return () => {
      isMounted = false;
      service.removeListener('created', setStatusToIdle);
      service.removeListener('patched', setStatusToIdle);
      service.removeListener('removed', setStatusToIdle);
      service.removeListener('failed', onFailed);

    };
  }, [setGlobalErrorMessage, t]);

  useEffect(() => {
    if (!passwordRef || !passwordRef.current || !gamePassword) return;

    passwordRef?.current?.animate({
      opacity: [0, 1, 1],
      transform: ['rotateX(90deg)', 'rotateX(180deg)', 'rotateX(360deg)'],
      easing: ['ease-in', 'ease-out']
    }, {
      duration: 1000,
      iterations: 1
    });
  }, [gamePassword, passwordRef]);

  const noIdAnimation = useMemo(
    () => {
      return keyframes`
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.6;
        },
        100% {
          opacity: 1;
        }
      `;
    }, []
  );

  function handleAccountMenuClick(event) {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleCreateGameId = async (event) => {
    event.preventDefault();
    setAnchorEl(null);

    if (!isIdle || !!gameId) return;

    try {
      setStatus('create');
      await feathers.service('game-ids').create({
        gameType,
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
      setStatus('idle');
    }
  };

  const handleBalanceCheck = async (event) => {
    event.preventDefault();
    setAnchorEl(null);

    if (!isIdle || !gameId) return;

    try {
      setStatus('patch-balance');
      const { _id: gameUid } = gameId;
      await feathers.service('game-ids').patch(gameUid, {
        lastBalanceCheckRequestAt: new Date(),
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
      setStatus('idle');
    }
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setAnchorEl(null);

    if (!isIdle || !gameId) return;

    try {
      setStatus('patch-password');
      const { _id: gameUid } = gameId;
      await feathers.service('game-ids').patch(gameUid, {
        lastPasswordResetRequestAt: new Date(),
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
      setStatus('idle');
    }
  };

  const handleIdRelease = async (event) => {
    event.preventDefault();
    setAnchorEl(null);

    if (!isIdle || !gameId) return;

    try {
      setStatus('patch-release');
      const { _id: gameUid } = gameId;
      await feathers.service('game-ids').patch(gameUid, {
        lastIdReleaseRequestAt: new Date(),
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
      setStatus('idle');
    }
  };

  const handleDownloadPressed = (event) => {
    event.preventDefault();
    setSelectedDownload(game);
  };

  const handleDownloadDialogClose = () => {
    setSelectedDownload(null);
  };

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('game-ids');

    const onTaskProcessed = (data) => {
      const { action } = data;
      if (action !== 'startGame') return;
      onStartGame(data);
    }

    const onStartGame = (data) => {
      setStatus('idle');
      const lobbyUrl = get(data, 'lobbyUrl');
      const success = get(data, 'success');

      if (!success) {
        setGlobalErrorMessage({ err: new Error('Failed to start game') });
        return;
      }

      if (lobbyUrl && isMounted) {
        const target = isIOS() ? '_self' : '_blank';
        window.open(lobbyUrl, target);
      }
    };

    service.on('processed', onTaskProcessed);

    return () => {
      isMounted = false;
      service.removeListener('processed', onTaskProcessed);
    };
  }, [setGlobalErrorMessage, t]);

  async function handleAutoStart(event) {
    event.preventDefault();
    setAnchorEl(null);

    if (!gameId || !isIdle) return;

    setStatus('startGame');

    try {

      const { _id: gameUid } = gameId;
      await feathers.service('game-ids').patch(gameUid, {
        lastGameStartRequestAt: new Date(),
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
      setStatus('idle');
    }
  }

  function onDepositClick(event) {
    event.preventDefault();
    setOpenBalanceTransferDialog({
      open: true,
      mode: 'deposit',
      minAmount: decMinDpAmount.toNumber(),
      maxAmount: decMaxDpAmount.toNumber(),
    });
  }

  function onWithdrawalClick(event) {
    event.preventDefault();
    setOpenBalanceTransferDialog({
      open: true,
      mode: 'withdrawal',
      minAmount: decMinWdAmount.toNumber(),
      maxAmount: decMaxWdAmount.toNumber(),
    });
  }

  function handleBalanceTransferDialogClose() {
    setOpenBalanceTransferDialog({
      open: false,
      mode: '',
      minAmount: 0,
      maxAmount: 0,
    });
  }

  function handleBalanceTransferDialogSubmit({ amount, mode }) {
    createBalanceTransferAsync(amount, mode);
    setOpenBalanceTransferDialog({ ...openBalanceTransferDialog, open: false, mode: ''});
  }

  async function createBalanceTransferAsync (amount, mode) {
    try {
      setStatus('transfer');
      await feathers.service(`/balance-transfers`).create({
        amount: amount,
        type: mode,
        gameType
      });
    } catch (err) {
      setStatus('idle');
      setGlobalErrorMessage({ err });
    }
  }

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      {
        !!selectedDownload && <DownloadDialog game={selectedDownload} lang={lang} onClose={handleDownloadDialogClose} />
      }
      <BalanceTransferDialog
        open={openBalanceTransferDialog.open}
        mode={openBalanceTransferDialog.mode}
        minAmount={openBalanceTransferDialog.minAmount}
        maxAmount={openBalanceTransferDialog.maxAmount}
        presetAmountMultiplier={openBalanceTransferDialog.presetAmountMultiplier}
        onClose={handleBalanceTransferDialogClose}
        onSubmit={handleBalanceTransferDialogSubmit}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {
            (!gamesReady || !gameIdsReady || !game) && <LinearProgress />
          }
          <Card>
            <CardHeader
              action={
                <Stack spacing={1} direction='row'>
                  <IconButton onClick={handleDownloadPressed}>
                    <DownloadIcon />
                  </IconButton>
                  <IconButton onClick={handleAccountMenuClick}>
                    <MoreIcon />
                  </IconButton>
                </Stack>
              }
              title={(
                <>
                  <Typography variant='h6'>
                    {game?.name[lang]}
                  </Typography>
                </>
              )}
              avatar={
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                }}>
                  <FavouriteGame gameType={gameType} />
                  <Avatar
                    src={getGameImagePath(gameType)}
                    variant="rounded"
                  />
                </Box>
              }
            />
            <Divider />
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <Grid container>
                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box>
                      <Typography variant='subtitle2'>
                        {
                          `${t('Game Balance')}`
                        }
                      </Typography>
                      <CountUp
                        start={cuBalance.start}
                        end={cuBalance.end}
                        duration={0.88}
                        separator=" "
                        decimals={2}
                        decimal="."
                        prefix=''
                        suffix=''
                      >
                        {({ countUpRef, start }) => (
                          <Typography variant="h3" ref={countUpRef} />
                        )}
                      </CountUp>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ my: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack direction='row' spacing={1}>
                      <Button disabled={!gameId} size='large' sx={{ borderRadius: 20 }} color='primary' fullWidth variant='contained' onClick={onDepositClick}>
                        <AddIcon />
                      </Button>
                      <LoadingButton color='secondary' disabled={!gameId} loading={showBalanceProgress} size='large' sx={{ borderRadius: 20 }} variant='contained' fullWidth onClick={handleBalanceCheck}>
                        <RefreshIcon />
                      </LoadingButton>
                      <Button disabled={!gameId} size='large' sx={{ borderRadius: 20 }} color='primary' fullWidth variant='contained' onClick={onWithdrawalClick}>
                        <MinusIcon />
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle2' color='textSecondary' sx={{ fontStyle: 'italic' }}>
                  {
                    t('Game ID')
                  }
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant='h6' gutterBottom>
                    {
                      gameUsername || '-'
                    }
                  </Typography>
                  <CopyToClipboard text={gameUsername} copiedText={t('Username copied')}>
                    <IconButton disabled={!gameUsername} size='small' color='info' variant='contained' sx={{ ml: 1 }}>
                      <CopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                </Box>
                <Typography variant='subtitle2' color='textSecondary' sx={{ fontStyle: 'italic' }}>
                  {
                    t('Game Password')
                  }
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant='h6' gutterBottom ref={passwordRef}>
                    {
                      gamePassword || '-'
                    }
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton disabled={!gamePassword} size='small' color='info' variant='contained' sx={{ ml: 1 }} onClick={handlePasswordReset}>
                      <RenewIcon />
                    </IconButton>
                    <CopyToClipboard text={gamePassword} copiedText={t('Password copied')}>
                      <IconButton disabled={!gamePassword} size='small' color='info' variant='contained' sx={{ ml: 1 }}>
                        <CopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </Box>
                </Box>
              </Box>
              {
                !!showLinearProgress && <LinearProgress />
              }
            </CardContent>
            <Divider />
            <CardActions sx={{ display: (!gameId || supportAutoStart) ? 'block' : 'none' }}>
              <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {
                  !gameId &&
                  <Grid item xs={12}>
                    <Button
                      size='large'
                      sx={{
                        fontWeight: 700,
                        animation: `${noIdAnimation} 1s ease infinite`,
                        animationDelay: '1.0s'
                      }}
                      color='primary'
                      fullWidth
                      variant='contained'
                      onClick={handleCreateGameId}
                    >
                      {t('Get game ID')}
                    </Button>
                  </Grid>
                }
                {
                  !!gameId && supportAutoStart &&
                  <Grid item xs={12}>
                    <Button
                      sx={{
                        fontWeight: 700
                      }}
                      color='primary'
                      fullWidth
                      startIcon={<GameIcon />}
                      endIcon={<GameIcon />}
                      variant='contained'
                      size='large'
                      onClick={handleAutoStart}
                    >
                      {t('Enter')}
                    </Button>
                  </Grid>
                }
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <RecentPayoutsCard recentPayouts={recentPayouts} />
        </Grid>
      </Grid>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <MenuItem onClick={handleIdRelease}>{t('Change game ID')}</MenuItem>
      </Menu>
      {
        includes(CAPTCHA_KIOSKS, gameType) ?
          <Grid item xs={12}>
            <KioskCaptcha captchaPattern={captchaPattern} />
          </Grid>
          : null
      }
    </Box>
  );
}
