import React, { useState, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useAuth } from 'hooks/useAuth';
import { get, unset } from 'lodash';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import feathers from 'services/feathers';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CommonContext from 'features/context/commonContext';
import SetPasswordDialog from './SetPasswordDialog';
import SetBankAccountDialog from './SetBankAccountDialog';

export default function Account(props) {
  const { user, update: updateUser } = useAuth();
  const userId = get(user, '_id');
  const hasPassword = get(user, 'hasPassword', false);
  const username = get(user, 'username', '');
  const nickname = get(user, 'nickname', '');
  const { t } = useTranslation();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { wallet } = useContext(CommonContext);
  const [ passwordDialog, setPasswordDialog ] = useState(false);
  const [ bankAccountDialog, setBankAccountDialog ] = useState(false);

  const savedBank = useMemo(
    () => {
      return get(wallet, 'savedBank');
    }, [wallet]
  );

  const userSchema = Yup.object().shape({
    nickname: Yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nickname: nickname,
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      const formattedValues = { ...values };

      if (get(values, 'password') === '') unset(formattedValues, 'password');

      try {
        const savedUser = await feathers.service('users').patch(userId, formattedValues);
        updateUser(savedUser);
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
        formik.setFieldValue('password', '');
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formik.isSubmitting) return;
    formik.submitForm();
  };

  const onSetPasswordClicked = (event) => {
    event?.preventDefault();
    setPasswordDialog(true);
  };

  const handlePasswordDialogClose = (event) => {
    event?.preventDefault();
    setPasswordDialog(false);
  };

  const onSetBankAccountClicked = (event) => {
    event?.preventDefault();
    setBankAccountDialog(true);
  };

  const handleBankAccountDialogClose = (event) => {
    event?.preventDefault();
    setBankAccountDialog(false);
  };

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card elevation={4}>
            <CardHeader disableTypography
              title={
                <Typography variant='h6'>
                  {t('Account')}
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled={true}
                    fullWidth
                    id="username"
                    name="username"
                    label={t('Username')}
                    value={username}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="nickname"
                    name="nickname"
                    label={t('Nickname')}
                    value={formik.values.nickname}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                    helperText={formik.touched.nickname && formik.errors.nickname}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Button variant="contained" onClick={handleSubmit}>
                {t('Save')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={16} sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {
                  hasPassword ?
                    <Button size='large' onClick={onSetPasswordClicked} fullWidth color='info' variant='contained'>{t('Update Password')}</Button> :
                    <Button size='large' onClick={onSetPasswordClicked} fullWidth color='info' variant='contained'>{t('Set Password')}</Button>
                }
              </Grid>
              <Grid item xs={12}>
                {
                  savedBank ?
                    <Button size='large' onClick={onSetBankAccountClicked} fullWidth color='info' variant='contained'>{t('Update Bank Account')}</Button> :
                    <Button size='large' onClick={onSetBankAccountClicked} fullWidth color='info' variant='contained'>{t('Set Bank Account')}</Button>
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <SetPasswordDialog open={passwordDialog} onClose={handlePasswordDialogClose} />
        <SetBankAccountDialog open={bankAccountDialog} onClose={handleBankAccountDialogClose} />
      </Grid>
    </Box>
  );
}
