import React, { useState, useContext, createContext, useEffect, useMemo, useCallback } from 'react';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

const UserFavouriteContext = createContext();

export function ProvideUserFavourite({ children }) {
  const userFavourite = useProvideUserFavourite();
  return <UserFavouriteContext.Provider value={userFavourite}>{children}</UserFavouriteContext.Provider>;
}

export const useUserFavourite = () => {
  return useContext(UserFavouriteContext);
};

function useProvideUserFavourite() {
  const [isIdle, setIsIdle] = useState(true);
  const [userFavourite, setUserFavourite] = useState([]);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const userFavouriteGames = useMemo(() => {
    const favouriteGames = userFavourite?.favouriteGames || [];
    return favouriteGames.map((item) => item.gameType);
  }, [userFavourite]);

  const userFavouriteGameTitles = useMemo(() => {
    const favouriteGameTitles = userFavourite?.favouriteGameTitles || [];
    return favouriteGameTitles;
  }, [userFavourite]);

  useEffect(() => {
    const fetchUserFavourite = async () => {
      try {
        setIsIdle(false);
        const res = await feathers.service('user-favourites').find();
        const { data } = res;
        const userFavourite = data[0];

        if (userFavourite) {
          setUserFavourite(userFavourite);
          return;
        }

        const created = await feathers.service('user-favourites').create({}).catch((err) => {
          // Ignore error if user-favourites already exists
        });
        setUserFavourite(created);
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        setIsIdle(true);
      }
    };

    fetchUserFavourite();
  }, [setGlobalErrorMessage]);

  const addFavouriteGame = useCallback(async (gameType) => {
    try {
      if (!userFavourite) return;
      const userFavouriteId = userFavourite._id;
      setIsIdle(false);
      const res = await feathers.service('user-favourites').patch(userFavouriteId, {}, {
        query: {
          $addToSet: { favouriteGames: { gameType }}
        }
      });

      setUserFavourite(res);
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setIsIdle(true);
    }
  }, [setGlobalErrorMessage, userFavourite]);

  const removeFavouriteGame = useCallback(async (gameType) => {
    try {
      if (!userFavourite) return;
      const userFavouriteId = userFavourite._id;
      setIsIdle(false);
      const res = await feathers.service('user-favourites').patch(userFavouriteId, {}, {
        query: {
          $pull: { favouriteGames: { gameType }}
        }
      });

      setUserFavourite(res);
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setIsIdle(true);
    }
  }, [setGlobalErrorMessage, userFavourite]);

  const addFavouriteGameTitle = useCallback(async (gameType, gameCode) => {
    try {
      if (!userFavourite) return;
      const userFavouriteId = userFavourite._id;

      setIsIdle(false);
      const res = await feathers.service('user-favourites').patch(userFavouriteId, {}, {
        query: {
          $addToSet: { favouriteGameTitles: { gameType, gameCode }}
        }
      });

      setUserFavourite(res);
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setIsIdle(true);
    }
  }, [setGlobalErrorMessage, userFavourite]);

  const removeFavouriteGameTitle = useCallback(async (gameType, gameCode) => {
    try {
      if (!userFavourite) return;
      const userFavouriteId = userFavourite._id;
      setIsIdle(false);
      const gameCodeString = `${gameCode}`;
      const res = await feathers.service('user-favourites').patch(userFavouriteId, {}, {
        query: {
          $pull: {
            favouriteGameTitles: {
              gameType,
              gameCode: gameCodeString
            }
          }
        }
      });

      setUserFavourite(res);
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setIsIdle(true);
    }
  }, [setGlobalErrorMessage, userFavourite]);


  return useMemo(() => {
    return {
      isIdle,
      userFavouriteGames,
      userFavouriteGameTitles,
      addFavouriteGame,
      removeFavouriteGame,
      addFavouriteGameTitle,
      removeFavouriteGameTitle
    };
  }, [isIdle, userFavouriteGames, userFavouriteGameTitles, addFavouriteGame, removeFavouriteGame, addFavouriteGameTitle, removeFavouriteGameTitle]);
}