import React, { useEffect, useMemo } from 'react';
import {
  useNavigate,
} from "react-router-dom";
import { useAuth } from 'hooks/useAuth';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Loader from 'features/loader/Loader';

export default function Relogin({ from }) {
  const navigate = useNavigate();
  const auth = useAuth();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const jwtToken = useMemo(
    () => {
      return localStorage.getItem('feathers-jwt');
    }, []
  );

  useEffect(() => {
    if (auth.user || !jwtToken || !auth.isIdle) {
      return;
    }

    async function relogin() {
      try {
        await auth.relogin();
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        navigate(from, { replace: true });
      }
    }
    relogin();
  }, [auth, from, navigate, setGlobalErrorMessage, jwtToken]);

  return <Loader open={true} />;
}
