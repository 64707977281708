import React, { useMemo } from "react";
import {
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import Decimal from "decimal.js";

const SUPPORTED_MODE = ['deposit', 'withdrawal'];

export default function BalanceTransferDialog(props) {
  const {
    mode,
    open,
    onClose,
    onSubmit,
    minAmount = 0,
    maxAmount = 0,
    supportedPersetAmount = ['1by2', 'max'],
  } = props;
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (mode === 'deposit') {
      return 'Game Point Deposit';
    }
    if (mode === 'withdrawal') {
      return 'Game Point Withdrawal';
    }
    return '';
  }, [mode]);

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required(t("Required"))
      .min(minAmount, t('Min amount', { amount: minAmount }))
      .max(maxAmount, t('Max amount', { amount: maxAmount })),
  });

  if (!open || !SUPPORTED_MODE.includes(mode)) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t(title)}</DialogTitle>
      <Formik
        initialValues={{
          amount: '0.00'
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit({
            amount: values.amount,
            mode,
          });
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="amount"
                      label={t("Amount")}
                      value={values.amount}
                      onBlur={handleBlur}
                      error={touched.amount && Boolean(errors.amount)}
                      helperText={touched.amount && errors.amount}
                      autoComplete="off"
                      tabIndex={-1}
                      inputMode="numeric"
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                      onChange={(event) => {
                        event.preventDefault();
                        const { value } = event.target;
                        const sanitizedValue = value.replace(/\D/g, '');
                        const amountRaw = new Decimal(sanitizedValue);
                        const amount = amountRaw.dividedBy(100);
                        const decMaxAmount = new Decimal(maxAmount);
                        const amountStr = amount.gt(decMaxAmount) ? decMaxAmount.toFixed(2) : amount.toFixed(2);
                        setFieldValue('amount', amountStr);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            {'🎰'}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {
                        supportedPersetAmount.map((presetType, index) => (
                          <Grid item key={index}>
                            <Button
                              size="small"
                              variant="contained"
                              color='info'
                              onClick={() => {
                                const decMaxAmount = new Decimal(maxAmount);
                                if (presetType === '1by2') {
                                  setFieldValue('amount', decMaxAmount.dividedBy(2).toFixed(2));
                                } else if (presetType === 'max') {
                                  setFieldValue('amount', decMaxAmount.toFixed(2));
                                } else {
                                  setFieldValue('amount', '0.00');
                                }
                              }}
                            >
                              {t(presetType)}
                            </Button>
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Grid>
                {
                  mode === 'deposit' && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                        {t("Overbet terms")}
                      </Typography>
                    </Box>
                  )
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} disabled={isSubmitting}>
                  {t("Cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="contained">
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </form>
          )
        }
      </Formik>
    </Dialog>
  );
}