import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { hex2rgba } from 'utils/color';
import { get } from 'lodash';

const SpanAnimation = styled(Box, {
  shouldForwardProp: (prop) => (
    prop !== 'stopAnimation' &&
    prop !== 'noNeonLights' &&
    prop !== 'direction'
  )
})(({ theme, stopAnimation = false, noNeonLights = false, direction }) => {

  const rgbPrimaryFade = hex2rgba(get(theme, 'palette.primary.main', '#FFFFFF'), 0.5);

  const hexPrimary = theme.palette.primary.main;

  const animation = useMemo(
    () => {
      let anim;

      switch (direction) {
        case 'top':
          anim = keyframes`
            25% {
              width: 100%;
              opacity: 1;
            }
            30%,
            100% {
              opacity: 0;
            }
          `;
          break;

        case 'right':
          anim = keyframes`
            0%,
            25% {
              opacity: 0;
              height: 0;
            }

            50% {
              opacity: 1;
              height: 100%;
            }

            55%,
            100% {
              height: 100%;
              opacity: 0;
            }
          `;
          break;

        case 'bottom':
          anim = keyframes`
            0%,
            50% {
              opacity: 0;
              width: 0;
            }

            75% {
              opacity: 1;
              width: 100%;
            }

            76%,
            100% {
              opacity: 0;
            }
          `;
          break;

        case 'left':
          anim = keyframes`
            0%,
            75% {
              opacity: 0;
              bottom: 0;
              height: 0;
            }
            100% {
              opacity: 1;
              height: 100%;
            }
          `;
          break;

        default:
      }

      if (direction === 'right') return `${anim} 3s linear infinite`;
      return `${anim} 3s ease-in-out infinite`;
    }, [direction]
  );

  const styles = useMemo(
    () => {
      switch (direction) {
        case 'top':
          return {
            top: 0,
            left: 0,
            width: 0,
            height: '5px',
            background: `linear-gradient(90deg, transparent 50%, ${rgbPrimaryFade}, ${hexPrimary})`
          }

        case 'right':
          return {
            top: 0,
            right: 0,
            width: '5px',
            height: 0,
            background: `linear-gradient(180deg, transparent 30%, ${rgbPrimaryFade}, ${hexPrimary})`
          }

        case 'bottom':
          return {
            right: 0,
            bottom: 0,
            height: '5px',
            background: `linear-gradient(90deg, ${hexPrimary}, ${rgbPrimaryFade}, transparent 50%)`
          }

        case 'left':
          return {
            left: 0,
            bottom: 0,
            width: '5px',
            height: 0,
            background: `linear-gradient(180deg, ${hexPrimary}, ${rgbPrimaryFade}, transparent 70%)`
          }

        default:
          return null;
      }
    }, [direction, hexPrimary, rgbPrimaryFade]
  );

  return {
    position: 'absolute',
    borderRadius: '100vmax',
    ...styles,
    ...(
      !noNeonLights && {
        animation
      }
    ),
    ...(
      !!stopAnimation && {
        'animationPlayState': 'paused'
      }
    ),
  }
});

const adjustColor = (color, amount) => {
  let usePound = false;

  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  let num = parseInt(color, 16);
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00FF) + amount;
  let b = (num & 0x0000FF) + amount;

  r = r > 255 ? 255 : r < 0 ? 0 : r;
  g = g > 255 ? 255 : g < 0 ? 0 : g;
  b = b > 255 ? 255 : b < 0 ? 0 : b;

  return (usePound ? "#" : "") + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
};

const NeonText = styled(Typography, {
  shouldForwardProp: (prop) => (prop !== 'stopAnimation' && prop !== 'noNeonLights')
})(({ theme, stopAnimation = false, noNeonLights = false }) => {

  const mainColor = theme.palette.primary.main;
  const isLightMode = theme.palette.mode === 'light';

  const neonAnimation = useMemo(() => {

    const lightColor = adjustColor(mainColor, 50); // lighter
    const darkColor = adjustColor(mainColor, -50); // darker variation

    return keyframes`
      0%, 100% {
        text-shadow: 0 0 1vw ${mainColor}, 0 0 3vw ${mainColor}, 0 0 10vw ${mainColor}, 0 0 10vw ${mainColor}, 0 0 0.4vw ${lightColor}, 1px 1px 0.1vw ${darkColor};
        color: ${lightColor};
      }
      50% {
        text-shadow: 0 0 0.5vw ${darkColor}, 0 0 1.5vw ${darkColor}, 0 0 5vw ${darkColor}, 0 0 5vw ${darkColor}, 0 0 0.2vw ${darkColor}, 1px 1px 0.1vw ${adjustColor(darkColor, -20)};
        color: ${isLightMode ? lightColor : darkColor};
      }
    `;
  }, [mainColor, isLightMode]);

  return {
    color: isLightMode ? adjustColor(mainColor, 50) : mainColor,
    textShadow: `0 0 4px ${adjustColor(mainColor, -50)}`,
    fontFamily: 'Neon',
    textTransform: 'uppercase',
    ...(
      !noNeonLights && {
        animation: `${neonAnimation} 1s ease infinite`,
      }
    ),
    ...(
      !!stopAnimation && {
        'animationPlayState': 'paused'
      }
    ),
    '&:hover': {
      'animationPlayState': 'paused'
    }
  };
});

const FluxText = styled(Typography, {
  shouldForwardProp: (prop) => (prop !== 'stopAnimation' && prop !== 'noNeonLights')
})(({ theme, stopAnimation = false, noNeonLights = false }) => {

  const fluxAnimation = useMemo(
    () => {
      return keyframes`
        0%, 100% {
          text-shadow: 0 0 1vw #D3D3D3, 0 0 2vw #D3D3D3, 0 0 7vw #D3D3D3, 0 0 7vw #D3D3D3, 0 0 .4vw #F5F5F5, 1px 1px .1vw #A9A9A9;
          color: #F5F5F5;
        }
        50% {
          text-shadow: 0 0 .5vw #A9A9A9, 0 0 1.5vw #A9A9A9, 0 0 5vw #A9A9A9, 0 0 5vw #A9A9A9, 0 0 .2vw #A9A9A9, 1px 1px .1vw #808080;
          color: #D3D3D3;
        }
      `;
    }, []
  );

  return {
    color: '#D3D3D3',
    textShadow: `0 0 4px ${adjustColor('#D3D3D3', -50)}`,
    fontFamily: 'Neon',
    textTransform: 'uppercase',
    ...(
      !noNeonLights && {
        animation: `${fluxAnimation} 2s linear infinite`,
      }
    ),
    ...(
      !!stopAnimation && {
        'animationPlayState': 'paused'
      }
    ),
    '&:hover': {
      'animationPlayState': 'paused'
    }
  };
});


export default function NeonPanel(props) {
  const { stopAnimation = false, noNeonLights = false } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{
      position: 'relative',
      border: `1px solid ${theme.palette.primary.light}`,
      bgcolor: '#223',
      p: 2,
      textAlign: 'center'
    }}>
      <SpanAnimation component='span' direction='top' stopAnimation={stopAnimation} noNeonLights={noNeonLights} />
      <SpanAnimation component='span' direction='right' stopAnimation={stopAnimation} noNeonLights={noNeonLights} />
      <SpanAnimation component='span' direction='bottom' stopAnimation={stopAnimation} noNeonLights={noNeonLights} />
      <SpanAnimation component='span' direction='left' stopAnimation={stopAnimation} noNeonLights={noNeonLights} />
      <NeonText variant='h4' stopAnimation={stopAnimation} noNeonLights={noNeonLights} id='lucky-wheel-title'>
        {t('Lucky Wheel')}
      </NeonText>
      <FluxText variant='h5' stopAnimation={stopAnimation} noNeonLights={noNeonLights} id='lucky-wheel-subtitle'>
        {t('Spin And Win')}
      </FluxText>
    </Box>
  );
}
