import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Done';
import Avatar from '@mui/material/Avatar';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import CropperDialog from 'features/image/CropperDialog';
import dayjs from 'dayjs';
import {
  DescriptionTwoTone as FileIcon,
  CropRotateTwoTone as CropIcon,
} from '@mui/icons-material';
import { useChats } from '../../hooks/useChats';
import {
  TextareaAutosize
} from '@mui/base';

import axiosClient from 'services/axios';

export default function MediaSender(props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const [ files, setFiles ] = useState([]);
  const [ selectedIndex, setSelectedIndex ] = useState([]);
  const [ selectAnchor, setSelectAnchor ] = useState(null);
  const [ selectAll, setSelectAll ] = useState(false);
  const [ status, setStatus ] = useState('idle');
  const [ lastUpload, setLastUpload ] = useState(null);
  const [ controller, setController ] = useState(null);
  const tagsRef = useRef(null);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ cropperDialog, setCropperDialog ] = useState({
    open: false,
    file: null,
  });
  const { selectedChatId, sendMediaMessage } = useChats();

  const filesLength = useMemo(() => files.length, [files]);

  useEffect(() => {
    if (!selectedIndex.length) return;
    if (tagsRef && tagsRef.current) tagsRef.current.focus();
  }, [selectedIndex]);

  const isSelectedImage = useMemo(() => {
    if (!selectedIndex.length) return false;
    const ind = selectedIndex[0];
    const file = get(files, `[${ind}]`, {});
    return file.type && file.type.startsWith('image') ? true : false;
  }, [selectedIndex, files]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/*': [],
      'video/*': [],
      // 'audio/*': [],
      // 'application/pdf': ['.pdf'],
      // 'text/plain': ['.txt'],
    },
    onDrop: acceptedFiles => {
      setFiles(prevState => {
        const newFiles = acceptedFiles.filter(file => {
          const duplicated = prevState.findIndex(f => f.path === file.path);

          if (duplicated >= 0) return false;

          return Object.assign(file, {
            customName: file.name,
            tags: [],
            isUploaded: false,
            uploadPercentage: 0,
            preview: URL.createObjectURL(file)
          });
        });

        return [...prevState, ...newFiles];
      });
    }
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    if (!lastUpload) return;
    setStatus('upload');
  }, [lastUpload]);

  useEffect(() => {
    if (status !== 'upload') return;

    const filesToUpload = files.filter(f => !f.isUploaded);

    async function uploadNow() {
      setStatus('uploading');

      for (const file of filesToUpload) {
        const formData = new FormData();

        const { caption, path } = file;

        if (caption) formData.append('caption', caption);

        formData.append('uri', file);

        const handleUploadProgress = (file) => async (pe) => {
          const p = Math.round(pe.loaded / pe.total * 100);

          setFiles((prevState) => {
            return prevState.map((d) => {
              if (d.path !== path) return d;

              return Object.assign(d, {
                uploadPercentage: p,
                isUploaded: p >= 100 ? true : false
              });
            });
          });
        };

        try {
          const controller = new AbortController();
          setController(controller);

          const uploaded = await axiosClient.post('chat-message-upload', formData, {
            signal: controller.signal,
            onUploadProgress: handleUploadProgress(file)
          });

          const { data } = uploaded;
          const { media, thumbnail = null } = data;
          sendMediaMessage(media, caption, thumbnail);
        } catch (err) {
          const errorCode = get(err, 'code', '');

          if (errorCode === 'ERR_CANCELED') {
            setStatus('idle');
            return;
          }

          const errData = get(err, 'response.data');
          const message = get(errData, 'message', '');
          if (message.indexOf('E11000') < 0) setGlobalErrorMessage({ err: errData });
        }
      }
      setStatus('idle');

      if (onClose) {
        onClose();
      }
    }

    uploadNow();
  }, [status, files, setGlobalErrorMessage, sendMediaMessage, onClose]);

  useEffect(() => {
    if (status !== 'cancel') return;
    if (controller) controller.abort();
    setStatus('idle');
  }, [status, controller]);

  useEffect(() => {
    if (selectedIndex.length <= 0) {
      setSelectAnchor(null);
    } else if (selectedIndex.length === 1) {
      setSelectAnchor(selectedIndex[0]);
    }
  }, [selectedIndex]);

  const handlePreviewClicked = useCallback((index) => (event) => {
    event.preventDefault();

    const ctrlOn = get(event, 'ctrlKey', false);
    const shiftOn = get(event, 'shiftKey', false);

    if (ctrlOn) {
      setSelectedIndex(prevState => {
        if (prevState.indexOf(index) >= 0) {
          return prevState.filter(i => i !== index);
        } else {
          return [...prevState, index];
        }
      });
      setSelectAnchor(prevState => (prevState === index) ? null : index);
    } else if (shiftOn) {
      setSelectedIndex(prevState => {
        if (selectAnchor === null) return [index];
        const min = index <= selectAnchor ? index : selectAnchor;
        const max = index >= selectAnchor ? index : selectAnchor;

        return Array.from({length: max - min + 1}, (_, i) => i + min);
      });
    } else {
      setSelectedIndex(prevState => {
        if (prevState.length > 1) {
          return [index];
        } else {
          return prevState.indexOf(index) >= 0 ? [] : [index];
        }
      });
    }
  }, [selectAnchor]);

  useEffect(() => {
    if (selectAll) {
      setSelectedIndex(Array.from(Array(filesLength).keys()));
      setSelectAnchor(0);
    } else {
      setSelectedIndex([]);
      setSelectAnchor(null);
    }
  }, [selectAll, filesLength]);

  const toggleAllLabel = useMemo(() => {
    if (selectAll) return t('None');
    else return t('All');
  }, [selectAll, t]);

  const handleSelectAllToggle = (event) => {
    event.preventDefault();
    setSelectAll(prevState => !prevState);
  };

  const handleDeleteClicked = (event) => {
    event.preventDefault();
    setFiles((prevState) => {
      return prevState.filter((d, ind) =>
        selectedIndex.indexOf(ind) < 0
      );
    });
    setSelectedIndex([]);
  };

  const handleUploadClicked = useCallback((event) => {
    if (!selectedChatId) return;
    setSelectedIndex([]);
    setSelectAnchor(null);
    setLastUpload(Date.now());
  }, [selectedChatId]);

  const handleCancelClicked = useCallback((event) => {
    setStatus('cancel');
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleCropperClicked = useCallback((event) => {
    event.preventDefault();

    if (!selectedIndex.length) return;
    const file = get(files, `[${selectedIndex[0]}]`);

    setCropperDialog({
      open: true,
      file
    });
  }, [files, selectedIndex]);

  const handleCropperDialogClosed = (croppedImage) => {
    setCropperDialog({
      open: false,
      file: null
    });

    if (!croppedImage) return;

    const fileName = get(cropperDialog, 'file.name', '');
    const appendDt = dayjs().format('HHmmss');
    const nameOnly = fileName.split('.').slice(0, -1).join('.');
    const extOnly = fileName.split('.').pop();
    const thumbnailFileName = `${nameOnly}_${appendDt}.${extOnly}`;

    async function appendCroppedImage() {
      try {
        const blob = await fetch(croppedImage).then(r => r.blob());

        const croppedFile = new File([blob], thumbnailFileName, {
          type: blob.type,
        });

        Object.assign(croppedFile, {
          path: croppedImage,
          customName: croppedFile.name,
          tags: [],
          isUploaded: false,
          uploadPercentage: 0,
          preview: croppedImage
        });
        setFiles(prev => ([...prev, croppedFile]));
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    appendCroppedImage();
  };

  const onCaptionChanged = useCallback((event) => {
    setFiles((prevState) => {
      return prevState.map((d, index) => {
        if (selectedIndex.indexOf(index) < 0) return d;
        return Object.assign(d, {
          caption: event.target.value
        });
      });
    });
  }, [selectedIndex]);

  const thumbs = useCallback(() => {
    if (!files.length) return null;

    return (
      <Grid container spacing={2}>
      {
        files.map((file, index) => {
          const { type } = file;
          const isImage = type && type.startsWith('image');

          const isUploaded = get(file, 'isUploaded', false);
          const uploadPercentage = get(file, 'uploadPercentage', 0);
          return (
            <Grid key={file.path} item xs={6} md={3}>
              <Box sx={{ position: 'relative' }}>
                {
                  isImage ?
                  (
                    <Box
                      onClick={handlePreviewClicked(index)}
                      sx={{
                        cursor: 'pointer',
                        width: '100%',
                        height: 'auto',
                        border: 1,
                        borderRadius: 0,
                        ...(selectedIndex.indexOf(index) >= 0 && {
                          padding: 0.5,
                          border: 1,
                          borderColor: 'info.main'
                        })
                      }}
                      component='img'
                      key={file.path}
                      src={file.preview}
                      // Revoke data uri after image is loaded
                      onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                  ) : (
                    <Box
                      onClick={handlePreviewClicked(index)}
                      sx={{
                        cursor: 'pointer',
                        width: '100%',
                        border: 1,
                        borderRadius: 0,
                        ...(selectedIndex.indexOf(index) >= 0 && {
                          border: 2,
                          borderColor: 'info.main'
                        })
                      }}
                    >
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        gap: 1,
                      }}>

                      <FileIcon sx={{ fontSize: 48 }} />
                      <Typography variant='body2' color='textSecondary'>
                        {
                          file?.name || ''
                        }
                      </Typography>
                      </Box>
                    </Box>
                  )
                }

                {
                  (isUploaded || uploadPercentage > 0) &&
                  <Avatar
                    sx={{
                      transform: 'translate(-50%, -50%)',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      color: isUploaded ? 'success.dark' : 'warning.dark',
                      bgcolor: isUploaded ? 'success.light' : 'warning.light'
                    }}
                  >
                  {
                    isUploaded ?
                    <CheckIcon /> :
                    uploadPercentage
                  }
                  </Avatar>
                }
              </Box>
            </Grid>
          );
        })
      }
      </Grid>
    );
  }, [files, selectedIndex, handlePreviewClicked]);

  return (
    <Paper sx={{ p: 2 }} elevation={0}>
      <CropperDialog open={cropperDialog.open} file={cropperDialog.file} onClose={handleCropperDialogClosed} />
      <Box sx={{
        position: 'relative',
        minHeight: filesLength ? 50 : 100,
        border: 1.5,
        textAlign: 'center',
        borderStyle: 'dashed',
        borderRadius: 0,
        borderColor: (theme) => {
          if (isFocused) return theme.palette.info.light;
          else if (isDragAccept) return theme.palette.success.light;
          else if (isDragReject) return theme.palette.error.light;
          else return theme.palette.text.secondary;
        },
      }} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontWeight: 100,
        }} variant='body1'>
        {
          t('mediaSender.dropFilesHere')
        }
        </Typography>
      </Box>
      <Paper elevation={4} sx={{ mt: 2, p: 2, display: !filesLength ? 'none' : 'block' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
        }}>
          <Button variant='text' onClick={handleSelectAllToggle}>{toggleAllLabel}</Button>
          <Button color='error' onClick={handleDeleteClicked}>{t('Clear')}</Button>
        </Box>
        {
          thumbs()
        }
        <Box sx={{ my: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center',
                gap: 1,
              }}>
                <TextareaAutosize
                  maxRows={5}
                  style={{
                    width: '100%',
                    resize: 'none',
                    padding: '8px',
                    borderRadius: '4px',
                    border: 'none',
                  }}
                  placeholder={t('Type a message')}
                  value={get(files, `[${selectedIndex[0]}].caption`, '')}
                  onChange={onCaptionChanged}
                />
                <Button
                  size='small'
                  variant='contained'
                  color='info'
                  onClick={handleCropperClicked}
                  disabled={!isSelectedImage}
                  startIcon={<CropIcon />}
                >
                  { t('Crop') }
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 2,
      }}>
        <Button variant='text' onClick={handleCancelClicked}>
          { t('mediaSender.close') }
        </Button>
        <Button variant='contained' onClick={handleUploadClicked} >
          { t('mediaSender.send') }
        </Button>
      </Box>
    </Paper>
  );
}