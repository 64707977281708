import React, { useState, useContext, createContext, useEffect, useMemo, useCallback } from 'react';
import feathers from 'services/feathers';
import { useChats } from './useChats';
import { useAuth } from './useAuth';

const chatMessageContext = createContext();

export function ProvideChatMessages({ children }) {
  const chats = useProvideChatMessages();
  return <chatMessageContext.Provider value={chats}>{children}</chatMessageContext.Provider>;
}

export const useChatMessages = () => {
  return useContext(chatMessageContext);
};

function useProvideChatMessages() {
  const { user } = useAuth();
  const { selectedChatId, selectedChatUnreadCount } = useChats();
  const [ selectedChatInitialUnreadCount, setSelectedChatInitialUnreadCount ] = useState(null);
  const [ total, setTotal ] = useState(0);
  const [ skip, setSkip ] = useState(0);
  const [ limit ] = useState(50);
  const [ chatMessages, setChatMessages ] = useState([]);
  const [ selectedChatMessageId, setSelectedChatMessageId ] = useState(null);
  const [ searchText, setSearchText ] = useState('');
  const [ status, setStatus ] = useState('idle');
  const [ firstItemIndex, setFirstItemIndex ] = useState(999999);
  const [ initialTopMostItemIndex, setInitialTopMostItemIndex ] = useState(null);
  const [ isFirstLoad, setIsFirstLoad ] = useState(true);

  const myUsername = useMemo(() => {
    return user?.username;
  }, [user]);

  useEffect(() => {
    setSelectedChatInitialUnreadCount(selectedChatUnreadCount);
  }, [selectedChatUnreadCount, selectedChatInitialUnreadCount]);

  useEffect(() => {
    setTotal(0);
    setSkip(0);
    setChatMessages([]);
    setFirstItemIndex(999999);
    setIsFirstLoad(true);
  }, [selectedChatId]);

  useEffect(() => {
    setTotal(0);
    setSkip(0);
    setChatMessages([]);
    setFirstItemIndex(999999);

    if (searchText === '') {
      setIsFirstLoad(true);
    }
  }, [searchText]);

  useEffect(() => {
    if (!selectedChatId || !isFirstLoad) return;

    const getChatMessagesMeta = async () => {
      try {
        setStatus('loading');

        const res = await feathers.service('chat-messages').find({
          query: {
            chatId: selectedChatId,
            $limit: selectedChatInitialUnreadCount > limit ? selectedChatInitialUnreadCount : limit,
            $sort: {
              'sentAt': -1,
              'createdAt': -1,
            },
          },
        });
        const { data, total } = res;

        if (total === 0) {
          setTotal(0);
          setFirstItemIndex(0);
          setInitialTopMostItemIndex(0);
          return;
        }

        setTotal(total);
        setChatMessages(data.reverse());

        const calculatedSkip = total - data.length - limit;
        setSkip(calculatedSkip < 0 ? 0 : calculatedSkip);

        const calculatedFirstItemIndex = total - data.length;
        setFirstItemIndex(calculatedFirstItemIndex);

        let calculatedInitialTopMostItemIndex;

        if (selectedChatInitialUnreadCount > 0) {
          calculatedInitialTopMostItemIndex = data.length - selectedChatInitialUnreadCount;
        } else {
          calculatedInitialTopMostItemIndex = total - 1;
        }
        setInitialTopMostItemIndex(calculatedInitialTopMostItemIndex);
      } catch (err) {
        console.error(`Error fetching chat messages meta: ${err.message}`);
        throw err;
      } finally {
        setStatus('idle');
        setIsFirstLoad(false);
      }
    }

    getChatMessagesMeta();
  }, [selectedChatId, limit, selectedChatInitialUnreadCount, isFirstLoad]);

  const isInitiated = useMemo(() => {
    return !!selectedChatId && !isFirstLoad;
  }, [selectedChatId, isFirstLoad]);

  const chatMessageService = feathers.service('chat-messages');
  const chatMessageUpdaterService = feathers.service('chat-message');

  useEffect(() => {
    chatMessageService.on('created', (chatMessage) => {
      const { chatId } = chatMessage;
      if (chatId !== selectedChatId) return;

      setChatMessages((prevChatMessages) => {
        return [...prevChatMessages, chatMessage];
      });
    });

    chatMessageService.on('patched', (chatMessage) => {
      const { chatId } = chatMessage;
      if (chatId !== selectedChatId) return;

      setChatMessages((prevChatMessages) => {
        const newChatMessages = prevChatMessages.map((prevChatMessage) => {
          if (prevChatMessage._id === chatMessage._id) {
            return chatMessage;
          }
          return prevChatMessage;
        });
        return newChatMessages;
      });
    });

    return () => {
      chatMessageService.removeListener('created');
      chatMessageService.removeListener('patched');
    };
  }, [chatMessageService, selectedChatId]);

  const params = useMemo(() => {
    if (!isInitiated) return null;

    const ret = {
      query: {
        chatId: selectedChatId,
        $sort: {
          'sentAt': 1,
          'createdAt': 1,
        },
        $limit: limit,
        $skip: skip,
        ...(searchText && { $text: { $search: searchText } }),
      },
    };

    return ret;
  }, [limit, skip, searchText, isInitiated, selectedChatId]);

  useEffect(() => {
    if (!params) return;

    const getChatMessages = async () => {
      try {
        setStatus('loading');
        const res = await chatMessageService.find(params);
        const { data, total } = res;

        setFirstItemIndex((prevFirstItemIndex) => {
          if (prevFirstItemIndex - data.length < 0) return 0;
          return prevFirstItemIndex - data.length;
        });

        setChatMessages((prevChatMessages) => {
          const newChatMessages = data.filter((chatMessage) => !prevChatMessages.some((prevChatMessage) => prevChatMessage._id === chatMessage._id));
          return [...newChatMessages, ...prevChatMessages];
        });

        setTotal(total);
      } catch (err) {
        console.error(`Error fetching chats: ${err.message}`);
        throw err;
      } finally {
        setStatus('idle');
      }
    }

    getChatMessages();
  }, [params, chatMessageService]);

  const fetchMore = useCallback(() => {
    // setPage((prevPage) => {
    //   if (prevPage - 1 < 0) return prevPage;
    //   return prevPage - 1;
    // });
    setSkip((prevSkip) => {
      if (prevSkip - limit < 0) return prevSkip;
      return prevSkip - limit;
    });

  }, [limit]);

  const markChatMessageAsRead = useCallback(async (chatMessageId) => {
    if (!isInitiated) return;
    try {
      await chatMessageUpdaterService.patch(chatMessageId, {
        readAt: new Date()
      });
    } catch (err) {
      console.error(`Error marking chat message as read: ${err.message}`);
      throw err;
    }
  }, [chatMessageUpdaterService, isInitiated]);

  const formattedChatMessages = useMemo(() => {
    return chatMessages.map((chatMessage) => {

      const { participantStatuses = [], from } = chatMessage;

      const myParticipant = participantStatuses.find(
        (participant) => participant.id === myUsername && participant.type === 'user'
      );

      const { readAt, deletedAt, pinnedAt } = myParticipant || {};

      const fromMe = from.id === myUsername && from.type === 'user';

      return {
        ...chatMessage,
        fromMe,
        ...(readAt && { readAt }),
        ...(deletedAt && { deletedAt }),
        ...(pinnedAt && { pinnedAt }),
      };
    });
  }, [chatMessages, myUsername]);

  return {
    chatMessages: formattedChatMessages,
    //getChatMessages,
    fetchMore,
    total,
    firstItemIndex,
    initialTopMostItemIndex,
    selectedChatMessageId,
    setSelectedChatMessageId,
    searchText,
    setSearchText,
    isIdle: status === 'idle',
    isInitiated,
    markChatMessageAsRead,
  };
}