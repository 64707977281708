import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import LanguageCardHeader from './LanguageCardHeader.js';
import useImageAssetDownloader from 'hooks/useImageAssetDownloader';
import {
  Skeleton,
} from '@mui/material';

export default function AvatarLogo(props) {
  const { brandLogo, name } = props;
  const { image, isLoaded } = useImageAssetDownloader({ assetId: brandLogo });

  return (
    <>
      <LanguageCardHeader />
      {
        isLoaded ? (
          <CardMedia
            width='100%'
            component="img"
            image={image}
            alt={name}
            sx={{ p: "1em", objectFit: "contain" }}
          />
        ) : (
          <Skeleton animation='wave' width={'100%'} height={160} variant='rounded' />
        )
      }
    </>
  );
}
