import { useCallback, useEffect, useState } from 'react';
import feathers from 'services/feathers';
import { get } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import dayjs from 'dayjs';

export default function useFeathers(serviceName, params = {}, options = {}) {
  const service = feathers.service(`/${serviceName}`);
  const [ fetchedData, setFectchedData ] = useState([]);
  const [ status, setStatus ] = useState('fetch');
  const [ paramsStr, setParamsStr ] = useState(null);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  /*
    options
  */
  const liveUpdatePredicate = get(options, 'liveUpdatePredicate');
  const createdAtTop = get(options, 'createdAtTop', false);

  const predicateCallback = useCallback(
    (data) => {
      if (liveUpdatePredicate === undefined) return true;
      return liveUpdatePredicate(data);
    },
    [liveUpdatePredicate],
  );

  const onChangedCallback = useCallback(
    (data) => {
      // Predicate not matched
      if (!predicateCallback(data)) return;

      const _id = get(data, '_id');

      setFectchedData((prevState) => {
        return prevState.map(d => {
          if (d._id !== _id) return d;
          const prevUpdatedAt = dayjs(get(d, 'updatedAt'));
          const currUpdatedAt = dayjs(get(data, 'updatedAt'));
          if (currUpdatedAt.isBefore(prevUpdatedAt)) return d;
          return data;
        });
      });
    },
    [predicateCallback]
  );

  const onAddedCallback = useCallback(
    (data) => {
      if (!predicateCallback(data)) return;

      if (createdAtTop) {
        setFectchedData((prevState) => [data, ...prevState]);
      } else {
        setFectchedData((prevState) => [...prevState, data]);
      }
    },
    [predicateCallback, createdAtTop]
  );

  const onDeletedCallback = useCallback(
    (data) => {
      // Predicate not matched
      if (!predicateCallback(data)) return;

      const _id = get(data, '_id');

      setFectchedData((prevState) => {
        return prevState.filter(d =>
          d._id !== _id
        );
      });

    },
    [predicateCallback]
  );

  useEffect(() => {
    if (typeof params !== 'object') return;
    setParamsStr(JSON.stringify(params));
  }, [params]);

  useEffect(() => {
    setFectchedData([]);
    setStatus('fetch');
  }, [paramsStr]);

  useEffect(() => {
    let isMounted = true;
    if (status !== 'fetch' || !paramsStr) return;
    async function fetch () {
      try {
        const p = JSON.parse(paramsStr);
        const find = await service.find(p);
        if (isMounted) {
          const total = get(find, 'total');

          // Fetch all will return [] instead of { total: x...}
          if (total === undefined) setFectchedData(find);
          else setFectchedData(find.data);
        }
      } catch (err) {
        if (isMounted) {
          setGlobalErrorMessage({ err });
        }
      }
      if (isMounted) setStatus('idle');
    };

    fetch();
    return () => {
      isMounted = false;
    };
  }, [status, paramsStr, service, setGlobalErrorMessage]);

  useEffect(() => {
    if (status !== 'idle') return;

    service.on('created', onAddedCallback);
    service.on('removed', onDeletedCallback);
    service.on('updated', onChangedCallback);
    service.on('patched', onChangedCallback);

    return () => {
      service.removeListener('created', onAddedCallback);
      service.removeListener('removed', onDeletedCallback);
      service.removeListener('updated', onChangedCallback);
      service.removeListener('patched', onChangedCallback);
    };
  }, [service, status, fetchedData, predicateCallback, onAddedCallback, onChangedCallback, onDeletedCallback]);

  return {
    data: fetchedData,
    ready: status === 'idle',
  };
}
