import React, { useState, useContext, createContext, useEffect, useMemo, useCallback } from 'react';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useAuth } from 'hooks/useAuth';

const chatContext = createContext();

export function ProvideChats({ children }) {
  const chats = useProvideChats();
  return <chatContext.Provider value={chats}>{children}</chatContext.Provider>;
}

export const useChats = () => {
  return useContext(chatContext);
};

function useProvideChats() {
  const { user } = useAuth();
  const [ total, setTotal ] = useState(0);
  const [ limit ] = useState(1);
  const [ skip, setSkip ] = useState(0);
  const [ chats, setChats ] = useState([]);
  const [ selectedChatId, setSelectedChatId ] = useState(null);
  const [ searchText, setSearchText ] = useState('');
  const [ status, setStatus ] = useState('idle');
  const [ distributionDomain, setDistributionDomain ] = useState(null);
  const [ selectedChatSendChannel, setSelectedChatSendChannel ] = useState('local');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const myUsername = useMemo(() => {
    return user?.username;
  }, [user]);

  const participantIdConcat = useMemo(() => {
    const { username, companyId } = user;
    const participants = [
      {
        id: username,
        type: 'user',
      },
      {
        id: companyId,
        type: 'system',
      },
    ];

    return participants.map(p => `${p.id}:${p.type}`).sort().join(';');
  }, [user]);

  const params = useMemo(() => ({
    query: {
      participantIdConcat,
      type: 'private',
      $sort: { 'lastMessage.createdAt': -1 },
      $limit: limit,
      $skip: skip,
      ...(searchText && { $text: { $search: searchText } }),
    },
  }), [limit, skip, searchText, participantIdConcat]);

  useEffect(() => {
    setSkip(0);
    setTotal(0);
    setChats([]);
  }, [searchText]);

  const chatService = feathers.service('chats');

  useEffect(() => {
    chatService.on('created', (chat) => {
      setChats((prevChats) => {
        if (prevChats.some((prevChat) => prevChat._id === chat._id)) {
          return prevChats;
        }
        return [chat, ...prevChats];
      });
    });

    chatService.on('patched', (chat) => {
      setChats((prevChats) => {
        const prevChat = prevChats.find((prevChat) => prevChat._id === chat._id);
        const { lastMessage: prevLastMessage = {} } = prevChat || {};

        if (prevLastMessage.createdAt === chat.lastMessage.createdAt) {
          return prevChats.map((prevChat) => {
            if (prevChat._id === chat._id) {
              return chat;
            }
            return prevChat;
          });
        }
        return [chat, ...prevChats.filter((prevChat) => prevChat._id !== chat._id)];
      });
    });

    return () => {
      chatService.removeListener('created');
      chatService.removeListener('patched');
    };
  }, [chatService]);

  const getChats = useCallback(async () => {
    try {
      setStatus('loading');
      const res = await chatService.find(params);
      const { data, total, distributionDomain } = res;

      if (data.length > 0) {
        setSelectedChatId(data[0]._id);
      }

      setChats((prevChats) => {
        const newChats = data.filter((chat) => !prevChats.some((prevChat) => prevChat._id === chat._id));
        return [...prevChats, ...newChats];
      });

      setTotal(total);

      if (distributionDomain) {
        setDistributionDomain(distributionDomain);
      }
    } catch (err) {
      console.error(`Error fetching chats: ${err.message}`);
      throw err;
    } finally {
      setStatus('idle');
    }
  }, [chatService, params]);

  const fetchMore = useCallback(() => {
    if (skip + limit >= total) return;
    setSkip((prevSkip) => prevSkip + limit);
  }, [limit, total, skip]);

  const formattedChats = useMemo(() => {
    return chats.map((chat) => {
      const { participants = [], name, lastMessage } = chat;
      const { from } = lastMessage || {};

      const myParticipant = participants.find(
        (participant) => participant.id === myUsername && participant.type === 'user'
      );

      const { unreadCount = 0 } = myParticipant || {};

      let formattedName = name;

      if (!formattedName) {
        const participantNames = participants
          .map((participant) => participant.id)
          .join(', ');

        formattedName = participantNames;
      }

      const fromMe = from?.id === myUsername && from?.type === 'user';

      return {
        ...chat,
        name: formattedName,
        unreadCount,
        fromMe,
      };
    });
  }, [chats, myUsername]);

  const selectedChatObject = useMemo(() => {
    const find = formattedChats.find((chat) => chat._id === selectedChatId);
    return find;
  }, [formattedChats, selectedChatId]);

  const selectedChatUnreadCount = useMemo(() => {
    const { unreadCount } = selectedChatObject || {};
    return unreadCount;
  }, [selectedChatObject]);

  useEffect(() => {
    if (!selectedChatObject) return;
    const { lastMessage } = selectedChatObject;
    const { origin } = lastMessage || {};

    if (origin) {
      setSelectedChatSendChannel(origin);
    }
  }, [selectedChatObject]);

  const sender = useMemo(() => {
    return {
      id: myUsername,
      type: 'user',
    };
  }, [myUsername]);

  const recipient = useMemo(() => {
    if (!selectedChatObject) return null;
    const { companyId } = selectedChatObject;
    return {
      id: companyId,
      type: 'system',
    };
  }, [selectedChatObject]);

  const sendTextMessage = useCallback(async (text) => {
    if (!selectedChatId) return;

    try {
      const requestData = {
        chatId: selectedChatId,
        from: sender,
        to: recipient,
        type: 'text',
        text,
        origin: 'local',
      };

      await feathers.service('chat-messages').create(requestData);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [selectedChatId, setGlobalErrorMessage, sender, recipient]);

  const sendMediaMessage = useCallback(async (media, caption, thumbnail) => {
    if (!selectedChatId) return;

    try {
      const { mimeType } = media;
      const chatMessageType = mimeType.split('/')[0];

      const requestData = {
        chatId: selectedChatId,
        from: sender,
        to: recipient,
        type: chatMessageType,
        text: caption,
        media,
        ...(!!thumbnail && { thumbnail }),
        origin: 'local',
      };

      await feathers.service('chat-messages').create(requestData);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [selectedChatId, setGlobalErrorMessage, sender, recipient]);

  useEffect(() => {
    getChats();
  }, [getChats]);

  return {
    chats: formattedChats,
    getChats,
    fetchMore,
    total,
    selectedChatId,
    setSelectedChatId,
    searchText,
    setSearchText,
    isIdle: status === 'idle',
    selectedChatUnreadCount,
    selectedChatSendChannel,
    setSelectedChatSendChannel,
    distributionDomain,
    sendTextMessage,
    sendMediaMessage,
  };
}
