import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/fonts/neon.ttf';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { ProvideAuth } from 'hooks/useAuth';
import { ProvideCheckAppVersion } from 'hooks/useCheckAppVersion';
import { ProvideUserConfig } from 'hooks/useUserConfig';
import { AbilityContext } from './casl/Can';
import ability from './casl/ability';
import CompanySettingProvider from 'features/context/CompanySettingProvider';
import ThemeProvider from 'features/context/ThemeProvider';
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ProvideAuth>
      <ProvideCheckAppVersion>
        <CompanySettingProvider>
          <ProvideUserConfig>
            <AbilityContext.Provider value={ability}>
              <ThemeProvider>
                <App />
              </ThemeProvider>
            </AbilityContext.Provider>
          </ProvideUserConfig>
        </CompanySettingProvider>
      </ProvideCheckAppVersion>
    </ProvideAuth>
  </React.StrictMode>
);

// If you want to troubleshoot production behavior
// change the StrictMode to Fragment

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
