import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import NewsContext from 'features/context/newsContext';
import { useAuth } from 'hooks/useAuth';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTheme } from '@mui/material/styles';
import NewsDialog from 'features/news/NewsDialog';
import NewsPreviewCard from './NewsPreviewCard';
import Alert from '@mui/material/Alert';
import {
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import {
  SwapVertTwoTone as SortIcon,
  FilterAltTwoTone as FilterIcon,
} from '@mui/icons-material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';

const SORT_MENU_MAP = {
  '0': 'Default',
  '1': 'Date (new)',
  '2': 'Date (old)',
  '3': 'Title (A-Z)',
  '4': 'Title (Z-A)',
};

export default function Home(props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ news, setNews ] = useState(null);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const theme = useTheme();
  const [ openDialog, setOpenDialog ] = useState(false);
  const [ selectedSortKey, setSelectedSortKey ] = useState('0');
  const [ selectedTag, setSelectedTag ] = useState(null);
  const {
    data: newsData,
    allTags: newsAllTags,
    totalPage: newsTotalPage,
    isFetching: newsIsFetching,
    page: newsPage,
    gotoPage: newsGotoPage,
    setSort: newsSetSort,
    setFilter: newsSetFilter,
    errorMessage: newsErrorMessage,
  } = useContext(NewsContext);

  const allTags = useMemo(
    () => {
      return newsAllTags[lang] || [];
    }, [lang, newsAllTags]
  );

  useEffect(() => {
    if (!newsErrorMessage) return;
    const err = {
      message: newsErrorMessage
    };
    setGlobalErrorMessage({ err });
  }, [newsErrorMessage, setGlobalErrorMessage]);

  useEffect(() => {
    if (!selectedTag) {
      newsSetFilter({});
      return;
    }
    newsSetFilter({
      [`tags.${lang}`]: selectedTag
    });
  }, [newsSetFilter, selectedTag, lang]);

  useEffect(() => {
    switch (selectedSortKey) {
      case '0':
        newsSetSort({
          pinnedAt: -1,
          publishedAt: -1
        });
        break;
      case '1':
        newsSetSort({
          publishedAt: -1
        });
        break;
      case '2':
        newsSetSort({
          publishedAt: 1
        });
        break;
      case '3':
        newsSetSort({
          [`title.${lang}`]: 1
        });
        break;
      case '4':
        newsSetSort({
          [`title.${lang}`]: -1
        });
        break;
      default:
        break;
    }
  }, [lang, newsSetSort, selectedSortKey]);

  useEffect(() => {
    if (!news) return;
    setOpenDialog(true);
  }, [news]);

  const handlePageChange = (event, newPage) => {
    event?.preventDefault();
    newsGotoPage(newPage - 1);
  };

  const handleNewsClicked = useCallback(
    (newsId) => {
      if (!newsId) return;
      const find = newsData.find(n => n._id === newsId);
      setNews(find);
    }, [newsData]
  );

  const handleOnClose = useCallback(
    (event) => {
      event?.preventDefault();
      setOpenDialog(false);
      setNews(null);
    }, []
  );

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          maxWidth: {
            'sm': 'sm',
            'md': 'xl',
          },
          mx: 'auto',
        }}
      >
        <NewsDialog open={openDialog} lang={lang} news={news} handleOnClose={handleOnClose} />
        {
          newsIsFetching &&
          <LinearProgress />
        }
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h5' sx={{ fontWeight: 700, textTransform: 'uppercase' }}>
              {selectedTag ? selectedTag : t('All')}
            </Typography>
          </Box>
          <Box>
            <PopupState variant="popover" popupId="sortMenu">
              {(popupState) => (
                <>
                  <IconButton  color='inherit' variant="text" {...bindTrigger(popupState)}>
                    <SortIcon />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                  {
                      Object.keys(SORT_MENU_MAP).map((key) => {
                        return (
                          <MenuItem
                            key={key}
                            onClick={(event) => {
                              event.preventDefault();
                              setSelectedSortKey(key);
                              popupState.close();
                            }}
                            sx={{
                              ...(selectedSortKey === key && {
                                color: 'success.main',
                                fontWeight: 'bold',
                              })

                            }}
                          >
                            {t(SORT_MENU_MAP[key])}
                          </MenuItem>
                        );
                      })
                  }
                  </Menu>
                </>
              )}
            </PopupState>
            <PopupState variant="popover" popupId="filterMenu">
              {(popupState) => (
                <>
                  <IconButton color='inherit' {...bindTrigger(popupState)}>
                    <FilterIcon />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        event.preventDefault();
                        setSelectedTag(null);
                        popupState.close();
                      }}
                      sx={{
                        textTransform: 'uppercase',
                        ...(selectedTag === null && {
                          color: 'success.main',
                          fontWeight: 'bold',
                        })
                      }}
                    >
                      {t('All')}
                    </MenuItem>
                    {
                      allTags.map((tag) => {
                        return (
                          <MenuItem
                            key={tag}
                            onClick={(event) => {
                              event.preventDefault();
                              setSelectedTag(tag);
                              popupState.close();
                            }}
                            sx={{
                              textTransform: 'uppercase',
                              ...(selectedTag === tag && {
                                color: 'success.main',
                                fontWeight: 'bold',
                              })
                            }}
                          >
                            {tag}
                          </MenuItem>
                        );
                      })
                    }
                  </Menu>
                </>
              )}
            </PopupState>
          </Box>
        </Box>
        <Box sx={{ p: 1 }}>
          <Grid container spacing={2}>
          {
            !!newsData.length ?
            newsData.map((n) => {
              return (
                <Grid item xs={6} sm={4} key={n._id}>
                  <NewsPreviewCard
                    news={n}
                    onClick={handleNewsClicked}
                  />
                </Grid>
              )
            }) :
            <Grid item xs={12}>
              <Alert severity="info">{t('No record')}</Alert>
            </Grid>
          }
          </Grid>
        </Box>
      </Paper>
      <Box sx={
        {
          bgcolor: theme.palette.background.default,
          borderTop: 0.5,
          borderColor: 'divider',
          borderRadius: 0,
          bottom: theme.mixins.toolbar.minHeight,
          left: 0,
          position: 'fixed',
          width: '100%',
          zIndex: 100
        }
      }>
        <Box sx={{ display: 'flex' }}>
          <Pagination size='large' shape='circular' sx={{ m: 1 }} count={newsTotalPage} page={newsPage + 1} onChange={handlePageChange} />
        </Box>
      </Box>
    </>
  );
}
