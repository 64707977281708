import React, { useContext, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import SupportContext from 'features/context/supportContext';
import { useAuth } from 'hooks/useAuth';
import Loader from 'features/loader/Loader';
import { get, sortBy } from 'lodash';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import ImageAvatar from 'features/avatar/ImageAvatar';
import PersonIcon from '@mui/icons-material/Person';
import ContactIcon from '@mui/icons-material/ContactSupportTwoTone';
import ContactDialog from './ContactDialog';
import StyledBadge from 'features/styledBadge/StyledBadge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

export default function Contact(props) {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { customerServices, customerServicesReady } = useContext(SupportContext);
  const { t } = useTranslation();
  const [ dialog, setDialog ] = useState({
    open: false,
    contact: null
  });

  const hideLine = useMemo(() => {
    return customerServices.every(cs => !cs.line);
  }, [customerServices]);

  const handleDialogClicked = (contact) => (event) => {
    event?.preventDefault();
    setDialog({
      open: true,
      contact
    })
  };

  const handleDialogClosed = () => {
    setDialog({
      open: false,
      contact: null
    })
  };

  if (!customerServicesReady) {
    return (
      <Loader open={true} />
    );
  }

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <ContactDialog open={dialog.open} contact={dialog.contact} hideLine={hideLine} onClose={handleDialogClosed} />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('Contact Us')} />
            <Divider />
            <CardContent>
              {
                !!customerServices.length ?
                (
                  <List dense={true} disablePadding /*subheader={t('Online')}*/>
                  {
                    sortBy(customerServices, [`name.${lang}`]).map((cs, index) => {
                      const isEnabled = get(cs, 'isEnabled', false);
                      const onShift = get(cs, 'onShift', false);
                      if (!isEnabled || !onShift) return null;

                      const key = get(cs, '_id');
                      const name = get(cs, `name.${lang}`, '');
                      const status = get(cs, `status.${lang}`, '');
                      const avatar = get(cs, 'avatar');

                      return (
                        <ListItem onClick={handleDialogClicked(cs)} key={key}
                          secondaryAction={
                            <IconButton edge="end">
                              <ContactIcon />
                            </IconButton>
                          }
                        >
                          <ListItemAvatar>
                            {
                              avatar ? (
                                <StyledBadge
                                  overlap='circular'
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                  variant='dot'
                                >
                                  <ImageAvatar
                                    alt={`${name}-${status}`}
                                    imageId={avatar}
                                  />
                                </StyledBadge>
                                ) : (
                                <StyledBadge
                                  overlap='circular'
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                  variant='dot'
                                >
                                  <Avatar sx={{
                                    bgcolor: red[100],
                                    color: red[600]
                                  }}>
                                    <PersonIcon />
                                  </Avatar>
                                </StyledBadge>
                              )
                            }
                          </ListItemAvatar>
                          <ListItemText
                            primary={<Typography sx={{ fontWeight: 700 }} variant="body" component="div">{name}</Typography>}
                            secondary={<Typography sx={{ fontStyle: 'oblique', fontWeight: 300, color: 'text.secondary' }} variant="body2" component="div">{status}</Typography>}
                          />
                        </ListItem>
                      )
                    })
                  }
                  </List>
                ) :
                <Alert severity="info">{t('No record')}</Alert>
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
