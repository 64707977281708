import React, { useMemo, useCallback } from 'react';
import { useUserFavourite } from 'hooks/useUserFavourite';

import {
  Box,
} from '@mui/material';

import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
} from '@mui/icons-material';

import {
  pink
} from '@mui/material/colors';

export default function FavouriteGame(props) {
  const { gameType } = props;
  const { userFavouriteGames, addFavouriteGame, removeFavouriteGame } = useUserFavourite();

  const isFavourite = useMemo(() => userFavouriteGames.includes(gameType), [gameType, userFavouriteGames]);

  const onFavouriteGameClick = useCallback((event) => {
    if (isFavourite) {
      removeFavouriteGame(gameType);
    } else {
      addFavouriteGame(gameType);
    }
    event.stopPropagation();
  }, [isFavourite, gameType, addFavouriteGame, removeFavouriteGame]);

  return (
    <Box
      onClick={onFavouriteGameClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: isFavourite ? 'warning.main' : 'grey.500',
      }}
    >
      {isFavourite ? <FavoriteIcon sx={{ color: pink[500] }} /> : <FavoriteBorderIcon />}
    </Box>
  );
}