import React, { useContext, useEffect, useState } from 'react';
import { _CASH_SYMBOL, _POINT_SYMBOL } from 'utils/symbol';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Decimal from 'decimal.js';
import CommonContext from 'features/context/commonContext';
import CountUp from 'react-countup';
import { get } from 'lodash';

export default function BalanceBar(props) {
  const isMediumSize = useMediaQuery('(min-width:600px)');
  const [cashBalance, setCashBalance] = useState({ start: 0, end: 0 });
  const [pointBalance, setPointBalance] = useState({ start: 0, end: 0 });

  const { wallet, walletReady } = useContext(CommonContext);

  useEffect(() => {
    if (!walletReady) return;

    const cashBalStr = get(wallet, 'cashBalance.$numberDecimal', '0');
    const cashBal = new Decimal(cashBalStr);
    setCashBalance(prev => ({
      start: prev?.end,
      end: cashBal
    }));

    const pointBalStr = get(wallet, 'pointBalance.$numberDecimal', '0');
    const pointBal = new Decimal(pointBalStr);

    setPointBalance(prev => ({
      start: prev?.end,
      end: pointBal
    }));
  }, [wallet, walletReady]);

  return (
    <Box sx={{ mx: 1 }}>
      <Stack direction='column' spacing={0}>
        <CountUp
          start={pointBalance.start}
          end={pointBalance.end}
          duration={0.88}
          separator=" "
          decimals={2}
          decimal="."
          prefix=''
          suffix=''
        >
          {({ countUpRef, start }) => (
            <Box variant='outlined' sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
              <Box sx={{ mx: 1 }}>
                <Typography variant={ isMediumSize ? 'body1' : 'caption' }>
                  {_POINT_SYMBOL}
                </Typography>
              </Box>
              <Box>
                <Typography variant={ isMediumSize ? 'body1' : 'caption' } ref={countUpRef} />
              </Box>
            </Box>
          )}
        </CountUp>
        <CountUp
          start={cashBalance.start}
          end={cashBalance.end}
          duration={0.88}
          separator=" "
          decimals={2}
          decimal="."
          prefix=''
          suffix=''
        >
          {({ countUpRef, start }) => (
            <Box variant='outlined' sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
              <Box sx={{ mx: 1 }}>
                <Typography variant={ isMediumSize ? 'body1' : 'caption' }>
                  {_CASH_SYMBOL}
                </Typography>
              </Box>
              <Box>
                <Typography variant={ isMediumSize ? 'body1' : 'caption' } ref={countUpRef} />
              </Box>
            </Box>
          )}
        </CountUp>
      </Stack>
    </Box>
  );
}
