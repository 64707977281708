import { createContext } from 'react';

const CommonContext = createContext({
  companySetting: null,
  companySettingReady: false,
  wallet: null,
  walletReady: false,
  userData: null,
  userDataReady: false,
  games: [],
  gamesReady: false,
  gameIds: [],
  gameIdsReady: false,
  companyBanks: [],
  companyBanksReady: false,
  pointBoosters: [],
  pointBoostersReady: false,
  redemptionDeals: [],
  redemptionDealsReady: false,
  luckyDraws: [],
  luckyDrawsReady: false,
  themes: [],
  themesReady: false,
});

export default CommonContext;
