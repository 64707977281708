import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { _POINT_SYMBOL } from 'utils/symbol';
import PropTypes from 'prop-types';
import CommonContext from 'features/context/commonContext';
import {
  LinearProgress,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  Typography,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import ImageAvatar from 'features/avatar/ImageAvatar';
import Decimal from 'decimal.js';
import { useAuth } from 'hooks/useAuth';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(utc);

export default function PointReward(props) {
  const { amount = 0, skipReward = false } = props;
  const { user: { lang: userLang = 'en' } = {} } = useAuth();
  const [ cuReward, setCuReward ] = useState({ amount: { start: 0, end: 0 }, percentage: { start: 0, end: 0 } });
  const { t } = useTranslation();
  const [ showMore, setShowMore ] = useState(false);

  const { userData, userDataReady, tiers, tiersReady, pointBoosters, pointBoostersReady } = useContext(CommonContext);

  const userLevel = useMemo(
    () => {
      if (!userDataReady) return 0;
      return userData?.level || 0;
    }, [userDataReady, userData]
  );

  const userTimezone = useMemo(
    () => {
      if (!userDataReady) return 'UTC';
      return userData?.timezone || 'UTC';
    }, [userDataReady, userData]
  );

  const currentTier = useMemo(
    () => {
      if (!userDataReady || !tiersReady) return null;
      return tiers.find(tier => tier._id === userData.tierId);
    }, [userDataReady, tiersReady, userData, tiers]
  );

  const tierReward = useMemo(
    () => {
      if (!currentTier) return null;

      const { _id, name, photo, pointMultiplier } = currentTier;
      const localeName = name[userLang] || name['en'];
      const localePhoto = photo[userLang] || photo['en'];
      const decPointMultiplier = new Decimal(pointMultiplier?.$numberDecimal || 0);
      const rewardAmount = new Decimal(amount).times(decPointMultiplier);

      return {
        _id,
        name: localeName,
        imageId: localePhoto,
        percentage: decPointMultiplier.times(100).toNumber(),
        rewardAmount: rewardAmount.toNumber(),
        requirementMet: true,
      };
    }, [currentTier, amount, userLang]
  );

  const generateRequirementText = useCallback(
    (booster) => {
      const { requirementType, requirementAmount, requirementLevel, requirementRanks } = booster;

      switch (requirementType) {
        case 'deposit':
          const minAmount = new Decimal(requirementAmount.min?.$numberDecimal || 0);
          const maxAmount = new Decimal(requirementAmount.max?.$numberDecimal || 0);

          const minAmountUnbound = minAmount.eq(-1);
          const maxAmountUnbound = maxAmount.eq(-1);

          if (minAmountUnbound && maxAmountUnbound) return t('Any Amount');

          let depositRequirementText = '';

          if (minAmountUnbound) {
            depositRequirementText = t('Amount Requirement Up To', { max: maxAmount.toFixed(2) });
          } else if (maxAmountUnbound) {
            depositRequirementText = t('Amount Requirement From', { min: minAmount.toFixed(2) });
          } else {
            depositRequirementText = t('Amount Requirement', { min: minAmount.toFixed(2), max: maxAmount.toFixed(2) });
          }
          return `${t('Deposit')}: ${depositRequirementText}`;

        case 'rank':
          if (!tiers?.length) return '';
          const mappedRanks = requirementRanks.map(function (r) {
            const tier = tiers.find(t => t._id === r);
            return tier?.name[userLang] || tier?.name['en'] || '';
          });

          const isAllRanks = mappedRanks.length === tiers.length;
          if (isAllRanks) return t('Any Rank');

          const combinedRanks = mappedRanks.join(', ');

          return `${t('Rank')}: ${combinedRanks}`

        case 'level':
          const requirementLevelMin = requirementLevel.min;
          const requirementLevelMax = requirementLevel.max;
          const minUnbound = requirementLevelMin === -1 ? true : false;
          const maxUnbound = requirementLevelMax === -1 ? true : false;

          if (minUnbound && maxUnbound) return t('Any Level');

          let levelRequirementText = '';

          if (minUnbound) {
            levelRequirementText = t('Level Requirement Up To', { max: requirementLevelMax });
          } else if (maxUnbound) {
            levelRequirementText = t('Level Requirement From', { min: requirementLevelMin });
          } else {
            levelRequirementText = t('Level Requirement', { min: requirementLevelMin, max: requirementLevelMax });
          }
          return `${t('Level')}: ${levelRequirementText}`;

        default:
          return '';
      }

    }, [tiers, userLang, t]
  );

  const calculateBoosterEndsIn = useCallback(
    (booster) => {
      const { isDayAllowed, endsAt } = booster;
      if (!isDayAllowed) return null;

      const djsEndsAt = dayjs(endsAt);
      const now = dayjs();
      const diff = djsEndsAt.diff(now, 'day');

      if (diff > 180) return null;

      return djsEndsAt.locale(userLang).fromNow();
    }, [userLang]
  );

  const calculateBoosterAvailableIn = useCallback(
    (booster) => {
      const { isBetween = false, isDayAllowed = false, startsAt, endsAt, daysAllowed = [] } = booster;

      if (isBetween && isDayAllowed) return null;

      const djsStartsAt = dayjs(startsAt);
      const djsEndsAt = dayjs(endsAt);

      // For the case where the booster is between the start and end date but on the wrong day
      if (!isDayAllowed) {
        const nowWithTimezone = dayjs().tz(userTimezone);
        const todayIndex = nowWithTimezone.isoWeekday();
        const sortedDaysAllowed = daysAllowed.sort();

        let daysToAdd = -1;
        for (let i = 1; i <= 7; i++) {
          const nextDayIndex = (todayIndex + i) % 7;
          if (sortedDaysAllowed.includes(nextDayIndex)) {
            daysToAdd = i;
            break;
          }
        }

        if (daysToAdd === -1) return null;

        const djsNextDay = nowWithTimezone.add(daysToAdd, 'day').startOf('day');
        if (!djsNextDay.isBetween(djsStartsAt, djsEndsAt, 'day', '[)')) return null;

        return djsNextDay.locale(userLang).fromNow();
      }

      // For the case where booster is not between the start and end date
      if (!isBetween) {
        const now = dayjs();
        if (now.isBefore(djsStartsAt, 'day')) {
          return djsStartsAt.locale(userLang).fromNow();
        } else {
          return djsEndsAt.locale(userLang).fromNow();
        }
      }

      return null;
    }, [userLang, userTimezone]
  );

  const checkRequirements = useCallback(
    (booster) => {
      const { isDayAllowed, requirementType, requirementAmount, requirementLevel, requirementRanks } = booster;

      if (!isDayAllowed) return false;

      if (requirementType === 'none' || !requirementType) return true;

      if (requirementType === 'deposit') {
        const decRequirementMinAmount = new Decimal(requirementAmount?.min?.$numberDecimal || 0);
        const decRequirementMaxAmount = new Decimal(requirementAmount?.max?.$numberDecimal || 0);
        const decAmount = new Decimal(amount);

        if (decRequirementMinAmount.eq(-1) && decRequirementMaxAmount.eq(-1)) return true;
        if (decRequirementMinAmount.eq(-1) && decAmount.lte(decRequirementMaxAmount)) return true;
        if (decRequirementMaxAmount.eq(-1) && decAmount.gte(decRequirementMinAmount)) return true;
        return decAmount.gte(decRequirementMinAmount) && decAmount.lte(decRequirementMaxAmount);
      }

      if (requirementType === 'level') {
        return userLevel >= requirementLevel.min && userLevel <= requirementLevel.max;
      }

      if (requirementType === 'rank') {
        const tierId = currentTier?._id;
        return requirementRanks.includes(tierId);
      }

      return false;
    }, [amount, currentTier, userLevel]
  );

  const checkIsDayAllowed = useCallback(
    (booster) => {
      const { isBetween, daysAllowed = [] } = booster;
      if (!isBetween) return false;

      // If daysAllowed is empty, it means all days are allowed
      if (!daysAllowed.length) return true;

      const sortedDaysAllowed = daysAllowed.sort();

      const now = dayjs();

      const todayIndex = now.tz(userTimezone).isoWeekday();

      const isDayAllowed = sortedDaysAllowed.includes(todayIndex);

      return isDayAllowed;
    }, [userTimezone]
  );

  const boosterRewards = useMemo(
    () => {
      if (!pointBoostersReady || !currentTier) return [];

      const rewards = [];

      for (const booster of pointBoosters) {
        const { isEnabled = false, _id, name, photo, pointMultiplier } = booster;
        if (!isEnabled) continue;

        booster['isBetween'] = isBetween(booster);
        booster['isDayAllowed'] = checkIsDayAllowed(booster);
        booster['endsIn'] = calculateBoosterEndsIn(booster);
        booster['availableIn'] = calculateBoosterAvailableIn(booster);
        booster['requirementMet'] = checkRequirements(booster);
        booster['requirementText'] = generateRequirementText(booster);

        const localeName = name[userLang] || name['en'];
        const localePhoto = photo[userLang] || photo['en'];
        const decPointMultiplier = new Decimal(pointMultiplier?.$numberDecimal || 0);
        const rewardAmount = new Decimal(amount).times(decPointMultiplier);

        rewards.push({
          ...booster,
          _id,
          name: localeName,
          imageId: localePhoto,
          percentage: decPointMultiplier.times(100).toNumber(),
          rewardAmount: rewardAmount.toNumber(),
        });
      }

      rewards.sort((a, b) => {
        if (a.percentage === b.percentage) {
          return a.name.localeCompare(b.name);
        }
        return b.percentage - a.percentage;
      });

      return rewards;
    }, [pointBoostersReady, pointBoosters, currentTier, userLang, amount, generateRequirementText, calculateBoosterEndsIn, calculateBoosterAvailableIn, checkRequirements, checkIsDayAllowed]
  );

  const activeRewards = useMemo(
    () => {
      if (!tierReward) return null;

      const rewards = [];
      rewards.push(tierReward);

      for (const booster of boosterRewards) {
        if (booster.requirementMet) {
          rewards.push(booster);
        }
      }

      return rewards;
    }, [tierReward, boosterRewards]
  );

  const inactiveRewards = useMemo(
    () => {
      if (!tierReward) return null;

      const rewards = [];

      for (const booster of boosterRewards) {
        if (!booster.requirementMet) {
          rewards.push(booster);
        }
      }

      return rewards;
    }, [tierReward, boosterRewards]
  );

  const hasMore = useMemo(
    () => {
      return inactiveRewards?.length > 0;
    }, [inactiveRewards]
  );

  const totalReward = useMemo(
    () => {
      if (!activeRewards) return { percentage: 0, amount: 0 };

      let decTotalPercentage = new Decimal(0);
      let decTotalAmount = new Decimal(0);

      for (const reward of activeRewards) {
        const { percentage: boosterPercentage, rewardAmount: boosterRewardAmount } = reward;

        decTotalPercentage = decTotalPercentage.add(boosterPercentage);
        decTotalAmount = decTotalAmount.add(boosterRewardAmount);
      }

      return {
        percentage: decTotalPercentage.toNumber(),
        amount: decTotalAmount.toNumber()
      }
    }, [activeRewards]
  );

  useEffect(() => {
    setCuReward(prev => {

      if (prev?.amount?.end === totalReward.amount) {
        return prev;
      }

      return {
        amount: {
          start: prev?.amount?.end,
          end: totalReward.amount
        },
        percentage: {
          start: prev?.percentage.end,
          end: totalReward?.percentage
        }
      }
    });
  }, [totalReward]);

  function isBetween(booster) {
    const { startsAt, endsAt } = booster;
    const djsStartsAt = dayjs(startsAt);
    const djsEndsAt = dayjs(endsAt);
    const now = dayjs();
    return now.isBetween(djsStartsAt, djsEndsAt, 'day', '[)'); // [) means inclusive start and exclusive end
  }

  const isReady = useMemo(
    () => {
      return (userDataReady && tiersReady && pointBoostersReady);
    }, [userDataReady, tiersReady, pointBoostersReady]
  );

  if (!isReady) return (
    <Card>
      <CardContent>
        <LinearProgress />
      </CardContent>
    </Card>
  );

  return (
    <Card>
      <CardHeader
        disableTypography={true}
        title={
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
             <CountUp
              start={cuReward?.percentage.start}
              end={cuReward?.percentage.end}
              duration={0.88}
              separator=" "
              decimals={2}
              decimal="."
              prefix={`${t('Total Reward')} `}
              suffix='%'
            >
              {({ countUpRef, start }) => (
                <Typography
                  variant='h6'
                  sx={{
                    textDecoration: !skipReward ? 'none' : 'line-through',
                  }}
                  component='span' ref={countUpRef}
                />
              )}
            </CountUp>
            <CountUp
              start={cuReward?.amount.start}
              end={cuReward?.amount.end}
              duration={0.88}
              separator=" "
              decimals={2}
              decimal="."
              prefix=''
              suffix={` ${_POINT_SYMBOL}`}
            >
              {({ countUpRef, start }) => (
                <Typography
                  variant='h6'
                  sx={{
                    textDecoration: !skipReward ? 'none' : 'line-through',
                  }}
                  component='span' ref={countUpRef}
                />
              )}
            </CountUp>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        {
          activeRewards?.map((reward, index) =>
            {
              const { _id: rewardId, name, imageId, percentage, rewardAmount, endsIn } = reward;
              const decPercentage = new Decimal(percentage);
              const decRewardAmount = new Decimal(rewardAmount);

              return (
                <ListItem key={rewardId} divider={index < activeRewards.length - 1}>
                  <ListItemIcon>
                    <ImageAvatar
                      imageId={imageId}
                      alt={name}
                      sx={{ width: 48, height: 48 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    primary={
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                        <Typography variant='subtitle2'>
                          {reward.name} ({decPercentage.toString()}%)
                        </Typography>
                        <Typography variant='subtitle1'>
                          {`${decRewardAmount.toFixed(2)}`}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <>
                        {
                          reward.requirementText &&
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            color: 'text.secondary',
                            fontStyle: 'italic',
                          }}>
                            <Typography variant='caption'>
                              {reward.requirementText}
                            </Typography>
                          </Box>
                        }
                        {
                          endsIn &&
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            color: 'text.secondary',
                            fontStyle: 'italic',
                          }}>
                            <Typography variant='caption'>
                              {t('Ends in', { when: endsIn })}
                            </Typography>
                          </Box>
                        }
                      </>
                    }
                  />
                </ListItem>
              );
            }
          )
        }
        {
          showMore &&
          <ListSubheader sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
            {t('More Rewards')}
          </ListSubheader>
        }
        {
          showMore && inactiveRewards?.map((reward, index) =>
            {
              const { _id: rewardId, name, imageId, percentage, rewardAmount, availableIn } = reward;
              const decPercentage = new Decimal(percentage);
              const decRewardAmount = new Decimal(rewardAmount);
              return (
                <ListItem key={rewardId} divider={index < inactiveRewards.length - 1}>
                  <ListItemIcon>
                    <ImageAvatar
                      imageId={imageId}
                      alt={name}
                      sx={{ width: 48, height: 48, filter: 'grayscale(60%)' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    primary={
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                        <Typography variant='subtitle2'>
                          {reward.name} ({decPercentage.toString()}%)
                        </Typography>
                        <Typography variant='subtitle1'>
                          {`${decRewardAmount.toFixed(2)}`}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <>
                        {
                          reward.requirementText &&
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            color: 'text.secondary',
                            fontStyle: 'italic',
                          }}>
                            <Typography variant='caption'>
                              {reward.requirementText}
                            </Typography>
                          </Box>
                        }
                        {
                          availableIn &&
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            color: 'text.secondary',
                            fontStyle: 'italic',
                          }}>
                            <Typography variant='caption'>
                              {t('Availability', { when: availableIn })}
                            </Typography>
                          </Box>
                        }
                      </>
                    }
                  />
                </ListItem>
              );
            }
          )
        }
      </CardContent>
      <CardActions>
        <Box sx={{
          display: hasMore ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          cursor: 'pointer',
        }}
          onClick={() => setShowMore(!showMore)}
        >
          <Typography
            variant='caption'
          >
            {showMore ? t('Show Less') : t('Show More')}
          </Typography>
          {
            showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />
          }
        </Box>
      </CardActions>

    </Card>
  );
}

PointReward.propTypes = {
  amount: PropTypes.number.isRequired,
  skipReward: PropTypes.bool.isRequired,
};