import React, { useState, useContext, createContext, useEffect, useMemo } from 'react';
import feathers from 'services/feathers';
import { get } from 'lodash';
import i18n from 'i18n';
import { useAuth } from 'hooks/useAuth';
import CompanySettingContext from 'features/context/companySettingContext';

const userConfigContext = createContext();

export function ProvideUserConfig({ children }) {
  const userConfig = useProvideUserConfig();
  return <userConfigContext.Provider value={userConfig}>{children}</userConfigContext.Provider>;
}

export const useUserConfig = () => {
  return useContext(userConfigContext);
};

function useProvideUserConfig() {
  const [ lang, setLang ] = useState('en');
  const [ darkMode, setDarkMode ] = useState(false);
  const [ themeName, setThemeName ] = useState('');
  const [ companyId, setCompanyId ] = useState(null);
  const [ hideAnnouncement, setHideAnnouncement ] = useState(false);
  const [ hideLiveTransaction, setHideLiveTransaction ] = useState(false);
  const [ skipDepositReward, setSkipDepositReward ] = useState(null);
  const [ compactAppBar, setCompactAppBar ] = useState(false);
  const [ noNeonLights, setNoNeonLights ] = useState(false);
  const { user, update: updateUser } = useAuth();

  const {
    defaultLocale,
  } = useContext(CompanySettingContext);

  const userId = useMemo(
    () => {
      return get(user, '_id');
    }, [user]
  );

  useEffect(
    () => {
      /*
        This effect is to set the language when the user is not logged in.
        When the user is logged in, the language is set in the next effect.
      */
      if (user) return;

      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get('lang');

      if (lang) {
        setLang(lang);
        return;
      }

      if (defaultLocale) {
        setLang(defaultLocale);
        return;
      }
    }, [user, defaultLocale]
  );

  useEffect(
    () => {
      if (!user) return;
      const companyId = get(user, 'companyId');
      const lang = get(user, 'lang', 'en');
      const darkMode = get(user, 'settings.darkMode', false);
      const themeName = get(user, 'settings.themeName', '');
      const hideAnnouncement = get(user, 'settings.hideAnnouncement', false);
      const hideLiveTransaction = get(user, 'settings.hideLiveTransaction', false);
      const skipDepositReward = get(user, 'settings.skipDepositReward', null);
      const compactAppBar = get(user, 'settings.compactAppBar', false);
      const noNeonLights = get(user, 'settings.noNeonLights', false);

      setCompanyId(companyId);
      setLang(lang);
      setDarkMode(darkMode);
      setThemeName(themeName);
      setHideAnnouncement(hideAnnouncement);
      setHideLiveTransaction(hideLiveTransaction);
      setSkipDepositReward(skipDepositReward);
      setCompactAppBar(compactAppBar);
      setNoNeonLights(noNeonLights);

      localStorage.setItem('org', companyId);
    }, [user]
  );

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const toggleDarkMode = async () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);

    if (!userId) return;
    try {
      const savedUser = await feathers.service('users').patch(userId, { 'settings.darkMode': newDarkMode });
      updateUser(savedUser);
    } catch (err) {
      throw err;
    }
  };

  const syncSkipDepositReward = async (skipDepositReward) => {
    if (!userId) return;
    try {
      const savedUser = await feathers.service('users').patch(userId, { 'settings.skipDepositReward': skipDepositReward });
      updateUser(savedUser);
    } catch (err) {
      throw err;
    }
  };

  return {
    companyId,
    lang,
    setLang,
    darkMode,
    setDarkMode,
    toggleDarkMode,
    themeName,
    setThemeName,
    hideAnnouncement,
    hideLiveTransaction,
    skipDepositReward,
    syncSkipDepositReward,
    compactAppBar,
    noNeonLights,
  };
}
