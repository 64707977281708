import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import CommonContext from 'features/context/commonContext';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export default function ParticipantsPreviewDialog(props) {
  const { onClose, luckyDrawId } = props;
  const { t } = useTranslation();

  const { luckyDraws } = useContext(CommonContext);

  const luckyDraw = useMemo(
    () => {
      if (!luckyDrawId) return {};

      const findLuckyDraw = luckyDraws.find((luckyDraw) => luckyDraw._id === luckyDrawId) || null;
      return findLuckyDraw;
    }, [luckyDraws, luckyDrawId]
  );

  const isEndedWithNoParticipants = useMemo(
    () => {
      if (!luckyDraw) return false;

      const { state, participantCount = 0 } = luckyDraw || {};

      if (state !== 'done') return false;

      return participantCount === 0;
    }, [luckyDraw]
  );

  const data = useMemo(
    () => {
      if (!luckyDraw) return [];

      const { participants: usernames = [], participantCount = 0 } = luckyDraw || {};

      const startIndex = participantCount - (usernames.length - 1);

      const ret = usernames.map(function(u, index) {
        return {
          username: u,
          joinIndex: startIndex + index
        }
      });

      return ret;
    }, [luckyDraw]
  );

  const handleClose = (event) => {
    onClose(event);
  };

  const Row = ({ index, style }) => {
    const { username, joinIndex } = data[index];

    return (
      <Box style={style} sx={{ display: 'flex', p: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='subtitle1' color={'text.secondary'}>
          {username}
        </Typography>
        <Typography variant='h6' sx={{
          fontWeight: 700,
        }}>
          {`#${joinIndex}`}
        </Typography>
      </Box>
    );
  };

  return (
    <Dialog maxWidth='xs' fullWidth open={!!luckyDrawId} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>{t('Participant Preview')}</DialogTitle>
      {
        !!data.length ?
        <DialogContent dividers sx={{ overflowX: 'hidden', minHeight: '50vh' }}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="list-username"
                height={height}
                itemCount={data.length}
                itemSize={50}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </DialogContent> :
        <DialogContent dividers>
          <Typography variant='body1' color={'text.secondary'}>
            {
              isEndedWithNoParticipants ?
              t('Event ended with no participant') :
              t('No participant')
            }
          </Typography>
        </DialogContent>
      }
      <DialogActions>
        <Button variant='contained' autoFocus onClick={handleClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ParticipantsPreviewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};
