import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';
import useGameLogo from 'hooks/useGameLogo';

import podiumBase from 'assets/images/game/podium/base.png';
import podiumRedLight from 'assets/images/game/podium/red_light.png';
import podiumGreenLight from 'assets/images/game/podium/green_light.png';

export default function Games(props) {
  const { isEnabled, gameType, gameBalance, onClick } = props;
  const { getGameImagePath } = useGameLogo();

  const balanceAnimation = useMemo(
    () => {
      return keyframes`
        from, to { transform: scale(1, 1); }
        25% { transform: scale(0.9, 1.1); }
        50% { transform: scale(1.1, 0.9); }
        75% { transform: scale(0.95, 1.05); }
      `;
    }, []
  );

  const lightAnimation = useMemo(
    () => {
      return keyframes`
         0% {
          display: block;
          opacity: 1;
        }
        50% {
          display: block;
          opacity: 0.25;
        }

        100% {
          display: block;
          opacity: 1;
        }
      `;
    }, []
  );

  return (
    <Box sx={{
      ...(
        !isEnabled && {
          filter: 'grayscale(100%)'
        }
      ),
      position: 'relative',
      textAlign: 'center'
    }}>
      <Box
        component='img'
        src={podiumBase}
        sx={{
          position: 'absolute',
          width: '7rem',
          height: '7rem',
          left: '50%',
          top: 0,
          zIndex: 15,
          transform: 'translate(-52%, 0%)',
        }}
      />
      <Box
        component='img'
        src={!!gameBalance ? podiumGreenLight : podiumRedLight}
        sx={{
          position: 'absolute',
          width: '7rem',
          height: '7rem',
          left: '50%',
          top: 0,
          zIndex: 16,
          transform: 'translate(-52%, 0%)',
          ...(
            isEnabled && {
              animation: `${lightAnimation} 1s infinite linear`,
            }
          )
        }}
      />
      <Box
        onClick={onClick}
        component='img'
        src={getGameImagePath(gameType)}
        alt={`${gameType} logo`}
        sx={{
          cursor: 'pointer',
          position: 'relative',
          width: '5rem',
          height: '5rem',
          zIndex: 20,
          ...(
            isEnabled && !!gameBalance && {
              animation: `${balanceAnimation} 0.5s infinite linear`
            }
          ),
        }}
      />
    </Box>
  );
}
