import React, { useRef, useLayoutEffect, useState, useContext } from 'react';
import {
  Grid,
  Paper,
} from '@mui/material';
import ChatWindow from './ChatWindow';
import CommonContext from '../context/commonContext';
import { ProvideChatMessages } from '../../hooks/useChatMessages';

function ChatLayout() {
  const chatContainerRef = useRef(null);
  const [ containerHeight, setContainerHeight ] = useState(0);
  const { appBarHeight } = useContext(CommonContext);

  useLayoutEffect(() => {
    if (chatContainerRef && chatContainerRef.current) {
      const containerTop = chatContainerRef.current.getBoundingClientRect().top;
      const appBar = document.getElementById('appBar');
      const appBarHeight = appBar?.offsetHeight || 0;
      const totalOffset = appBarHeight + containerTop + 24;

      setContainerHeight(`calc(100vh - ${totalOffset}px)`);
    }
  }, [appBarHeight, chatContainerRef]);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        ref={chatContainerRef}
        spacing={1}
        sx={{
          height: containerHeight,
        }}
      >
        <Grid item xs={12}>
          <ChatWindow />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function Chats() {
  return (
    <ProvideChatMessages>
      <ChatLayout />
    </ProvideChatMessages>
  );
}