import React, { useState, useContext } from 'react';
import {
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import CompanySettingContext from 'features/context/companySettingContext';
import { lookups as lookupLocales } from 'lookups/locales';
import i18n from 'i18n';

const breatheAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const BreathingIconButton = styled(IconButton)(({ theme }) => ({
  animation: `${breatheAnimation} 3s ease-in-out 3`,
}));

const LanguageCardHeader = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { supportedLocales } = useContext(CompanySettingContext);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    query.lang = language;
    i18n.changeLanguage(language);
    const newQuery = qs.stringify(query);
    navigate(`?${newQuery}`);
    handleMenuClose();
  };

  return (
    <CardHeader
      action={
        <>
          <BreathingIconButton
            aria-label="language"
            aria-controls="language-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="primary"
          >
            <LanguageIcon />
          </BreathingIconButton>
          <Menu
            id="language-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {
              supportedLocales.map((locale) => (
                <MenuItem
                  key={locale}
                  onClick={() => handleLanguageChange(locale)}
                >
                  {lookupLocales[locale]}
                </MenuItem>
              ))
            }
          </Menu>
        </>
      }
      {...props}
    />
  );
};

export default LanguageCardHeader;
