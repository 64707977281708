/*
  Date and time utility functions

  Version 0.1.0

  This file contains utility functions for working with date and time.

  --- Changelog ---
  [0.1.0] - 2023-07-13:
    - Added isBetweenHour function to check if the current time falls within a specified time range.
*/

const dayjs = require('dayjs');
/*
  Function to check whether now is between startsAtTime and endsAtTime
*/
export function isBetweenHour(startsAtTime, endsAtTime) {
  if (!startsAtTime || !endsAtTime) return false;
  if (!dayjs(startsAtTime).isValid() || !dayjs(endsAtTime).isValid()) return false;

  const nowTime = dayjs();
  const startsAtTimeObj = dayjs(startsAtTime);
  const endsAtTimeObj = dayjs(endsAtTime);

  // Convert time values to seconds for easier comparison
  const nowSeconds = nowTime.diff(nowTime.startOf('day'), 'seconds');
  const startsAtSeconds = startsAtTimeObj.diff(startsAtTimeObj.startOf('day'), 'seconds');
  const endsAtSeconds = endsAtTimeObj.diff(endsAtTimeObj.startOf('day'), 'seconds');

  // Check if endsAtTime is smaller than startsAtTime, indicating a range spanning two days
  if (endsAtSeconds < startsAtSeconds) {
    // Different day, check if nowTime is between startsAtTime and ends of the day or between start of the day and endsAtTime
    if ((nowSeconds >= startsAtSeconds && nowSeconds <= 86399) || (nowSeconds >= 0 && nowSeconds <= endsAtSeconds)) {
      return true;
    }
  } else {
    // Same day, check if nowTime is between startsAtTime and endsAtTime
    if (nowSeconds >= startsAtSeconds && nowSeconds <= endsAtSeconds) {
      return true;
    }
  }

  return false;
}
