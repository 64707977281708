import React, { useMemo, useContext } from 'react';
import SeamlessAPIGame from './game/SeamlessAPIGame';
import APIGame from './game/APIGame';
import KioskGame from './game/KioskGame';
import CommonContext from 'features/context/commonContext';
import {
  useParams
} from "react-router-dom";
import { get, includes } from 'lodash';

const KIOSK_WITH_API_GAME_MENU = ['lpe88', 'ntc33'];

export default function Game() {
  const { gameType } = useParams();
  const { companySetting } = useContext(CommonContext);

  const kioskWithApiGameMenu = useMemo(
    () => {
      const hasProxy = get(companySetting, 'socksProxy.isEnabled', false);
      return includes(KIOSK_WITH_API_GAME_MENU, gameType) && hasProxy;
    }, [gameType, companySetting]
  );

  const gameProcessingType = useMemo(
    () => {
      return gameType.endsWith('-api') ? 'api' : 'kiosk';
    }, [gameType]
  );

  const showApiGame = useMemo(
    () => {
      return kioskWithApiGameMenu || gameProcessingType === 'api';
    }, [kioskWithApiGameMenu, gameProcessingType]
  );

  const showSeamlessApiGame = useMemo(
    () => {
      return gameType.endsWith('-sapi');
    }, [gameType]
  );

  if (showSeamlessApiGame) {
    return <SeamlessAPIGame />;
  }

  if (showApiGame) {
    return <APIGame />;
  }

  return <KioskGame />;
}
