import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Cropper from 'react-easy-crop'
import { getCroppedImg, getRotatedImage } from 'utils/canvas-utils';
import { getOrientation } from 'get-orientation/browser';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import ZoomOutIcon from '@mui/icons-material/ZoomOutTwoTone';
import ZoomInIcon from '@mui/icons-material/ZoomInTwoTone';
import RotateLeftIcon from '@mui/icons-material/RotateLeftTwoTone';
import RotateRightIcon from '@mui/icons-material/RotateRightTwoTone';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Decimal from 'decimal.js';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
} from '@mui/material';

const CropperArea = styled('div', {})(
  ({ theme }) => ({
    position: 'relative',
    height: 300,
    [theme.breakpoints.up('sm')]: {
      height: 340
    },
    '& .reactEasyCrop_Container': {
    }
  })
);

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  })
}

export default function CropperDialog(props) {
  const { open, file = null, onClose } = props;
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [ aspectRatio, setAspectRatio ] = useState('4/3');
  const { t } = useTranslation();

  useEffect(() => {
    if (!file) return;
    let isMounted = true;
    async function readFileAsync() {
      let imageDataUrl;
      try {
        imageDataUrl = await readFile(file);

        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        isMounted && setImageSrc(imageDataUrl);
      }
    }

    readFileAsync();

    return () => {
      isMounted = false;
    };
  }, [file, setGlobalErrorMessage]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onConfirmCrop = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setImageSrc(null);
      onClose(croppedImage);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [imageSrc, croppedAreaPixels, rotation, setGlobalErrorMessage, onClose]);

  const onCancelCrop = useCallback(() => {
    setImageSrc(null);
    setCroppedAreaPixels(null);
    onClose(null);
  }, [onClose]);

  const handleClose = () => {
    onClose();
  };

  const handleAspectRatioChanged = (ar) => (event) => {
    event.preventDefault();
    setAspectRatio(ar);
  };

  function parse(arStr) {
    const arArr = arStr.split('/');
    if (arArr.length !== 2) return 1;
    const a1 = new Decimal(arArr[0]);
    const a2 = new Decimal(arArr[1]);
    return a1.dividedBy(a2).toNumber();
  }

  if (!imageSrc) return null;

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      {/* <DialogTitle>
        {t('Image Editor')}
      </DialogTitle> */}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={8} sx={{ p: 1 }}>
              <CropperArea>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={parse(aspectRatio)}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onRotationChange={setRotation}
                  onZoomChange={setZoom}
                />
              </CropperArea>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box
              textAlign='center'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              {
                ['4/3', '3/4', '3/2', '2/3', '16/9', '9/16', '1'].map(function(ar) {
                  return <Button size='small' variant={ar === aspectRatio ? 'contained' : 'outlined'} key={ar} onClick={handleAspectRatioChanged(ar)}>{ar}</Button>
                })
              }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1} direction="row" alignItems="center">
              <ZoomOutIcon />
              <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)} />
              <ZoomInIcon />
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <RotateLeftIcon />
              <Slider min={0} max={360} step={1} value={rotation} onChange={(e, rotation) => setRotation(rotation)} />
              <RotateRightIcon />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='secondary' onClick={onCancelCrop}>{t('Cancel')}</Button>
        <Button variant='contained' color='success' onClick={onConfirmCrop}>{t('Confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}

CropperDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
