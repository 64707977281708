import React from 'react';
import LanguageCardHeader from './LanguageCardHeader.js';
import useImageAssetDownloader from 'hooks/useImageAssetDownloader';
import {
  Avatar,
  Skeleton,
  Typography,
} from '@mui/material';

export default function AvatarLogo(props) {
  const { title, tagline, brandLogo, name } = props;
  const { image, isLoaded } = useImageAssetDownloader({ assetId: brandLogo });

  return (
    <LanguageCardHeader
      title={<Typography variant='h5'>{title}</Typography>}
      subheader={<Typography variant='subtitle2'>{tagline}</Typography>}
      avatar={
        isLoaded
          ? <Avatar variant='rounded' alt={name} src={image} />
          : <Skeleton animation='wave' width={40} height={40} variant='rounded' />
      }
    />
  );
}
