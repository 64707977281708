import React, { useContext, useMemo } from 'react';
import AvatarLogo from './header/AvatarLogo';
import MediaLogo from './header/MediaLogo';
import CompanySettingContext from 'features/context/companySettingContext';
import useQuery from 'hooks/useQuery';

export default function CardHeader(props) {
  const query = useQuery();
  const { pageConfig, defaultLocale, ready } = useContext(CompanySettingContext);

  const lang = query.get('lang') || defaultLocale;

  const title = useMemo(() => {
    return pageConfig?.title?.[lang] || '';
  }, [pageConfig, lang]);

  const tagline = useMemo(() => {
    return pageConfig?.tagline?.[lang] || '';
  }, [pageConfig, lang]);

  const brandLogoVariant = useMemo(() => {
    return pageConfig?.brandLogoVariant || 'avatar';
  }, [pageConfig]);

  const brandLogo = useMemo(() => {
    if (brandLogoVariant === 'media') {
      return pageConfig?.brandLogo || 'https://placehold.co/600x280';
    }

    return pageConfig?.brandLogo || 'logo512.png';
  }, [pageConfig, brandLogoVariant]);

  const name = useMemo(() => {
    return pageConfig?.pwaManifest?.name?.[lang] || '';
  }, [pageConfig, lang]);

  if (!ready) return null;

  if (brandLogoVariant === 'media') return <MediaLogo title={title} brandLogo={brandLogo} name={name} />

  return <AvatarLogo title={title} tagline={tagline} brandLogo={brandLogo} name={name} />
}
