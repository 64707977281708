import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { get, unset, set } from 'lodash';
import useImageDownloader from 'hooks/useImageDownloader';

export default function ImageAvatar(props) {
  const imageId = get(props, 'imageId');
  const imageVariant = get(props, 'imageVariant', 'original');
  const avatarVariant = get(props, 'variant', 'circular')

  const avatarWidth = get(props, 'width', get(props, 'sx.width', 40));
  const avatarHeight = get(props, 'height', get(props, 'sx.height', 40));
  const avatarBgcolor = get(props, 'sx.bgcolor');

  const skeletonSX = {};
  if (avatarBgcolor) set(skeletonSX, 'bgcolor', avatarBgcolor);
  if (avatarVariant === 'rounded') set(skeletonSX, 'borderRadius', 1);

  const newProps = { ...props };
  unset(newProps, 'imageId');
  unset(newProps, 'imageVariant');

  const { image, imageReady } = useImageDownloader({
    id: imageId,
    variant: imageVariant
  });

  if (!imageReady) return <Skeleton sx={skeletonSX} pulse='wave' variant={avatarVariant} width={avatarWidth} height={avatarHeight} />;
  return <Avatar src={image} {...newProps} />;
}
