import axios from 'services/axios';
import { get } from 'lodash';

export const getVersion = async (companyId) => {
  try {
    const response = await axios.get(`/company-settings?companyId=${companyId}`);
    const version = get(response, 'data.data[0].walletConfig.walletAppVersion');
    return version;
  } catch (error) {
    console.error(`Error fetching version: ${error}`);
    return null;
  }
};
