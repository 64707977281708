import React, { useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Typography,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useChats } from '../../hooks/useChats';

function TextRenderer({ text }) {
  const splitText = text.split(/\r?\n/g);

  return (
    <Box>
      {
        splitText.map((line, index) => {
          if (!line) {
            return <br key={index} />;
          }
          return (
            <Typography key={index} variant="body2">
              {line}
            </Typography>
          );
        })
      }
    </Box>
  );
}

function ImageRenderer({ media, thumbnail, text }) {
  const { distributionDomain } = useChats();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const imageSrc = useMemo(() => {
    if (thumbnail) {
      return `https://${distributionDomain}/${thumbnail.objectKey}`;
    }
    return `https://${distributionDomain}/${media.objectKey}`;
  }, [distributionDomain, media, thumbnail]);

  const fullImageSrc = useMemo(() => {
    return `https://${distributionDomain}/${media.objectKey}`;
  }, [distributionDomain, media]);

  return (
    <Box>
      <Box
        component="img"
        src={imageSrc}
        alt={media.objectKey}
        onClick={handleOpen}
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
          cursor: 'pointer',
        }}
      />
      {
        text && (
          <Box>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {text}
            </Typography>
          </Box>
        )
      }
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Box
            component="img"
            src={fullImageSrc}
            alt={media.objectKey}
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function VideoRenderer({ media, thumbnail, text }) {
  const { distributionDomain } = useChats();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const hasThumbnail = thumbnail && thumbnail.objectKey;

  const thumbnailSrc = useMemo(() => {
    if (hasThumbnail) {
      return `https://${distributionDomain}/${thumbnail.objectKey}`;
    }
    return null;
  }, [distributionDomain, thumbnail, hasThumbnail]);

  const videoSrc = useMemo(() => {
    return `https://${distributionDomain}/${media.objectKey}`;
  }, [distributionDomain, media]);

  return (
    <Box>
      <Box
        component={hasThumbnail ? 'img' : 'video'}
        src={hasThumbnail ? thumbnailSrc : videoSrc}
        {
          ...(hasThumbnail ? {
            alt: thumbnail.objectKey,
          } : {
            controls: true,
          })
        }
        onClick={handleOpen}
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
          cursor: 'pointer',
        }}
      />
      {
        text && (
          <Box>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {text}
            </Typography>
          </Box>
        )
      }
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Box
            component="video"
            src={videoSrc}
            controls
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function AudioRenderer({ media }) {
  const { distributionDomain } = useChats();

  const audioSrc = useMemo(() => {
    return `https://${distributionDomain}/${media.objectKey}`;
  }, [distributionDomain, media]);

  return (
    <Box>
      <Box
        component="audio"
        src={audioSrc}
        controls
      />
    </Box>
  );
}

function DocumentRenderer({ media, thumbnail, text }) {
  const { distributionDomain } = useChats();
  const { t } = useTranslation();

  const thumbnailSrc = useMemo(() => {
    if (thumbnail) {
      return `https://${distributionDomain}/${thumbnail.objectKey}`;
    }
    return null;
  }, [distributionDomain, thumbnail]);

  const documentSrc = useMemo(() => {
    return `https://${distributionDomain}/${media.objectKey}`;
  }, [distributionDomain, media]);

  const fileName = useMemo(() => {
    let ret = [t('Download')];
    if (media?.metadata?.fileName) {
      ret.push(media.metadata.fileName);
    }

    return ret.join(' ');
  }, [media, t]);

  return (
    <Box>
      {
        thumbnailSrc && (
          <Box
            component="img"
            src={thumbnailSrc}
            alt={media.objectKey}
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        )
      }
      <Box>
        <Link
          href={documentSrc}
          target="_blank"
          rel="noreferrer"
          variant="body2"
        >
          {fileName}
        </Link>
        {
          text && (
            <Box>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {text}
              </Typography>
            </Box>
          )
        }
      </Box>
    </Box>
  );
}

function LocationRenderer({ location }) {
  const { t } = useTranslation();
  const { latitude, longitude } = location;
  if (!latitude || !longitude) {
    return null;
  }

  return (
    <Box>
      <Link
        href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
        target="_blank"
        rel="noreferrer"
        variant="body2"
      >
        {t('Open in Google Maps')}
      </Link>
      <Box>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {latitude}, {longitude}
        </Typography>
      </Box>
    </Box>
  );
}

function StickerRenderer({ media, thumbnail }) {
  const { distributionDomain } = useChats();

  const stickerSrc = useMemo(() => {
    if (thumbnail) {
      return `https://${distributionDomain}/${thumbnail.objectKey}`;
    }
    return `https://${distributionDomain}/${media.objectKey}`;
  }, [distributionDomain, media, thumbnail]);

  return (
    <Box>
      <Box
        component="img"
        src={stickerSrc}
        alt={media.objectKey}
        sx={{
          maxWidth: '150px',
          maxHeight: '150px',
        }}
      />
    </Box>
  );
}

export function ChatMessage({ message }) {
  const { text, media, thumbnail, location, type } = message;

  return (
    <Box>
      {
        type === 'text' && (
          <TextRenderer text={text} />
        )
      }
      {
        type === 'image' && (
          <ImageRenderer media={media} thumbnail={thumbnail} text={text} />
        )
      }
      {
        type === 'video' && (
          <VideoRenderer media={media} thumbnail={thumbnail} text={text} />
        )
      }
      {
        type === 'audio' && (
          <AudioRenderer media={media} />
        )
      }
      {
        type === 'document' && (
          <DocumentRenderer media={media} thumbnail={thumbnail} text={text} />
        )
      }
      {
        type === 'location' && (
          <LocationRenderer location={location} />
        )
      }
      {
        type === 'sticker' && (
          <StickerRenderer media={media} thumbnail={thumbnail} />
        )
      }
    </Box>
  );
}
