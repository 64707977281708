import ContentContext from './announcementContext';
import React, { useMemo, useContext } from 'react';
import useFeathers from 'hooks/useFeathers';
import dayjs from 'dayjs';
import { get, each, includes, sortBy } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import CompanySettingContext from 'features/context/companySettingContext';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isoWeek);
dayjs.extend(isBetween);

function AnnouncementProvider({ children }) {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { companyId: guestCompanyId } = useContext(CompanySettingContext);

  const queryFetchAll = useMemo(
    () => {
      return {
        query: {
          ...(guestCompanyId && {
            companyId: guestCompanyId
          }),
          $limit: -1
        }
      };
    }, [guestCompanyId]
  );

  const { data, ready } = useFeathers('announcements', queryFetchAll);

  const announcements = useMemo(
    () => {
      if (!ready) return [];

      let ret = [];

      each(data, announcement => {
        const isEnabled = get(announcement, 'isEnabled');
        if (!isEnabled) return;

        const startsAt = get(announcement, 'startsAt');
        const endsAt = get(announcement, 'endsAt');
        if (!startsAt || !endsAt) return;

        const isActivePeriod = dayjs().isBetween(dayjs(startsAt), dayjs(endsAt), 'second', '[)');
        if (!isActivePeriod) return;

        const daysAllowed = get(announcement, 'daysAllowed', []);
        const todayIndex = dayjs().isoWeekday();
        const isAllowed = includes(daysAllowed, todayIndex);
        if (!isAllowed) return;

        const id = get(announcement, '_id');
        const text = get(announcement, `text[${lang}]`, '');
        const { speed, delay, loop, isPlay } = announcement;

        ret.push({
          id,
          text,
          speed,
          delay,
          loop,
          isPlay,
          startsAt,
        });
      });

      const sorted = sortBy(ret, [
        function (d) {
          return dayjs(d.startsAt).unix();
        }
      ]);

      return sorted;
    }, [data, ready, lang]
  );

  return (
    <ContentContext.Provider value={{
      data: announcements,
      ready,
    }}>
      {children}
    </ContentContext.Provider>
  );
}

export default AnnouncementProvider;
