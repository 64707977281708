import React, { useContext, useState, useMemo, useEffect } from 'react';
import { _CASH_SYMBOL, _POINT_SYMBOL } from 'utils/symbol';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CommonContext from 'features/context/commonContext';
import SupportContext from 'features/context/supportContext';
import { useAuth } from 'hooks/useAuth';
import { get, filter, shuffle } from 'lodash';
import Decimal from 'decimal.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'features/copyToClipboard/CopyToClipboard';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/InfoTwoTone';
import CloseIcon from '@mui/icons-material/HighlightOff';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { useTranslation } from 'react-i18next';
import { generateBotUrl } from 'utils/social';
import QRBackdrop from 'features/qrBackdrop/QRBackdrop';
import Alert from '@mui/material/Alert';
import CountUp from 'react-countup';
import Grow from '@mui/material/Grow';
import ReferralChart from './Chart';

export default function Referral(props) {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const supportedLocales = get(user, 'supportedLocales', ['en']);
  const [ showInfo, setShowInfo ] = useState(false);
  const { userData } = useContext(CommonContext);
  const { tgBots, waBots, lineBots } = useContext(SupportContext);
  const [ qrBackdrop, setQRBackdrop ] = useState({
    open: false,
    link: null,
    logo: null,
  });

  const referrerUsername = useMemo(() => (get(userData, 'referrerUsername', '')), [userData]);
  const refereeCount = useMemo(() => (get(userData, 'refereeCount', 0)), [userData]);

  const decPointRewardAmount = useMemo(
    () => (
      new Decimal(get(userData, 'referralReward.point.amount.$numberDecimal', '0'))
    ), [userData]
  );

  const decCashRewardAmount = useMemo(
    () => (
      new Decimal(get(userData, 'referralReward.cash.amount.$numberDecimal', '0'))
    ), [userData]
  );

  const referralRewardType = useMemo(() => (get(user, 'referralReward.type', 'point')), [user]);
  const isCashReward = useMemo(() => (referralRewardType === 'both' || referralRewardType === 'cash'), [referralRewardType]);
  const isPointReward = useMemo(() => (referralRewardType === 'both' || referralRewardType === 'point'), [referralRewardType]);

  const decPointMultiplier = useMemo(
    () => (
      new Decimal(get(user, 'referralReward.pointMultiplier.$numberDecimal', '0'))
    ), [user]
  );

  const decCashMultiplier = useMemo(
    () => (
      new Decimal(get(user, 'referralReward.cashMultiplier.$numberDecimal', '0'))
    ), [user]
  );

  const { t } = useTranslation();
  const [ cuRefCount, setCuRefCount ] = useState({ start: 0, end: 0 });
  const [ cuPointAmount, setCuPointAmount ] = useState({ start: 0, end: 0 });
  const [ cuCashAmount, setCuCashAmount ] = useState({ start: 0, end: 0 });

  const logo = useMemo(
    () => {
      return {
        telegram: '/images/logo/telegram.png',
        whatsapp: '/images/logo/whatsapp.png',
        line: '/images/logo/line.png'
      }
    }, []
  );

  const waLink = useMemo(
    () => {
      const waBot = shuffle(filter(waBots))[0];

      if (waBot) {
        const waUrl = generateBotUrl(waBot, 'whatsapp', lang);
        if (!waUrl) return null;
        return `${waUrl}${user._id}`;
      }
      return null;
    }, [waBots, user, lang]
  );

  const tgLink = useMemo(
    () => {
      const tgBot = shuffle(filter(tgBots))[0];

      if (tgBot) {
        const tgUrl = generateBotUrl(tgBot, 'telegram', lang);
        if (!tgUrl) return null;
        return `${tgUrl}${user._id}`;
      }
      return null;
    }, [tgBots, user, lang]
  );

  const lineLink = useMemo(
    () => {
      const lineBot = shuffle(filter(lineBots))[0];

      if (lineBot) {
        const lineUrl = generateBotUrl(lineBot, 'line', lang);
        if (!lineUrl) return null;
        return `${lineUrl}${user._id}`;
      }
      return null;
    }, [lineBots, user, lang]
  );

  const defaultLocaleIsThai = useMemo(() => (supportedLocales[0] === 'th'), [supportedLocales]);

  useEffect(() => {
    setCuRefCount(prev => {
      if (prev?.end === refereeCount) return prev;

      return {
        start: prev?.end,
        end: refereeCount
      }
    });
  }, [refereeCount]);

  useEffect(() => {
    setCuPointAmount(prev => {
      if (prev?.end === decPointRewardAmount.toNumber()) return prev;

      return {
        start: prev?.end,
        end: decPointRewardAmount.toNumber()
      }
    });
  }, [decPointRewardAmount]);

  useEffect(() => {
    setCuCashAmount(prev => {
      if (prev?.end === decCashRewardAmount.toNumber()) return prev;

      return {
        start: prev?.end,
        end: decCashRewardAmount.toNumber()
      }
    });
  }, [decCashRewardAmount]);

  const handleQRClicked = (type) => (event) => {
    event?.preventDefault();

    switch (type) {
      case 'telegram':
        setQRBackdrop({
          open: true,
          data: tgLink,
          logo: logo.telegram,
          fileName: 'invite-telegram',
        });
        break;

      case 'whatsapp':
        setQRBackdrop({
          open: true,
          data: waLink,
          logo: logo.whatsapp,
          fileName: 'invite-whatsapp',
        });
        break;

      case 'line':
        setQRBackdrop({
          open: true,
          data: lineLink,
          logo: logo.line,
          fileName: 'invite-line',
        });
        break;

      default:
        break;
    }
  };

  const handleQRBackdropClose = () => {
    setQRBackdrop({
      open: false,
      data: null,
      logo: null,
    });
  };

  function onInfoClicked(event) {
    event.preventDefault();
    setShowInfo(prev => !prev);
  }

  function showTelegramCard() {
    const disabled = !tgLink;
    return (
      <Card elevation={4}>
        <CardHeader
          avatar={
            <Avatar src={logo.telegram} alt='Telegram logo' />
          }
          title={t('Share at Telegram')}
          action={
            <IconButton disabled={disabled} color='inherit' onClick={handleQRClicked('telegram')}>
               <QrCodeIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent sx={{ display: 'flex', justifyContent: 'right' }}>
          <CopyToClipboard text={tgLink} copiedText={t('Referral link copied')}>
            <Button
              disabled={disabled}
              variant='contained'
              endIcon={<CopyIcon />}
              sx={{
                bgcolor: '#0088cc',
                '&:hover': { bgcolor: '#006ca3' },
                color: theme => theme.palette.getContrastText('#0088cc')
              }}
            >
              {t('Get referral link')}
            </Button>
          </CopyToClipboard>
        </CardContent>
      </Card>
    );
  }

  function showWhatsAppCard() {
    const disabled = !waLink;
    return (
      <Card elevation={4}>
        <CardHeader
          avatar={
            <Avatar src={logo.whatsapp} alt='WhatsApp logo' />
          }
          title={t('Share at WhatsApp')}
          action={
            <IconButton disabled={disabled} color='inherit' onClick={handleQRClicked('whatsapp')}>
               <QrCodeIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent sx={{ display: 'flex', justifyContent: 'right' }}>
          <CopyToClipboard text={waLink} copiedText={t('Referral link copied')}>
            <Button
              disabled={disabled}
              variant='contained'
              endIcon={<CopyIcon />}
              sx={{
                bgcolor: '#25D366',
                '&:hover': { bgcolor: '#1da851' },
                color: theme => theme.palette.getContrastText('#25D366')
              }}
            >
              {t('Get referral link')}
            </Button>
          </CopyToClipboard>
        </CardContent>
      </Card>
    );
  }

  function showLineCard() {
    if (!lineLink) return null;

    const disabled = !lineLink;

    return (
      <Card elevation={4}>
        <CardHeader
          avatar={
            <Avatar src={logo.line} alt='Line logo' />
          }
          title={t('Share at Line')}
          action={
            <IconButton disabled={disabled} color='inherit' onClick={handleQRClicked('line')}>
               <QrCodeIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent sx={{ display: 'flex', justifyContent: 'right' }}>
          <CopyToClipboard text={lineLink} copiedText={t('Referral link copied')}>
            <Button
              disabled={disabled}
              variant='contained'
              endIcon={<CopyIcon />}
              sx={{
                bgcolor: '#00c300',
                '&:hover': { bgcolor: '#009900' },
                color: theme => theme.palette.getContrastText('#00c300')
              }}
            >
              {t('Get referral link')}
            </Button>
          </CopyToClipboard>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <QRBackdrop {...qrBackdrop } onClose={handleQRBackdropClose} />
      <Grid container spacing={1}>
        {
          defaultLocaleIsThai && (
            <Grid item xs={12}>
              {
                showLineCard()
              }
            </Grid>
          )
        }
        <Grid item xs={12}>
          {
            showTelegramCard()
          }
        </Grid>
        <Grid item xs={12}>
          {
            showWhatsAppCard()
          }
        </Grid>
        {
          !defaultLocaleIsThai && (
            <Grid item xs={12}>
              {
                showLineCard()
              }
            </Grid>
          )
        }
        <Grid item xs={12}>
          <Card elevation={2}>
            <CardHeader
              title={t('Referral Stats')}
              subheader={!!referrerUsername ? t('My Referrer', { username: referrerUsername }) : ''}
              action={
                <IconButton color='inherit' onClick={onInfoClicked}>
                  {
                    showInfo ? <CloseIcon /> : <InfoIcon />
                  }
                </IconButton>
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                {
                  !!showInfo && (
                    <Grow in={!!showInfo}>
                      <Grid item xs={12}>
                        <Alert severity="info">
                          {`${t('Refer benefit')} ${_POINT_SYMBOL} 🟰 ♾️`}
                        </Alert>
                      </Grid>
                    </Grow>
                  )
                }
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: 2 }}>
                      <Typography variant='subtitle2' gutterBottom>
                        {
                          t('Referrals')
                        }
                      </Typography>
                      <CountUp
                        start={cuRefCount.start}
                        end={cuRefCount.end}
                        duration={0.88}
                        separator=" "
                        decimals={0}
                        decimal="."
                        prefix='👭 '
                        suffix=''
                      >
                        {({ countUpRef, start }) => (
                          <Typography sx={{ textAlign: 'right' }} variant="h6" ref={countUpRef} />
                        )}
                      </CountUp>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: 2 }}>
                    <Typography variant='subtitle2' gutterBottom>
                      {
                        `${t('Total Point Reward')}`
                      }
                    </Typography>
                    <CountUp
                      start={cuPointAmount.start}
                      end={cuPointAmount.end}
                      duration={0.88}
                      separator=" "
                      decimals={2}
                      decimal="."
                      prefix={`${_POINT_SYMBOL} `}
                      suffix=''
                    >
                      {({ countUpRef, start }) => (
                        <Typography gutterBottom sx={{ textAlign: 'right' }} variant="h6" ref={countUpRef} />
                      )}
                    </CountUp>
                    {
                      isPointReward ?
                        <Typography variant='subtitle2' sx={{ color: 'info.light', fontStyle: 'oblique' }}>
                          {t('On each deposit', { percentage: decPointMultiplier.times(100).toFixed(2) })}
                        </Typography> :
                        <Typography variant='subtitle2' sx={{ color: 'warning.light', fontStyle: 'oblique' }}>
                          {t('Apply via customer service')}
                        </Typography>
                    }
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: 2 }}>
                    <Typography variant='subtitle2' gutterBottom>
                      {
                        `${t('Total Cash Reward')}`
                      }
                    </Typography>
                    <CountUp
                      start={cuCashAmount.start}
                      end={cuCashAmount.end}
                      duration={0.88}
                      separator=" "
                      decimals={2}
                      decimal="."
                      prefix={`${_CASH_SYMBOL} `}
                      suffix=''
                    >
                      {({ countUpRef, start }) => (
                        <Typography gutterBottom sx={{ textAlign: 'right' }} variant="h6" ref={countUpRef} />
                      )}
                    </CountUp>
                    {
                      isCashReward ?
                        <Typography variant='subtitle2' sx={{ color: 'info.light', fontStyle: 'oblique' }}>
                          {t('On each deposit', { percentage: decCashMultiplier.times(100).toFixed(2) })}
                        </Typography> :
                        <Typography variant='subtitle2' sx={{ color: 'warning.light', fontStyle: 'oblique' }}>
                          {t('Apply via customer service')}
                        </Typography>
                    }
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4}>
            <ReferralChart data={userData} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
