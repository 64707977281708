import * as React from 'react';
import { SvgIcon } from '@mui/material';

/*
  Icons made by Freepik from www.flaticon.com
  https://www.flaticon.com/search?author_id=1&style_id=7&type=standard&word=

  Convert PNG to SVG
  https://convertio.co/png-svg/
*/

export function LineIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="24px"
        height="24px"
      >
        <path d="M25.12,44.521c-2.114,1.162-2.024-0.549-1.933-1.076c0.054-0.314,0.3-1.787,0.3-1.787c0.07-0.534,0.144-1.36-0.067-1.887 c-0.235-0.58-1.166-0.882-1.85-1.029C11.48,37.415,4.011,30.4,4.011,22.025c0-9.342,9.42-16.943,20.995-16.943S46,12.683,46,22.025 C46,32.517,34.872,39.159,25.12,44.521z M18.369,25.845c0-0.56-0.459-1.015-1.023-1.015h-2.856v-6.678 c0-0.56-0.459-1.015-1.023-1.015c-0.565,0-1.023,0.455-1.023,1.015v7.694c0,0.561,0.459,1.016,1.023,1.016h3.879 C17.91,26.863,18.369,26.406,18.369,25.845z M21.357,18.152c0-0.56-0.459-1.015-1.023-1.015c-0.565,0-1.023,0.455-1.023,1.015 v7.694c0,0.561,0.459,1.016,1.023,1.016c0.565,0,1.023-0.456,1.023-1.016V18.152z M30.697,18.152c0-0.56-0.459-1.015-1.023-1.015 c-0.565,0-1.025,0.455-1.025,1.015v4.761l-3.978-5.369c-0.192-0.254-0.499-0.406-0.818-0.406c-0.11,0-0.219,0.016-0.325,0.052 c-0.419,0.139-0.7,0.526-0.7,0.963v7.694c0,0.561,0.46,1.016,1.025,1.016c0.566,0,1.025-0.456,1.025-1.016v-4.759l3.976,5.369 c0.192,0.254,0.498,0.406,0.818,0.406c0.109,0,0.219-0.018,0.325-0.053c0.42-0.137,0.7-0.524,0.7-0.963V18.152z M36.975,20.984 h-2.856v-1.817h2.856c0.566,0,1.025-0.455,1.025-1.015c0-0.56-0.46-1.015-1.025-1.015h-3.879c-0.565,0-1.023,0.455-1.023,1.015 c0,0.001,0,0.001,0,0.003v3.842v0.001c0,0,0,0,0,0.001v3.845c0,0.561,0.46,1.016,1.023,1.016h3.879 c0.565,0,1.025-0.456,1.025-1.016c0-0.56-0.46-1.015-1.025-1.015h-2.856v-1.817h2.856c0.566,0,1.025-0.455,1.025-1.015 c0-0.561-0.46-1.016-1.025-1.016V20.984z" />
      </svg>
    </SvgIcon>
  );
};

export function CasinoIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M21 4304 c-21 -27 -21 -28 -21 -1153 1 -1215 -2 -1149 54 -1171 31
          -11 79 7 90 35 3 9 6 500 6 1091 l0 1074 135 0 134 0 7 -37 c13 -79 74 -185
          144 -254 103 -102 217 -149 361 -149 143 0 269 51 369 150 59 58 121 168 140
          248 l11 42 104 0 105 0 0 -1620 0 -1620 -105 0 -104 0 -11 43 c-19 78 -81 188
          -140 247 -99 99 -225 150 -369 150 -144 0 -258 -47 -361 -149 -70 -69 -131
          -175 -144 -253 l-7 -38 -134 0 -135 0 0 394 0 395 -26 20 c-35 28 -69 26 -99
          -4 l-25 -24 0 -439 c0 -429 0 -440 21 -466 l20 -26 2519 0 2519 0 20 26 21 27
          0 1717 0 1717 -21 27 -20 26 -330 0 c-316 0 -330 -1 -349 -20 -30 -30 -27 -83
          6 -109 22 -17 41 -21 100 -21 l73 0 -15 -42 c-88 -251 -401 -330 -595 -150
          -55 51 -109 136 -109 173 0 16 15 18 136 21 129 3 137 4 155 27 26 32 24 73
          -4 99 l-23 22 -2041 0 -2042 0 -20 -26z m1269 -137 c0 -32 -64 -138 -106 -177
          -25 -23 -71 -54 -102 -68 -49 -23 -70 -27 -152 -27 -85 0 -101 3 -154 29 -95
          46 -169 133 -197 228 l-8 28 360 0 c293 0 359 -2 359 -13z m766 -40 c22 -88
          65 -159 138 -233 108 -108 217 -154 366 -154 149 0 258 46 366 154 72 73 116
          146 139 236 l11 45 117 3 117 3 0 -1621 0 -1621 -117 3 -117 3 -11 45 c-23 90
          -67 163 -139 236 -108 108 -217 154 -366 154 -149 0 -258 -46 -366 -154 -73
          -74 -116 -145 -138 -233 l-12 -48 -117 -3 -117 -3 0 1621 0 1621 117 -3 117
          -3 12 -48z m858 26 c-21 -88 -99 -179 -192 -225 -61 -30 -74 -33 -162 -33 -88
          0 -101 3 -162 33 -93 46 -171 137 -192 225 l-6 27 360 0 360 0 -6 -27z m796
          -15 c47 -188 181 -326 369 -382 67 -20 209 -21 272 -2 178 54 312 185 362 354
          l21 72 118 0 118 0 0 -1620 0 -1620 -118 0 -118 0 -21 73 c-50 168 -184 299
          -362 353 -63 19 -205 18 -272 -2 -188 -56 -322 -194 -369 -381 l-11 -43 -119
          0 -120 0 0 1620 0 1620 120 0 119 0 11 -42z m-2628 -2940 c31 -14 77 -45 102
          -68 42 -39 106 -145 106 -177 0 -11 -66 -13 -359 -13 l-360 0 8 28 c33 112
          130 211 244 248 68 22 191 14 259 -18z m1642 -7 c91 -44 169 -136 190 -223 l6
          -28 -360 0 -360 0 6 28 c17 68 82 157 146 199 110 73 253 83 372 24z m1612 19
          c105 -36 191 -122 228 -228 l15 -42 -359 0 c-410 0 -382 -7 -334 88 35 71 125
          152 199 179 78 28 176 30 251 3z"/>
          <path d="M2345 3210 c-22 -5 -52 -13 -66 -18 -19 -8 -37 -7 -63 2 -29 10 -56
          10 -136 0 -112 -15 -137 -25 -150 -59 -8 -22 4 -391 16 -462 8 -53 34 -63 154
          -63 93 0 109 3 128 20 13 12 22 33 22 49 0 15 2 30 5 33 7 7 97 -11 108 -21 4
          -4 -12 -37 -36 -73 -96 -143 -147 -321 -147 -519 0 -123 11 -173 40 -189 11
          -6 165 -10 384 -10 l365 0 21 28 c24 30 24 44 -5 167 -22 93 -31 370 -16 461
          26 155 89 289 182 391 57 62 54 90 -22 181 -34 40 -71 75 -85 78 -18 5 -52 -7
          -119 -39 l-95 -46 -73 35 c-40 19 -104 41 -142 49 -75 16 -204 18 -270 5z
          m240 -155 c66 -15 134 -43 180 -75 43 -28 74 -25 159 17 42 20 78 35 81 32 3
          -3 -19 -42 -48 -86 -147 -218 -193 -549 -122 -870 l5 -23 -256 0 -257 0 6 103
          c11 195 83 361 207 481 l66 64 -35 66 c-35 64 -37 66 -76 66 -23 0 -83 9 -135
          21 -118 25 -158 20 -209 -29 -21 -21 -40 -44 -43 -52 -11 -32 -17 1 -24 133
          -7 131 -6 137 12 137 11 0 24 -8 31 -19 21 -33 58 -34 132 -1 119 52 213 62
          326 35z"/>
          <path d="M4020 3214 c-14 -3 -47 -12 -74 -20 -37 -10 -53 -11 -63 -3 -17 15
          -67 15 -175 -1 -68 -9 -91 -17 -109 -36 l-22 -23 11 -238 c11 -238 11 -238 37
          -260 24 -21 36 -23 135 -23 105 0 109 1 129 26 12 15 21 40 21 56 0 23 4 28
          18 24 9 -3 36 -10 60 -16 47 -12 47 -9 -5 -81 -104 -145 -163 -392 -144 -603
          11 -124 -16 -116 413 -116 234 0 366 4 379 11 38 20 40 50 12 181 -34 160 -38
          389 -9 513 25 111 84 232 156 321 33 41 60 82 60 90 0 23 -18 53 -69 116 -70
          88 -82 90 -197 34 l-95 -46 -47 24 c-97 49 -172 68 -287 71 -60 2 -121 1 -135
          -1z m273 -173 c32 -11 82 -34 112 -51 29 -16 64 -30 79 -30 15 0 58 16 96 35
          82 41 99 44 76 13 -183 -255 -233 -523 -170 -915 l7 -43 -253 0 -253 0 6 113
          c9 191 76 349 204 475 l64 63 -37 64 c-35 64 -37 65 -77 65 -23 0 -85 10 -137
          21 -79 18 -102 20 -136 10 -42 -11 -104 -64 -104 -88 0 -7 -4 -13 -10 -13 -13
          0 -19 46 -22 173 -4 119 1 127 54 82 37 -31 42 -31 114 2 33 15 82 33 109 40
          68 17 205 9 278 -16z"/>
          <path d="M656 3201 c-59 -15 -75 -16 -100 -6 -25 10 -51 10 -133 -1 -112 -14
          -141 -27 -150 -63 -3 -13 -2 -125 4 -247 12 -285 6 -274 165 -274 102 0 110 1
          133 25 15 15 25 36 25 54 0 32 -4 31 83 9 l38 -9 -36 -49 c-45 -62 -109 -197
          -129 -275 -42 -165 -44 -389 -4 -440 l19 -25 367 0 c273 1 371 4 383 13 35 27
          37 48 12 167 -21 97 -25 144 -26 295 -1 156 2 191 21 260 29 107 80 206 144
          285 66 80 71 93 54 133 -19 44 -113 146 -141 153 -15 4 -52 -9 -115 -41 l-93
          -47 -61 31 c-136 69 -318 89 -460 52z m327 -160 c32 -11 82 -34 112 -51 29
          -16 64 -30 79 -30 15 0 58 16 96 35 38 19 74 35 80 35 6 0 -9 -28 -33 -62 -55
          -78 -83 -132 -114 -223 -52 -154 -68 -392 -38 -589 8 -55 15 -101 15 -103 0
          -1 -115 -3 -256 -3 l-256 0 7 88 c17 216 77 364 202 490 l72 74 -35 63 -35 63
          -57 4 c-31 2 -96 13 -143 23 -83 18 -88 18 -133 1 -33 -13 -55 -30 -73 -57
          -14 -21 -30 -39 -34 -39 -5 0 -9 39 -9 88 0 48 -3 111 -6 140 -6 49 -5 52 15
          52 12 0 24 -6 27 -14 3 -8 18 -19 34 -26 24 -10 37 -8 100 20 39 17 90 35 113
          40 64 14 204 4 270 -19z"/>
        </g>
      </svg>
    </SvgIcon>
  );
};

export function RouletteIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M2325 5109 c-235 -24 -484 -83 -690 -164 -234 -91 -509 -249 -677
            -389 -59 -49 -68 -61 -68 -89 0 -40 34 -77 71 -77 16 0 66 31 136 84 268 203
            570 346 897 427 205 50 328 63 571 63 248 0 373 -15 590 -69 846 -213 1527
            -894 1740 -1740 55 -219 69 -342 69 -595 0 -253 -14 -376 -69 -595 -213 -846
            -894 -1527 -1740 -1740 -219 -55 -342 -69 -595 -69 -255 0 -368 13 -593 69
            -849 213 -1528 892 -1742 1740 -54 217 -69 342 -69 590 -1 279 21 440 94 689
            99 340 300 696 532 942 61 64 70 103 35 138 -44 43 -75 35 -144 -36 -165 -174
            -327 -417 -438 -658 -371 -811 -297 -1758 197 -2492 135 -202 343 -428 533
            -580 641 -512 1494 -684 2290 -461 929 260 1646 1043 1819 1987 156 845 -103
            1682 -705 2285 -398 398 -880 639 -1449 726 -126 19 -469 27 -595 14z"/>
          <path d="M2245 4660 c-225 -35 -415 -95 -620 -195 -636 -312 -1072 -918 -1169
            -1623 -21 -146 -21 -418 0 -564 96 -697 521 -1295 1149 -1613 298 -152 610
            -225 953 -225 259 0 438 28 672 106 768 255 1330 935 1435 1737 19 152 19 414
            -1 559 -132 961 -890 1708 -1854 1828 -134 16 -426 11 -565 -10z m235 -420 l0
            -290 -22 -4 c-13 -3 -50 -8 -83 -11 -54 -6 -226 -44 -265 -60 -13 -4 -39 49
            -125 257 -61 144 -110 267 -110 272 0 31 403 122 553 125 l52 1 0 -290z m420
            264 c79 -14 327 -78 337 -88 6 -6 -220 -545 -227 -541 -17 11 -210 54 -265 60
            -33 3 -70 8 -82 11 l-23 4 0 292 0 291 83 -6 c45 -4 125 -14 177 -23z m-1056
            -423 c58 -140 106 -260 106 -266 0 -7 -15 -19 -32 -26 -43 -17 -227 -141 -252
            -168 -11 -12 -24 -21 -30 -21 -6 0 -103 91 -214 203 l-203 203 38 35 c46 41
            180 143 243 184 77 49 225 124 231 117 4 -4 54 -121 113 -261z m1645 219 c121
            -64 240 -145 337 -227 l76 -66 -203 -203 c-112 -112 -209 -204 -215 -204 -6 0
            -19 9 -29 20 -23 25 -174 128 -243 165 -29 16 -51 34 -50 40 12 47 215 525
            223 525 6 0 52 -23 104 -50z m-1969 -816 c0 -6 -9 -19 -20 -29 -28 -25 -132
            -179 -164 -242 -14 -29 -29 -53 -33 -53 -7 0 -525 211 -532 217 -7 5 54 128
            107 213 53 86 131 194 193 263 l42 49 204 -204 c111 -111 203 -208 203 -214z
            m2570 321 c78 -96 154 -210 212 -322 l50 -93 -39 -16 c-21 -9 -139 -58 -262
            -110 -123 -52 -229 -94 -236 -94 -7 0 -19 15 -26 33 -17 42 -141 226 -168 251
            -12 11 -21 24 -21 30 0 6 91 102 202 213 165 165 205 200 216 190 8 -6 40 -43
            72 -82z m-1292 -30 c235 -45 462 -167 636 -341 175 -175 295 -399 343 -641 23
            -119 23 -347 0 -466 -48 -242 -168 -466 -343 -641 -175 -175 -399 -295 -641
            -343 -108 -21 -337 -23 -443 -4 -325 58 -632 256 -811 524 -152 227 -213 427
            -213 697 0 164 17 270 66 408 130 371 436 663 810 776 73 22 127 33 243 50 57
            8 271 -3 353 -19z m1629 -571 c48 -136 103 -418 103 -529 l0 -45 -110 0 c-97
            0 -112 -2 -130 -20 -22 -22 -26 -60 -10 -90 15 -28 64 -40 163 -40 l90 0 -6
            -82 c-10 -123 -35 -259 -73 -393 -19 -65 -36 -120 -38 -122 -2 -2 -124 47
            -273 108 l-269 111 23 86 c12 47 28 122 34 166 18 134 11 122 81 129 51 5 67
            10 85 32 28 32 29 55 2 89 -18 23 -27 26 -84 26 l-64 0 -5 33 c-19 135 -40
            244 -56 298 -11 35 -16 65 -12 67 4 2 122 52 262 111 140 60 259 108 264 107
            5 -1 16 -20 23 -42z m-3439 -79 c144 -59 260 -111 257 -115 -11 -17 -54 -210
            -60 -265 -3 -33 -9 -72 -11 -87 l-6 -28 -289 0 -289 0 0 48 c0 51 22 218 40
            297 25 118 73 266 85 262 6 -3 129 -53 273 -112z m186 -667 c3 -13 8 -50 11
            -83 6 -54 44 -225 60 -265 4 -12 -51 -39 -262 -128 -147 -61 -271 -108 -275
            -104 -13 13 -75 245 -92 342 -9 52 -19 132 -23 178 l-6 82 291 0 292 0 4 -22z
            m2911 -605 c143 -58 262 -108 264 -110 7 -5 -54 -128 -107 -213 -53 -86 -131
            -194 -193 -263 l-42 -49 -204 204 c-111 111 -203 208 -203 214 0 6 9 19 20 29
            28 25 132 179 164 243 14 28 30 52 34 52 4 0 124 -48 267 -107z m-2754 65 c17
            -43 141 -227 168 -252 12 -11 21 -24 21 -30 0 -6 -92 -103 -203 -214 l-204
            -204 -42 49 c-98 110 -199 260 -265 391 l-36 72 22 10 c194 84 497 209 509
            209 10 1 22 -13 30 -31z m334 -418 c25 -28 179 -132 243 -164 28 -14 52 -29
            52 -33 0 -7 -211 -525 -217 -532 -5 -7 -128 54 -213 107 -81 50 -212 146 -273
            201 l-38 35 203 203 c111 112 208 203 214 203 6 0 19 -9 29 -20z m2033 -183
            l204 -204 -49 -42 c-114 -101 -256 -197 -393 -266 l-71 -36 -15 38 c-9 21 -58
            139 -110 263 -52 123 -92 230 -90 237 3 6 24 22 48 34 56 28 210 133 233 159
            10 11 23 20 29 20 6 0 103 -92 214 -203z m-1368 -123 c58 -9 115 -18 128 -20
            l22 -4 0 -292 0 -291 -82 6 c-123 10 -259 35 -393 73 -65 19 -120 36 -122 38
            -2 1 47 124 108 273 l111 271 61 -19 c34 -10 109 -26 167 -35z m808 -211 c61
            -147 108 -271 104 -275 -13 -13 -245 -75 -342 -92 -52 -9 -132 -19 -177 -23
            l-83 -6 0 291 0 292 23 4 c12 2 58 9 102 15 44 6 118 22 165 35 47 13 87 24
            90 25 3 0 56 -119 118 -266z"/>
          <path d="M2477 3566 c-59 -22 -103 -61 -131 -118 -26 -51 -26 -54 -26 -285 l0
            -233 -65 -64 -64 -65 -244 -3 -244 -3 -49 -30 c-30 -19 -60 -49 -79 -79 -27
            -44 -30 -58 -30 -126 0 -68 3 -82 30 -126 19 -30 49 -60 79 -79 l49 -30 244
            -3 244 -3 64 -64 64 -64 3 -244 3 -244 30 -49 c19 -30 49 -60 79 -79 44 -27
            58 -30 126 -30 68 0 82 3 126 30 30 19 60 49 79 79 l30 49 3 244 3 244 64 64
            64 64 244 3 244 3 49 30 c30 19 60 49 79 79 27 44 30 58 30 126 0 68 -3 82
            -30 126 -19 30 -49 60 -79 79 l-49 30 -244 3 -244 3 -64 64 -64 64 -3 244 -3
            244 -30 49 c-42 68 -105 105 -187 110 -39 3 -77 -1 -101 -10z m142 -162 l31
            -26 0 -194 0 -194 -90 0 -90 0 0 194 0 194 31 26 c17 15 43 26 59 26 16 0 42
            -11 59 -26z m51 -585 c72 -32 106 -65 139 -130 123 -246 -132 -501 -378 -378
            -98 49 -151 136 -151 249 0 113 53 200 151 249 77 39 168 42 239 10z m-540
            -259 l0 -90 -194 0 -194 0 -26 31 c-15 17 -26 43 -26 59 0 16 11 42 26 59 l26
            31 194 0 194 0 0 -90z m1274 59 c15 -17 26 -43 26 -59 0 -16 -11 -42 -26 -59
            l-26 -31 -194 0 -194 0 0 90 0 90 194 0 194 0 26 -31z m-754 -683 l0 -194 -31
            -26 c-17 -15 -43 -26 -59 -26 -16 0 -42 11 -59 26 l-31 26 0 194 0 194 90 0
            90 0 0 -194z"/>
        </g>
      </svg>
    </SvgIcon>
  );
};

export function SlotIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M2510 4898 c-59 -17 -86 -45 -134 -138 -26 -49 -50 -90 -54 -90 -28
            0 -183 -30 -198 -38 -30 -16 -72 -64 -83 -95 -32 -85 1 -176 92 -250 53 -43
            54 -48 35 -169 -9 -59 -9 -87 0 -117 23 -77 130 -142 205 -125 18 3 67 24 110
            45 l77 39 78 -39 c42 -21 91 -42 109 -45 75 -17 182 48 205 125 9 30 9 58 0
            117 -19 120 -18 125 34 168 91 74 125 166 93 251 -11 31 -53 79 -83 95 -15 8
            -170 38 -198 38 -4 0 -28 41 -54 90 -49 95 -74 121 -139 139 -46 13 -48 13
            -95 -1z m72 -155 c9 -10 30 -47 48 -83 18 -36 40 -73 49 -84 27 -31 82 -53
            165 -65 43 -6 82 -14 87 -17 21 -12 5 -41 -56 -105 -91 -94 -100 -125 -75
            -268 10 -62 10 -75 -2 -88 -13 -12 -27 -8 -108 32 -59 28 -107 45 -130 45 -23
            0 -71 -17 -130 -45 -82 -40 -95 -44 -108 -31 -12 12 -13 25 -3 78 14 80 14
            149 1 184 -6 14 -40 57 -75 93 -61 64 -77 93 -56 105 5 3 44 11 87 17 83 12
            138 34 165 65 9 11 31 48 49 84 32 65 57 100 70 100 4 0 14 -8 22 -17z"/>
          <path d="M1406 4480 c-41 -13 -74 -47 -106 -111 l-29 -56 -58 -7 c-102 -11
            -163 -69 -163 -154 0 -54 11 -75 67 -134 l45 -46 -10 -60 c-17 -96 11 -162 82
            -196 49 -23 82 -20 156 15 l64 30 66 -30 c79 -37 102 -38 160 -8 66 34 83 72
            75 174 l-6 83 35 30 c39 34 76 104 76 141 -1 38 -36 106 -66 126 -15 9 -57 22
            -93 28 l-66 11 -35 65 c-28 51 -44 68 -80 87 -47 23 -67 26 -114 12z m83 -209
            c36 -71 75 -98 154 -107 31 -4 57 -9 57 -12 0 -3 -20 -26 -44 -52 -53 -56 -69
            -105 -56 -170 17 -84 17 -84 -50 -50 -77 39 -121 39 -195 0 l-56 -30 6 79 c8
            91 3 105 -63 173 -31 33 -41 49 -31 51 8 3 41 9 73 16 69 13 105 42 142 114
            13 26 26 47 29 47 3 0 18 -27 34 -59z"/>
          <path d="M3600 4468 c-36 -19 -52 -36 -80 -87 l-35 -65 -65 -11 c-37 -6 -79
            -20 -96 -32 -32 -23 -64 -85 -64 -125 0 -36 40 -107 77 -136 l34 -27 -7 -81
            c-7 -97 8 -141 62 -174 57 -36 94 -36 171 -1 l69 32 64 -30 c72 -34 104 -38
            154 -17 70 29 99 97 84 195 l-10 64 46 46 c50 51 66 85 66 139 0 79 -64 137
            -163 148 l-58 7 -29 56 c-33 67 -65 98 -111 111 -48 14 -59 13 -109 -12z m94
            -185 c37 -72 73 -101 142 -114 32 -7 65 -13 73 -16 10 -2 0 -18 -31 -51 -66
            -68 -71 -82 -64 -172 l7 -77 -57 28 c-74 37 -118 37 -194 -1 -67 -34 -67 -34
            -50 50 13 65 -3 114 -56 170 -24 26 -44 49 -44 52 0 3 26 8 57 12 79 9 118 36
            154 107 16 32 31 59 34 59 3 0 16 -21 29 -47z"/>
          <path d="M1173 3488 c-12 -6 -26 -22 -32 -37 -8 -23 -92 -1131 -91 -1218 0
            -19 8 -41 20 -53 19 -19 33 -20 300 -20 322 0 307 -4 329 93 26 117 120 218
            249 268 87 33 259 38 372 10 142 -35 260 -67 265 -73 3 -3 -20 -25 -52 -49
            -89 -66 -276 -253 -366 -364 -341 -422 -527 -933 -567 -1550 -14 -227 -15
            -222 19 -256 l30 -29 590 2 590 3 23 23 c21 21 23 34 29 195 21 540 174 1080
            442 1565 65 116 70 150 31 181 -36 28 -70 26 -100 -6 -38 -41 -236 -450 -294
            -606 -131 -353 -207 -712 -228 -1072 l-7 -130 -490 0 -490 0 3 80 c10 272 62
            546 152 810 50 146 167 388 247 510 177 270 417 505 676 660 82 50 98 74 81
            124 -12 35 -59 50 -156 50 -91 1 -138 10 -313 59 -126 36 -135 37 -290 36
            -147 0 -166 -2 -233 -26 -157 -57 -263 -149 -324 -284 l-33 -74 -177 0 -177 0
            5 33 c2 17 17 232 34 477 17 245 33 464 36 488 l5 42 130 0 129 0 0 -49 c0
            -59 31 -103 71 -103 14 0 65 16 115 35 219 87 515 132 714 108 127 -16 185
            -32 385 -106 217 -81 276 -96 380 -96 110 0 200 27 314 97 l86 52 142 -141
            143 -142 -64 -65 c-148 -152 -390 -456 -402 -506 -7 -29 18 -72 49 -83 40 -15
            73 11 158 124 112 150 215 270 337 393 85 87 102 109 102 135 0 28 -27 58
            -213 244 -179 180 -217 213 -242 213 -18 0 -55 -18 -101 -50 -92 -64 -189
            -110 -251 -119 -100 -14 -153 -1 -483 119 -137 50 -261 77 -390 86 -165 11
            -423 -25 -612 -85 l-88 -29 0 35 c0 23 -8 42 -25 58 l-24 25 -223 -1 c-134 0
            -232 -5 -245 -11z"/>
          <path d="M72 2000 c-27 -25 -27 -28 -52 -392 -23 -321 -22 -336 20 -358 10 -5
            85 -10 167 -10 172 0 183 4 208 80 28 83 100 130 203 130 46 0 131 -18 145
            -30 5 -4 -9 -22 -32 -40 -53 -43 -157 -167 -216 -258 -131 -203 -209 -463
            -222 -732 -5 -116 -4 -127 15 -150 l20 -25 326 -3 327 -3 28 28 c27 27 28 33
            34 158 11 206 61 418 147 617 22 50 40 100 40 110 0 52 -48 85 -98 67 -32 -11
            -74 -91 -131 -248 -55 -151 -111 -425 -111 -540 l0 -41 -226 0 -226 0 7 78
            c20 234 96 455 215 633 59 87 219 245 308 303 78 50 98 80 82 120 -12 33 -33
            42 -114 50 -38 4 -112 18 -164 31 -153 40 -278 28 -375 -38 -44 -30 -96 -91
            -110 -129 -5 -15 -18 -18 -67 -18 l-60 0 0 43 c0 23 7 125 15 227 8 102 15
            191 15 198 0 7 13 12 33 12 27 0 35 -5 44 -30 20 -53 51 -58 156 -23 211 71
            328 69 551 -8 138 -48 154 -51 220 -47 53 4 86 13 132 36 l61 31 46 -47 c26
            -26 47 -51 47 -56 0 -5 -26 -38 -58 -75 -92 -105 -162 -202 -162 -226 0 -37
            37 -75 74 -75 35 0 52 15 123 110 23 30 80 97 127 148 64 68 86 99 86 119 0
            21 -26 54 -123 150 -143 143 -148 145 -242 83 -96 -64 -139 -65 -294 -5 -184
            70 -354 82 -527 38 l-72 -19 -26 23 c-25 21 -36 23 -160 23 -118 0 -134 -2
            -154 -20z"/>
          <path d="M3523 2000 c-27 -24 -28 -31 -53 -402 -22 -311 -21 -326 20 -348 10
            -5 86 -10 169 -10 142 0 151 1 174 23 13 12 29 41 36 64 31 112 174 153 340
            97 9 -3 -14 -33 -68 -87 -222 -223 -347 -494 -390 -847 -17 -136 -14 -224 8
            -251 l19 -24 326 -3 327 -3 29 30 c28 28 30 34 30 108 1 203 64 473 164 696
            31 68 32 97 8 126 -20 26 -73 31 -98 10 -42 -34 -147 -315 -184 -490 -11 -52
            -25 -147 -32 -211 l-12 -118 -223 0 -223 0 0 28 c1 59 31 242 56 327 34 122
            118 293 191 390 74 99 185 205 280 267 79 52 97 75 87 112 -10 40 -35 53 -111
            60 -40 3 -118 18 -175 32 -114 30 -213 29 -300 -1 -58 -20 -142 -93 -169 -146
            -19 -39 -20 -39 -79 -39 l-60 0 0 38 c0 20 7 120 15 222 8 102 15 193 15 203
            0 13 8 17 33 17 27 0 35 -5 44 -30 20 -53 51 -58 156 -22 114 38 238 56 330
            49 46 -4 121 -24 220 -58 139 -47 156 -51 221 -47 53 4 86 13 132 36 l61 31
            51 -52 51 -52 -46 -50 c-84 -92 -183 -227 -183 -250 0 -12 10 -34 21 -49 17
            -22 28 -26 57 -24 32 3 44 12 100 83 35 44 101 122 148 173 61 68 84 100 84
            119 0 21 -27 54 -123 150 -141 142 -151 145 -231 88 -96 -68 -150 -70 -304
            -11 -185 71 -353 83 -528 38 l-72 -18 -26 23 c-25 22 -36 23 -160 23 -118 0
            -135 -2 -153 -20z"/>
        </g>
      </svg>
    </SvgIcon>
  );
};

export function TicketIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M2722 5043 c-102 -106 -107 -116 -92 -153 27 -66 83 -67 151 -3 l50
            47 155 -155 155 -155 -25 -44 c-35 -63 -48 -123 -47 -210 1 -169 92 -309 250
            -384 62 -29 73 -31 176 -31 104 0 114 2 180 33 l69 33 155 -156 156 -155 -144
            -144 c-132 -132 -153 -149 -255 -205 l-111 -60 -353 352 c-194 194 -457 459
            -584 590 l-231 237 47 50 c51 55 57 83 25 124 -15 19 -30 26 -53 26 -31 0
            -110 -77 -1045 -1019 -939 -947 -1011 -1021 -1011 -1053 0 -30 21 -54 228
            -261 172 -172 233 -227 252 -227 13 0 49 17 80 38 70 49 108 62 185 62 72 0
            142 -30 194 -84 l33 -34 -23 -15 c-13 -8 -139 -76 -279 -152 -140 -75 -258
            -142 -263 -148 -25 -40 -18 -58 123 -324 78 -148 151 -278 163 -291 25 -27 45
            -27 117 -2 110 39 222 15 297 -62 65 -68 78 -100 78 -198 0 -102 -19 -144 -92
            -209 -26 -24 -58 -52 -70 -63 -13 -11 -23 -29 -23 -41 0 -29 276 -547 306
            -574 14 -13 34 -23 46 -23 30 0 597 309 614 335 9 14 12 33 8 49 -7 28 -48 66
            -71 66 -8 0 -126 -61 -263 -135 -137 -74 -252 -135 -254 -135 -6 0 -206 374
            -206 385 0 4 11 16 24 26 13 10 40 43 59 72 204 307 -23 702 -388 674 -38 -3
            -77 -8 -85 -11 -11 -4 -40 42 -117 186 -56 106 -100 195 -97 199 2 4 79 47
            172 97 203 108 2433 1311 2585 1393 59 33 111 59 115 59 4 0 54 -86 109 -192
            l102 -192 -45 -48 c-25 -27 -60 -79 -77 -116 -30 -63 -32 -74 -32 -177 0 -103
            2 -114 32 -177 83 -176 266 -278 447 -250 39 6 74 8 78 4 4 -4 52 -91 106
            -194 l99 -187 -1026 -552 c-564 -303 -1034 -561 -1043 -571 -39 -44 -8 -122
            48 -121 30 0 2142 1131 2176 1165 10 10 18 30 18 45 0 28 -264 539 -297 576
            -20 21 -47 20 -149 -8 -51 -14 -70 -15 -116 -6 -137 29 -226 135 -228 272 0
            103 37 174 126 240 45 33 54 45 54 71 0 20 -42 111 -125 268 -69 130 -125 241
            -125 246 0 4 70 78 155 164 139 140 155 160 155 190 0 32 -20 54 -228 262
            -175 176 -233 228 -253 228 -13 0 -48 -18 -78 -40 -65 -49 -116 -64 -203 -58
            -143 11 -248 123 -250 266 -1 75 15 123 65 194 29 41 38 62 34 80 -4 13 -106
            126 -229 251 -270 276 -252 268 -366 150z m678 -1814 c0 -3 -39 -26 -87 -52
            -49 -26 -125 -67 -171 -92 -53 -28 -84 -40 -86 -32 -1 7 -10 35 -19 63 -16 48
            -16 54 -1 90 21 50 21 138 0 189 -23 53 -81 113 -135 137 -54 25 -147 26 -211
            4 -25 -9 -48 -16 -51 -16 -3 0 3 30 12 68 22 84 23 115 4 178 -20 66 -64 117
            -131 150 -66 32 -147 36 -206 11 -34 -14 -41 -15 -75 0 -20 8 -63 15 -95 15
            -112 0 -212 -73 -243 -176 -20 -65 -19 -98 4 -179 10 -37 16 -67 12 -67 -3 0
            -28 7 -55 15 -67 21 -162 19 -211 -4 -58 -26 -121 -99 -139 -162 -20 -67 -20
            -91 3 -159 18 -51 18 -56 3 -85 -10 -19 -16 -54 -16 -95 0 -79 33 -151 91
            -199 39 -32 116 -61 163 -61 25 0 116 21 152 35 17 7 17 5 2 -36 -10 -26 -17
            -77 -18 -124 -1 -80 5 -102 48 -167 l18 -27 -34 -21 c-70 -43 -43 -64 -461
            356 l-385 388 591 599 591 598 568 -568 c312 -312 568 -571 568 -574z m-1217
            558 c18 -8 38 -21 46 -30 19 -24 83 -22 106 3 49 55 140 42 172 -24 14 -31 15
            -43 4 -90 -11 -51 -57 -152 -116 -256 -31 -56 -31 -74 0 -105 33 -34 67 -31
            134 9 92 55 158 86 221 102 54 14 63 14 93 -1 65 -30 80 -110 33 -166 -17 -19
            -26 -43 -26 -64 0 -21 9 -45 26 -64 68 -81 1 -194 -103 -175 -53 9 -134 44
            -228 98 -44 25 -90 46 -103 46 -29 0 -72 -42 -72 -71 0 -11 24 -64 54 -117 85
            -150 88 -137 -51 -211 -65 -35 -151 -82 -191 -103 l-72 -40 -35 35 c-26 26
            -35 43 -35 69 0 48 32 137 82 227 67 124 70 130 63 151 -9 29 -45 60 -69 60
            -12 0 -57 -21 -101 -46 -211 -121 -312 -131 -352 -37 -17 43 -11 76 23 116 30
            35 32 87 4 112 -25 23 -43 79 -35 107 4 13 19 35 33 51 48 51 151 31 313 -63
            100 -58 137 -63 168 -24 12 15 21 33 21 41 0 7 -25 60 -55 117 -58 108 -95
            203 -95 243 0 39 29 83 63 98 40 18 41 18 80 2z m-476 -1470 c-1 -4 -60 -39
            -131 -76 l-129 -69 -54 59 c-97 104 -197 144 -342 137 -71 -3 -99 -10 -156
            -36 l-69 -33 -155 156 -156 155 230 231 230 230 367 -373 c202 -205 366 -377
            365 -381z"/>
          <path d="M2427 2331 c-659 -356 -1203 -654 -1208 -662 -26 -41 12 -119 58
            -119 10 0 95 41 188 91 1641 882 2233 1204 2248 1222 37 42 -1 118 -57 116
            -21 -1 -425 -214 -1229 -648z"/>
          <path d="M3563 2435 c-14 -10 -31 -43 -45 -88 -12 -39 -23 -74 -25 -76 -1 -2
            -33 6 -72 18 -88 27 -97 26 -126 -4 -54 -53 -20 -113 80 -140 36 -9 67 -20 69
            -24 2 -3 -5 -33 -15 -66 -10 -33 -19 -72 -19 -86 0 -32 38 -69 71 -69 44 0 67
            26 90 103 11 40 22 73 23 75 2 1 29 -7 61 -18 72 -25 107 -25 133 -2 60 50 27
            111 -79 143 -38 11 -69 24 -69 27 0 4 9 36 20 72 24 79 19 115 -20 135 -36 19
            -50 19 -77 0z"/>
          <path d="M3075 2170 c-31 -12 -41 -29 -60 -95 -10 -33 -21 -64 -25 -68 -4 -5
            -41 2 -83 14 -72 21 -78 21 -101 5 -30 -19 -42 -59 -30 -95 7 -21 24 -31 89
            -53 44 -15 81 -28 81 -28 1 0 -7 -27 -17 -61 -10 -33 -19 -74 -19 -90 0 -57
            67 -89 116 -55 13 9 29 44 43 89 l22 75 62 -19 c79 -24 112 -24 137 1 25 25
            26 81 2 103 -10 9 -48 26 -85 37 -37 11 -67 26 -67 33 0 7 9 40 20 75 22 69
            17 99 -22 126 -25 18 -33 19 -63 6z"/>
          <path d="M2575 1900 c-30 -11 -40 -27 -64 -102 -13 -38 -26 -68 -31 -68 -4 0
            -37 9 -73 20 -66 20 -88 19 -119 -8 -20 -17 -24 -63 -8 -92 6 -10 39 -28 77
            -40 38 -13 72 -26 76 -30 5 -4 0 -40 -12 -80 -20 -73 -19 -92 7 -122 20 -24
            77 -23 101 1 10 10 28 50 40 89 l21 70 61 -19 c33 -10 74 -19 90 -19 57 0 89
            67 55 116 -9 13 -44 29 -89 43 l-75 22 19 62 c10 34 19 76 19 93 0 48 -51 82
            -95 64z"/>
          <path d="M3022 1246 c-657 -353 -1202 -650 -1213 -659 -47 -41 -3 -136 58
            -124 15 3 539 281 1163 617 1353 729 1257 676 1271 701 22 41 -18 109 -63 109
            -13 0 -560 -290 -1216 -644z"/>
          <path d="M2080 1633 c-33 -12 -51 -39 -70 -105 -12 -37 -26 -68 -33 -68 -7 0
            -40 9 -75 20 -70 22 -101 16 -127 -23 -18 -28 -12 -67 13 -90 10 -9 44 -23 77
            -32 33 -10 64 -21 68 -25 4 -4 -2 -41 -14 -83 l-23 -77 20 -27 c28 -37 77 -43
            109 -13 13 13 31 49 41 86 19 71 24 74 94 49 23 -8 57 -15 75 -15 62 0 98 77
            56 119 -10 10 -50 28 -90 40 -39 12 -71 23 -71 24 0 1 9 31 20 65 11 35 20 74
            20 87 0 42 -52 82 -90 68z"/>
        </g>
      </svg>
    </SvgIcon>
  );
};

export function FootballIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M1740 5114 c-208 -26 -378 -66 -526 -124 -282 -112 -554 -303 -741
            -520 -100 -117 -203 -267 -203 -297 0 -32 43 -73 76 -73 18 0 38 12 61 36 19
            20 35 35 37 33 2 -2 41 -118 86 -258 45 -140 84 -260 87 -266 4 -12 -454 -351
            -462 -343 -8 7 16 190 36 283 10 50 36 141 56 202 l37 113 -21 27 c-26 36 -83
            43 -111 15 -24 -24 -85 -210 -111 -337 -88 -438 -33 -851 166 -1245 188 -373
            470 -656 844 -843 696 -351 1499 -250 2107 265 172 145 333 355 447 583 111
            222 170 422 194 664 52 505 -109 1021 -442 1417 -172 205 -249 248 -293 163
            -13 -25 -13 -33 0 -63 8 -19 23 -39 34 -45 10 -5 51 -46 90 -89 l71 -79 -106
            -329 c-58 -181 -109 -333 -113 -337 -14 -15 -435 -165 -445 -159 -6 4 -147
            106 -313 227 l-302 219 0 241 0 241 286 207 286 207 111 -55 c62 -30 134 -66
            160 -81 47 -26 50 -26 83 -11 57 28 58 88 1 131 -39 29 -227 124 -317 159
            -198 78 -407 117 -645 122 -93 2 -186 1 -205 -1z m459 -169 c51 -9 111 -22
            133 -29 l40 -11 -227 -165 c-125 -91 -231 -166 -236 -168 -6 -3 -432 300 -462
            328 -11 10 103 37 233 55 122 18 392 13 519 -10z m-651 -305 l282 -204 0 -241
            0 -241 -302 -219 c-166 -121 -306 -223 -312 -226 -11 -7 -433 143 -446 158 -4
            4 -55 156 -113 337 l-106 328 62 70 c133 148 327 293 522 389 66 32 122 58
            126 56 3 -1 132 -94 287 -207z m1876 -549 c87 -147 166 -357 200 -526 18 -89
            38 -257 32 -263 -9 -9 -467 332 -462 344 3 7 42 127 87 268 46 141 86 256 90
            256 4 0 28 -35 53 -79z m-1236 -472 c152 -111 285 -209 295 -219 18 -17 15
            -29 -94 -364 l-113 -346 -371 0 -371 0 -113 346 c-82 254 -109 350 -101 358
            17 18 574 424 583 425 4 1 132 -90 285 -200z m-1253 -178 c119 -43 219 -81
            221 -84 3 -3 58 -169 123 -369 l118 -363 -135 -177 -135 -178 -351 0 -351 0
            -47 83 c-115 202 -190 435 -217 672 -8 69 -7 82 8 92 28 22 546 401 548 402 0
            1 99 -35 218 -78z m2433 -122 c152 -111 280 -204 283 -208 4 -3 3 -42 -2 -86
            -26 -236 -102 -470 -217 -672 l-47 -83 -351 0 -351 0 -135 178 -135 177 118
            363 c65 200 120 366 122 368 3 4 422 161 435 163 1 1 127 -90 280 -200z m-952
            -945 l126 -167 -27 -94 -28 -94 20 -27 c22 -30 69 -40 102 -23 12 7 28 38 42
            81 l23 70 299 0 298 0 -19 -27 c-36 -50 -185 -194 -269 -260 -45 -35 -126 -91
            -179 -123 -100 -61 -290 -148 -300 -137 -3 3 3 34 15 68 28 84 27 113 -5 138
            -33 26 -65 27 -92 4 -12 -11 -38 -71 -62 -145 -28 -85 -47 -129 -58 -132 -52
            -16 -213 -37 -322 -43 -135 -6 -304 7 -434 35 l-80 17 -108 330 c-91 277 -106
            333 -97 350 7 11 68 93 136 183 l124 162 384 0 384 0 127 -166z m-1215 -529
            c48 -148 87 -270 86 -271 -7 -6 -194 83 -271 130 -162 96 -384 284 -457 388
            l-21 28 288 -2 287 -3 88 -270z"/>
          <path d="M3975 3715 c-54 -53 -62 -43 413 -517 474 -475 464 -467 517 -413 54
            53 62 43 -413 517 -474 475 -464 467 -517 413z"/>
          <path d="M4061 2584 c-44 -56 -46 -53 323 -423 329 -329 346 -345 382 -345 26
            0 42 7 55 23 42 52 42 52 -328 423 -312 312 -351 348 -380 348 -22 0 -38 -8
            -52 -26z"/>
          <path d="M3463 1688 c-26 -12 -47 -59 -39 -85 3 -10 363 -375 799 -810 859
            -859 818 -823 872 -768 55 54 92 13 -773 877 -541 542 -804 798 -817 797 -11
            0 -30 -5 -42 -11z"/>
          <path d="M4356 1509 c-19 -15 -26 -29 -26 -57 0 -33 14 -50 203 -240 194 -195
            237 -232 272 -232 28 0 65 46 65 81 0 28 -24 56 -202 236 -242 244 -258 255
            -312 212z"/>
          <path d="M1341 1234 c-44 -56 -46 -53 327 -427 191 -192 358 -353 371 -358 50
            -20 113 37 97 88 -3 10 -165 177 -359 371 -314 313 -356 352 -384 352 -22 0
            -38 -8 -52 -26z"/>
          <path d="M2375 1225 c-53 -52 -55 -50 323 -428 377 -377 375 -375 427 -322 53
            52 54 50 -323 427 -377 377 -375 376 -427 323z"/>
          <path d="M3371 964 c-43 -54 -37 -62 197 -296 202 -203 220 -218 253 -218 63
            0 94 59 60 113 -11 17 -109 121 -219 230 -184 182 -202 197 -235 197 -27 0
            -41 -7 -56 -26z"/>
        </g>
      </svg>
    </SvgIcon>
  );
};

export function FishIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M2225 5110 c-80 -13 -110 -26 -145 -66 -63 -73 -58 -149 17 -252 55
            -76 107 -161 102 -166 -2 -2 -45 -20 -95 -39 -517 -199 -1011 -634 -1323
            -1167 l-56 -95 -168 -110 c-551 -362 -531 -348 -546 -387 -27 -75 17 -161 91
            -177 22 -5 149 -1 320 10 288 20 329 19 383 -8 16 -8 134 -120 260 -249 l230
            -235 52 1 c40 0 58 6 82 26 59 49 59 69 -4 364 -31 146 -66 312 -77 368 l-22
            104 25 19 c64 50 110 64 209 63 52 0 108 -5 123 -12 l28 -12 -30 -42 c-16 -24
            -48 -61 -71 -83 -72 -69 -87 -122 -51 -181 24 -39 70 -61 169 -80 177 -35 376
            9 529 119 l42 30 93 -17 c205 -36 415 -45 592 -27 65 7 83 13 98 31 24 29 23
            71 -2 98 -20 21 -25 22 -198 16 -386 -14 -671 49 -1022 226 -142 72 -202 90
            -299 90 -201 0 -380 -116 -381 -246 0 -17 29 -169 65 -337 36 -169 65 -310 65
            -313 0 -4 -89 83 -197 194 -119 121 -214 208 -238 220 -83 41 -134 44 -399 27
            -136 -9 -253 -15 -260 -13 -8 2 119 89 283 195 163 105 308 204 323 220 15 15
            43 55 62 87 36 60 104 123 258 238 219 165 439 292 730 421 44 20 59 32 64 53
            10 38 -7 77 -38 92 -24 11 -36 10 -95 -14 -131 -52 -383 -183 -518 -269 -28
            -18 -28 -17 9 25 65 74 214 207 336 299 299 225 574 344 945 408 144 24 501
            24 645 -1 101 -17 278 -60 287 -70 3 -3 -11 -42 -30 -88 -19 -46 -40 -100 -47
            -121 -8 -26 -17 -37 -26 -33 -36 14 -238 47 -347 57 -144 14 -395 6 -550 -16
            -124 -17 -289 -51 -330 -67 -51 -20 -63 -79 -24 -116 28 -27 57 -27 167 -3
            193 43 309 55 535 55 215 0 303 -9 474 -46 l53 -12 -6 -50 c-41 -319 12 -619
            155 -885 22 -40 38 -76 36 -78 -3 -2 -56 -17 -118 -34 -122 -31 -149 -49 -149
            -98 0 -33 37 -71 70 -71 68 0 584 156 798 241 254 102 374 174 393 238 16 54
            6 83 -46 133 l-44 43 59 69 c68 79 90 134 90 220 0 113 -65 205 -260 368 -251
            211 -622 404 -900 468 -48 11 -66 23 -136 90 -176 168 -405 288 -652 340 -111
            24 -352 34 -447 20z m405 -165 c154 -32 308 -95 435 -178 l70 -46 -166 6
            c-181 7 -325 -5 -503 -39 -106 -21 -110 -21 -120 -3 -6 10 -46 70 -88 134 -51
            76 -74 119 -67 126 28 28 308 28 439 0z m1201 -583 c164 -79 342 -202 505
            -349 108 -98 134 -134 134 -192 0 -53 -8 -68 -86 -159 -39 -46 -66 -87 -70
            -108 -9 -47 12 -99 52 -132 l34 -28 -117 -57 c-145 -69 -227 -102 -411 -164
            l-143 -48 -19 25 c-60 77 -142 282 -176 441 -28 131 -26 372 4 509 22 99 71
            240 106 309 l16 30 44 -18 c24 -11 81 -37 127 -59z m-1906 -1385 c44 -19 105
            -42 135 -52 l54 -18 -51 -22 c-73 -33 -177 -49 -256 -41 -37 4 -69 12 -72 16
            -3 5 9 26 28 47 18 21 43 53 56 71 12 17 23 32 24 32 2 0 38 -15 82 -33z"/>
            <path d="M3898 3950 c-24 -25 -29 -36 -24 -58 15 -60 84 -82 124 -39 31 33 29
            80 -4 106 -36 29 -60 26 -96 -9z"/>
            <path d="M2902 3879 c-194 -32 -314 -229 -258 -422 29 -99 121 -192 219 -218
            102 -27 224 -4 292 55 44 37 51 81 20 116 -24 27 -64 25 -121 -5 -96 -51 -205
            -23 -251 64 -43 82 -22 182 49 233 30 21 44 23 140 23 95 0 109 2 122 20 35
            46 15 97 -46 118 -60 22 -105 26 -166 16z"/>
            <path d="M3691 2534 c-60 -50 -60 -64 14 -409 82 -386 87 -414 82 -429 -3 -7
            -33 -28 -68 -47 -60 -32 -70 -34 -159 -34 l-95 0 -175 87 c-177 88 -323 144
            -437 168 -35 7 -63 17 -63 22 0 9 48 103 100 197 48 85 37 168 -29 224 -48 40
            -100 50 -231 44 -385 -18 -728 -185 -985 -480 l-50 -57 -170 -50 c-316 -91
            -500 -161 -742 -282 -186 -92 -239 -133 -282 -219 -68 -137 -45 -285 62 -397
            23 -24 55 -50 71 -59 l28 -15 -26 -27 c-53 -55 -52 -138 2 -188 79 -73 481
            -276 789 -397 341 -135 566 -180 898 -180 339 0 625 57 887 176 l68 31 57 -26
            c110 -49 171 -62 288 -61 123 0 204 20 305 76 74 41 98 65 110 109 16 60 -9
            110 -84 164 -33 24 -74 57 -91 74 l-29 32 107 102 c98 93 107 105 107 138 0
            55 -56 93 -102 68 -13 -7 -68 -55 -123 -108 -129 -124 -213 -191 -355 -286
            -429 -287 -969 -402 -1485 -315 -119 20 -262 53 -272 64 -3 3 7 34 22 68 133
            305 160 668 71 967 l-15 51 37 15 c66 28 211 74 310 99 232 58 384 77 692 83
            283 6 513 -10 670 -45 45 -10 114 -17 175 -16 119 1 182 22 273 88 62 46 92
            97 92 155 0 16 -29 167 -65 336 -36 168 -65 309 -65 313 0 4 87 -82 193 -190
            268 -274 272 -276 632 -251 320 21 313 29 95 -111 -432 -279 -432 -279 -470
            -341 -19 -32 -79 -124 -132 -205 -54 -81 -98 -157 -98 -168 0 -10 10 -31 21
            -46 17 -22 28 -27 58 -24 31 3 42 11 86 70 28 36 79 115 115 175 36 59 81 123
            101 143 20 19 161 116 315 214 360 232 370 239 384 282 23 70 -16 151 -83 173
            -23 8 -111 6 -328 -8 -303 -20 -345 -18 -397 14 -15 10 -128 121 -252 247
            l-225 229 -52 -1 c-40 0 -58 -6 -82 -26z m-925 -339 c4 -8 -12 -45 -34 -83
            -22 -37 -53 -97 -69 -133 l-30 -66 -74 10 c-115 14 -455 2 -584 -22 -60 -11
            -111 -18 -113 -16 -3 2 31 27 74 55 104 68 296 161 414 200 178 60 403 89 416
            55z m-66 -444 c58 -12 139 -32 180 -45 l75 -24 -170 -1 c-374 -2 -670 -48
            -988 -153 -86 -28 -159 -49 -161 -47 -17 21 -84 165 -78 170 11 11 296 76 427
            98 168 28 185 29 410 26 153 -3 225 -8 305 -24z m-1258 -221 c54 -92 112 -247
            139 -370 19 -86 24 -135 24 -265 0 -215 -29 -347 -118 -547 l-25 -57 -129 53
            c-276 115 -416 182 -572 274 -82 48 -93 57 -77 66 48 28 67 53 73 92 8 62 -14
            93 -97 139 -84 46 -125 89 -141 147 -22 82 9 164 80 211 118 78 467 229 711
            308 81 26 89 23 132 -51z m2214 -1081 c16 -17 50 -49 76 -70 l48 -38 -53 -24
            c-101 -48 -230 -58 -340 -27 l-39 11 97 65 c54 35 111 76 129 89 40 32 46 31
            82 -6z"/>
            <path d="M1120 1180 c-45 -45 -11 -120 53 -120 42 0 70 29 70 70 0 63 -78 95
            -123 50z"/>
            <path d="M2045 1031 c-85 -22 -119 -62 -99 -118 13 -39 50 -49 118 -33 45 10
            64 11 96 1 86 -26 150 -119 136 -198 -16 -87 -92 -153 -177 -153 -23 0 -67 12
            -101 27 -70 31 -104 25 -127 -21 -51 -106 180 -197 344 -135 81 30 164 113
            191 189 57 166 -14 339 -170 415 -56 27 -158 40 -211 26z"/>
        </g>
      </svg>
    </SvgIcon>
  );
};

// export function FlatIcon(props) {
//   return (
//     <SvgIcon {...props}>
//       <svg
//         version="1.0"
//         xmlns="http://www.w3.org/2000/svg"
//         width="24px"
//         height="24px"
//         viewBox="0 0 512 512"
//         preserveAspectRatio="xMidYMid meet">
//           <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
//           </g>
//       </svg>
//     </SvgIcon>
//   );
// };
