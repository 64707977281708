import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import feathers from 'services/feathers';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOff from '@mui/icons-material/VisibilityOffTwoTone';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';

function BoolChip(props) {
  const { isTrue } = props;
  const cleanProp = { ...props };

  delete cleanProp?.isTrue;

  if (isTrue) return <Chip { ...cleanProp } variant='contained' color='success' />;
  return <Chip {...cleanProp} variant='outlined' />
}

BoolChip.propTypes = {
  isTrue: PropTypes.bool.isRequired,
};

//eslint-disable-next-line
const strongPasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>]{8,}$/;

export default function SetPasswordDialog(props) {
  const { user, update: updateUser  } = useAuth();
  const userId = get(user, '_id');
  const hasPassword = get(user, 'hasPassword', false);
  const [ showCurrentPassword, setShowCurrentPassword ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const {  open, onClose } = props;
  const { t } = useTranslation();

  const passwordSchema = Yup.object().shape({
    ...(
      hasPassword ?
      {
        currentPassword: Yup.string().required(t("Required"))
      } : {
        currentPassword: Yup.string().nullable(),
      }
    ),
    //eslint-disable-next-line
    password: Yup.string().required(t("Required")).matches(strongPasswordRegex, t('Invalid password format'))
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      currentPassword: '',
      password: '',
    },
    validationSchema: passwordSchema,
    onSubmit: async (values, { resetForm }) => {
      const formattedValues = { ...values };

      if (!formattedValues?.currentPassword) delete formattedValues?.currentPassword;

      try {
        const savedUser = await feathers.service('users').patch(userId, formattedValues);
        updateUser(savedUser);
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
        resetForm();
        onClose();
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    },
  });

  const passwordCheck = useMemo(
    () => {
      const password = get(formik, 'values.password', '');

      const digit = /\d/.test(password);
      const lowerCase = /[a-z]/.test(password);
      const upperCase = /[A-Z]/.test(password);
      //eslint-disable-next-line
      const symbol = /[!@#$%^&*()\-_=+{};:,<.>]/.test(password);
      const length = /.{8}/.test(password);

      return {
        digit,
        lowerCase,
        upperCase,
        symbol,
        length
      }
    }, [formik]
  );

  const handleClickShowCurrentPassword = (event) => {
    event.preventDefault();
    setShowCurrentPassword(prev => !prev);
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(prev => !prev);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formik.isSubmitting) return;
    formik.submitForm();
  };

  return (
    <Dialog maxWidth='xs' onClose={onClose} open={open}>
      <DialogTitle>
        {
          hasPassword ?
          t('Update Password') :
          t('Set Password')
        }
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {
              !hasPassword &&
              <Alert severity='info'>{t('Password optional')}</Alert>
            }
          </Grid>
          {
            hasPassword &&
            <Grid item xs={12}>
              <TextField
                sx={{ my: 1 }}
                fullWidth
                autoComplete='off'
                type={showCurrentPassword ? 'text' : 'password'}
                id='currentPassword'
                name='currentPassword'
                label={t('Current Password')}
                value={get(formik, 'values.currentPassword', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, 'touched.currentPassword', false) && Boolean(get(formik, 'errors.currentPassword'))}
                helperText={get(formik, 'touched.currentPassword', false) && get(formik, 'errors.currentPassword')}
                InputProps={{
                  endAdornment:
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleClickShowCurrentPassword}
                        edge='end'
                      >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Grid>
          }
          <Grid item xs={12}>
            <TextField
              sx={{ my: 1 }}
              fullWidth
              autoComplete='off'
              type={showPassword ? 'text' : 'password'}
              id='password'
              name='password'
              label={t('New Password')}
              value={get(formik, 'values.password', '')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={get(formik, 'touched.password', false) && Boolean(get(formik, 'errors.password'))}
              helperText={get(formik, 'touched.password', false) && get(formik, 'errors.password')}
              InputProps={{
                endAdornment:
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 1 }}>
              <Typography variant='subtitle2' color='text.secondary' gutterBottom>{t('Password requirements')}</Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <BoolChip size='small' label={t('1 digit')} isTrue={passwordCheck.digit} />
                </Grid>
                <Grid item>
                  <BoolChip size='small' label={t('1 lower case')} isTrue={passwordCheck.lowerCase} />
                </Grid>
                <Grid item>
                  <BoolChip size='small' label={t('1 upper case')} isTrue={passwordCheck.upperCase} />
                </Grid>
                <Grid item>
                  <BoolChip size='small' label={t('1 symbol')} isTrue={passwordCheck.symbol} />
                </Grid>
                <Grid item>
                  <BoolChip size='small' label={t('8 characters')} isTrue={passwordCheck.length} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' variant='contained' onClick={onClose} autoFocus>
          {t('Close')}
        </Button>
        <Button variant='contained' onClick={handleSubmit}>
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SetPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
