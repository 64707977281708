import React, { useMemo, useState, useLayoutEffect, useRef } from 'react';
import { useAuth } from 'hooks/useAuth';
import { get } from 'lodash';
import ProtectedImage from 'features/img/ProtectedImage';
import GenericImage from 'features/img/GenericImage';
import {
  Box,
  Card,
  CardMedia,
  Typography,
} from '@mui/material';
//import { Node } from 'slate';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';

// const serializeText = nodes => {
//   return nodes.map(n => Node.string(n)).join('\n')
// };

const serializeImage = nodes => {
  const images = [];

  const findImage = (nodes) => {
    nodes.forEach(n => {
      if (n.type === 'image') {
        images.push(n);
      } else if (n.children) {
        findImage(n.children);
      }
    });
  }

  findImage(nodes);

  return images;
};

export default function NewsPreviewCard(props) {
  const { onClick, news } = props;
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ cardMaxWidth, setCardMaxWidth ] = useState(0);
  const cardRef = useRef(null);

  const newsId = useMemo(
    () => {
      return get(news, '_id');
    }, [news]
  );

  const title = get(news, `title.${lang}`);
  const contentRaw = get(news, `content.${lang}`, '[]');
  const pinnedAt = get(news, 'pinnedAt');

  const contentNodes = JSON.parse(decodeURIComponent(contentRaw));
  const images = serializeImage(contentNodes);
  const [ showImage, setShowImage ] = useState(false);

  const imageId = useMemo(
    () => {
      return get(images, '[0].id');
    }, [images]
  );

  const imageUrl = useMemo(
    () => {
      return get(images, '[0].url', 'https://placehold.co/500');
    }, [images]
  );

  useLayoutEffect(() => {
    if (!cardRef.current) return;
    setCardMaxWidth(cardRef.current.clientWidth);
  }, [cardRef]);

  const handleClicked = (event) => {
    event?.preventDefault();
    if (!newsId) return;
    onClick(newsId);
  };

  const handleOnLoaded = (event) => {
    setShowImage(true);
  }

  return (
    <Box>
      <Card elevation={4} sx={{ borderRadius: 0 }} ref={cardRef}>
        <CardMedia onClick={handleClicked} sx={{ cursor: 'pointer', position: 'relative' }}>
          {
            !!imageId ?
            <ProtectedImage
              sx={{
                width: '100%',
                display: showImage ? 'block' : 'none'
              }}
              assetId={imageId}
              name={imageId}
              onLoad={handleOnLoaded}
              thumbnail
            /> :
            <GenericImage
              sx={{
                width: '100%',
                display: showImage ? 'block' : 'none'
              }}
              src={imageUrl}
              alt={imageUrl}
              onLoad={handleOnLoaded}
            />
          }
        </CardMedia>
      </Card>
      <Box sx={{
        bgcolor: theme => pinnedAt ? theme.palette.primary.main : 'rgb(0,0,0)',
        color: theme => theme.palette.getContrastText(pinnedAt ? theme.palette.primary.main : 'rgb(0,0,0)'),
        maxWidth: cardMaxWidth,
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
        }}>
          <Typography variant='caption'
            sx={{
              fontWeight: pinnedAt ? 700 : 400,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              overflowWrap: 'anywhere',
            }}
          >
            {
              title
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
