import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { get } from 'lodash';
import ProtectedImage from 'features/img/ProtectedImage';
import GenericImage from 'features/img/GenericImage';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { createEditor } from 'slate';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Slate,
  Editable,
  withReact,
} from 'slate-react';
import {
  Box,
  //Fade,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  FullscreenTwoTone as FullscreenIcon,
  FullscreenExitTwoTone as FullscreenExitIcon,
} from '@mui/icons-material';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Fade ref={ref} {...props} />;
// });

export default function NewsDialog(props) {
  const { open, news, handleOnClose } = props;
  const lang = get(props, 'lang', 'en');
  const [ slateContent, setSlateContent ] = useState([]);
  const renderElement = useCallback(props => <Element { ...props } />, []);
  const renderLeaf = useCallback(props => <Leaf { ...props } />, []);
  const editorRef = useRef();
  const { t } = useTranslation();
  const [ isFullscreen, setIsFullscreen ] = useState(false);

  if (!editorRef.current) editorRef.current = withReact(createEditor());
  const editor = editorRef.current;

  useEffect(() => {
    if (!news) {
      setSlateContent([]);
      return;
    }
    const contentStr = get(news, `content.${lang}`, '');
    if (!contentStr.length) return;
    const content = JSON.parse(decodeURIComponent(contentStr));
    setSlateContent(content);
  }, [news, lang]);

  const newsTitle = useMemo(
    () => {
      const title = get(news, `title.${lang}`, '');
      return title;
    }, [news, lang]
  );

  const newsDate = useMemo(
    () => {
      const dateRaw = get(news, `publishedAt`);
      if (!dateRaw || !dayjs(dateRaw).isValid()) return null;
      const djsDate = dayjs(dateRaw);
      return djsDate.locale(lang).fromNow();
    }, [news, lang]
  );

  const Element = (props) => {
    const { attributes, children, element } = props;
    const style = { textAlign: element.align };
    const { key } = element;

    switch (element.type) {
      case 'block-quote':
        return (
          <blockquote style={style} {...attributes}>
            {children}
          </blockquote>
        );
      case 'bulleted-list':
        return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
        );
      case 'heading-one':
        return (
          <h1 style={style} {...attributes}>
            {children}
          </h1>
        );
      case 'heading-two':
        return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
        );
      case 'heading-three':
        return (
          <h3 style={style} {...attributes}>
            {children}
          </h3>
        );
      case 'heading-four':
        return (
          <h4 style={style} {...attributes}>
            {children}
          </h4>
        );
      case 'heading-five':
        return (
          <h5 style={style} {...attributes}>
            {children}
          </h5>
        );
      case 'heading-six':
        return (
          <h6 style={style} {...attributes}>
            {children}
          </h6>
        );
      case 'list-item':
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
      case 'numbered-list':
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        );
      case 'image':
        return (<Image {...props} />);
      case 'link':
        return (<Link {...props} />);
      case 'youtube':
        return (<YouTube {...props} />);

        // Table support
      case 'table':
        return (
          <TableContainer component={Paper} sx={{
            maxWidth: {
              xs: '100%',
              md: '50%',
            }
          }}>
            <Table>
              {children}
            </Table>
          </TableContainer>
        );
      case 'table-head':
        return (
          <TableHead style={style} {...attributes} key={key}>
            {children}
          </TableHead>
        );
      case 'table-body':
        return (
          <TableBody style={style} {...attributes} key={key}>
            {children}
          </TableBody>
        );
      case 'table-row':
        return (
          <TableRow style={style} {...attributes} key={key}>
            {children}
          </TableRow>
        );
      case 'table-cell':
        return (
          <TableCell style={style} {...attributes} key={key}>
            {children}
          </TableCell>
        );

      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        );
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }

    if (leaf.code) {
      children = <code>{children}</code>
    }

    if (leaf.italic) {
      children = <em>{children}</em>
    }

    if (leaf.underline) {
      children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
  };

  const Image = ({ attributes, children, element }) => {
    const id = get(element, 'id');

    return (
      <Box {...attributes}
        sx={{
          width: {
            xs: '100%',
            md: '50%',
          }
        }}
      >
        {children}
        <Box
          contentEditable={false}
          sx={{
            position: 'relative'
          }}
        >
          {
            id ?
            <ProtectedImage
              sx={{
                display: 'block',
                width: '100%',
              }}
              assetId={id}
              name={id}
            /> :
            <GenericImage
              sx={{
                display: 'block',
                width: '100%',
              }}
              alt={element.url}
              src={element.url}
            />
          }
        </Box>
      </Box>
    )
  }

  const Link = ({ attributes, children, element }) => {
    const href = get(element, 'href');
    if (!href) return null;

    return (
      <Box sx={{
          textDecoration: 'none',
          fontWeight: 700,
          color: 'text.primary',
          borderBottom: `3px solid`,
          borderColor: 'primary.main'
        }}
        component='a'
        href={href}
        target='blank'
        {...attributes}>
          {children}
      </Box>
    );
  };

  const YouTube = ({ attributes, children, element }) => {
    const url = element.url;
    const videoId = url?.split('v=')[1];
    if (!videoId) return null;
    const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0&amp;autoplay=0&mute=0`;
    return (
      <Box {...attributes}
        sx={{
          width: {
            xs: '100%',
            sm: '50%',
          }
        }}
      >
        <Box
          contentEditable={false}
          sx={{
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom: '56.25%' // full hd 16:9 (9/16)
          }}
        >
          <Box
            component='iframe'
            title={embedUrl}
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        {/*{
          children
        }*/}
      </Box>
    );
  };

  return (
    <Dialog
      fullScreen={isFullscreen}
      fullWidth={true}
      maxWidth='md'
      open={open}
      onClose={handleOnClose}
      // TransitionComponent={Transition}
    >
      <DialogTitle>
        {
          newsTitle
        }
      </DialogTitle>
      <IconButton
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          m: 1
        }}
        onClick={() => setIsFullscreen(!isFullscreen)}
      >
        {
          isFullscreen ?
          <FullscreenExitIcon /> :
          <FullscreenIcon />
        }
      </IconButton>
      <DialogContent dividers sx={{ bgcolor: 'background.paper' }}>
        {
          <Slate editor={editor} initialValue={slateContent}>
            <Editable
              readOnly
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder='...'
            />
          </Slate>
        }
        <Box sx={{ mt: 2 }}>
          <Typography variant='caption' sx={{ display: 'block', textAlign: 'right', color: 'text.secondary', fontStyle: 'italic' }}>
            {
              t('Published relative', { text: newsDate})
            }
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' autoFocus onClick={handleOnClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
