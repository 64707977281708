import React, { useMemo, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { get } from 'lodash';
import Decimal from 'decimal.js';
import { blue, amber, deepPurple } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ReferralChart(props) {
  const { t } = useTranslation();
  const { data } = props;
  const theme = useTheme();
  const [ unit, setUnit ] = useState('month');
  const { timezone: companyTimezone = 'UTC' } = data || {};

  const isDarkPalette = useMemo(
    () => {
      return get(theme, 'palette.mode', 'light') === 'dark' ? true : false;
    }, [theme]
  );

  const graphColorTone = useMemo(
    () => {
      return isDarkPalette ? 300 : 600;
    }, [isDarkPalette]
  );

  const recentReferralReward = useMemo(
    () => {
      return get(data, 'recentReferralReward');
    }, [data]
  );

  const recentReferee = useMemo(
    () => {
      return get(data, 'recentReferee');
    }, [data]
  );

  const dateFormat = useMemo(
    () => {
      switch (unit) {
        case 'day':
          return 'MM-DD';

        case 'week':
          return 'w';

        case 'month':
          return 'MMM';

        case 'year':
          return 'YYYY';

        default:
          return 'MM-DD';
      }
    }, [unit]
  );

  const startDate = useMemo(
    () => {
      switch (unit) {
        case 'day':
          return dayjs().subtract(14, 'd').startOf('d');

        case 'week':
          return dayjs().subtract(6, 'w').startOf('w');

        case 'month':
          return dayjs().subtract(6, 'M').startOf('M');

        case 'year':
          return dayjs().subtract(6, 'y').startOf('y');

        default:
          return dayjs().subtract(6, 'y').startOf('y');
      }
    }, [unit]
  );

  const defaultChartData = useMemo(
    () => {
      return [{
        date: dayjs().startOf(unit).format(dateFormat),
        cashCount: 0,
        cashAmount: 0,
        pointCount: 0,
        pointAmount: 0,
        refereeCount: 0,
      }];
    }, [unit, dateFormat]
  );

  const chartData = useMemo(
    () => {
      const cash = get(recentReferralReward, `cash.${unit}`, [])
        .map(({ date, count, amount }) => {
          const adjustedDate = dayjs(date).utc().tz(companyTimezone, true);
          return {
            unixTimestamp: adjustedDate.unix(),
            date: adjustedDate.format(dateFormat),
            cashCount: count,
            cashAmount: new Decimal(get(amount, '$numberDecimal', '0')).toFixed(2)
          }
        });
      const point = get(recentReferralReward, `point.${unit}`, [])
      .map(({ date, count, amount }) => {
          const adjustedDate = dayjs(date).utc().tz(companyTimezone, true);
          return {
            unixTimestamp: adjustedDate.unix(),
            date: adjustedDate.format(dateFormat),
            pointCount: count,
            pointAmount: new Decimal(get(amount, '$numberDecimal', '0')).toFixed(2)
          }
        });
      const referee = get(recentReferee, unit, [])
      .map(({ date, count }) => {
          const adjustedDate = dayjs(date).utc().tz(companyTimezone, true);
          return {
            unixTimestamp: adjustedDate.unix(),
            date: adjustedDate.format(dateFormat),
            refereeCount: count
          }
        });

      const combine = [...cash, ...point, ...referee].reduce(
        (all, current) => {
          const { unixTimestamp } = current;
          const unixTimeStampMs = unixTimestamp * 1000;

          if (dayjs(unixTimeStampMs).isBefore(startDate)) return all;

          const index = all.findIndex((element) => (element?.date === current?.date));

          if (index < 0) {
            return [
              ...all,
              {
                cashCount: 0,
                cashAmount: 0,
                pointCount: 0,
                pointAmount: 0,
                refereeCount: 0,
                ...current,
              }
            ];
          }

          all[index] = {
            ...all[index],
            ...current
          };

          const sorted = all.sort((a, b) => (a.unixTimestamp - b.unixTimestamp));
          return sorted;
        }, []
      );

      return combine;
    }, [recentReferralReward, recentReferee, unit, dateFormat, startDate, companyTimezone]
  );

  const unitButtonVariant = useCallback(
    (u) => {
      return (unit === u) ? 'contained' : 'outlined';
    }, [unit]
  );

  const handleChartUnitChanged = (unit) => (event) => {
    event?.preventDefault();
    setUnit(unit);
  };

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup size='small'>
          <Button onClick={handleChartUnitChanged('day')} variant={unitButtonVariant('day')}>{t('Day')}</Button>
          <Button onClick={handleChartUnitChanged('week')} variant={unitButtonVariant('week')}>{t('Week')}</Button>
          <Button onClick={handleChartUnitChanged('month')} variant={unitButtonVariant('month')}>{t('Month')}</Button>
          <Button onClick={handleChartUnitChanged('year')} variant={unitButtonVariant('year')}>{t('Year')}</Button>
        </ButtonGroup>
      </Box>
      <Box sx={{ width: '100%', height: 200 }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData.length ? chartData : defaultChartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke={theme.palette.divider} strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              contentStyle={{
                borderRadius: '5px',
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary
              }}
            />
            <Legend />
            <Line name={t('Point #')} type="monotone" dataKey="pointCount" stroke={blue[graphColorTone]} activeDot={{ r: 4 }} />
            <Bar name={t('Point')} dataKey="pointAmount" barSize={15} fill={blue[graphColorTone + 100]} />
            <Line name={t('Cash #')} type="monotone" dataKey="cashCount" stroke={amber[graphColorTone]} activeDot={{ r: 8 }} />
            <Bar name={t('Cash')} dataKey="cashAmount" barSize={15} fill={amber[graphColorTone + 100]} />
            <Line name={t('Referrals')} type="monotone" dataKey="refereeCount" stroke={deepPurple[graphColorTone]} activeDot={{ r: 8 }} />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}
