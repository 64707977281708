import React from 'react';
import Button from '@mui/material/Button';
/*import Paper from '@mui/material/Paper';*/
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

export default function ThanksDialog(props) {
  //const [open, setOpen] = useState(false);
  const { open } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleClose() {
    navigate('/wallet', { replace: true });
  }

/*  function handleClicked(event) {
    event.preventDefault();
    navigate('/referral', { replace: true });
  }
*/
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {`❤️ ${t('Thank You')}`}
        </DialogTitle>
        <DialogContent dividers >
          <DialogContentText sx={{ fontStyle: 'oblique' }}>
            {t('Processing request')}
           {/* <Paper elevation={2} square sx={{ mt: 2, p: 2, textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 700 }} variant='body2' gutterBottom>
                {t('Unlimited Free Points')}
              </Typography>
              <Button color='success' variant='contained' onClick={handleClicked}>{t('Just Refer')}</Button>
            </Paper>*/}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} autoFocus>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
