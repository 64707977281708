import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { get } from 'lodash';
import useGameLogo from 'hooks/useGameLogo';

export default function Games(props) {
  const { isEnabled, gameType, gameBalance, onClick } = props;
  const theme = useTheme();
  const { getGameImagePath } = useGameLogo();

  const isDarkPalette = useMemo(
    () => {
      return get(theme, 'palette.mode', 'light') === 'dark' ? true : false;
    }, [theme]
  );

  const bgImage = useMemo(
    () => {
      return isDarkPalette ? 'mandala/dark.png' : 'mandala/light.png';
    }, [isDarkPalette]
  );

  const balanceAnimation = useMemo(
    () => {
      return keyframes`
        from, to { transform: translate(-50%, -50%) scale(1, 1); }
        25% { transform: translate(-50%, -50%) scale(0.9, 1.1); }
        50% { transform: translate(-50%, -50%) scale(1.1, 0.9); }
        75% { transform: translate(-50%, -50%) scale(0.95, 1.05); }
      `;
    }, []
  );

  return (
    <Box
      onClick={onClick}
      sx={{
        ...(
          !isEnabled && {
            filter: 'grayscale(100%)'
          }
        ),
        position: 'relative',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <Box
        component='img'
        src={`/images/bg-games/${bgImage}`}
        sx={{
          borderRadius: 2,
          position: 'relative',
          objectFit: 'contain',
          width: '100%',
          zIndex: 10,
          /*border: `solid ${borderColor}`,
          ...borderStyle*/
        }}
      />
      <Box
        component='img'
        src={getGameImagePath(gameType)}
        alt={`${gameType} logo`}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '5.6rem', md: '8rem' },
          height: { xs: '5.6rem', md: '8rem' },
          zIndex: 20,
          ...(
            isEnabled && !!gameBalance && {
              animation: `${balanceAnimation} 0.5s infinite linear`
            }
          ),
        }}
      />
    </Box>
  );
}
