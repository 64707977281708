import React, { useEffect, useState, useContext, createContext, useMemo } from 'react';
import { getVersion } from 'utils/getVersion';
import packageJson from '../../package.json';
import { useAuth } from 'hooks/useAuth';

const checkAppVersionContext = createContext();

export function ProvideCheckAppVersion({ children }) {
  const checkAppVersion = useProvideCheckAppVersion();
  return <checkAppVersionContext.Provider value={checkAppVersion}>{children}</checkAppVersionContext.Provider>;
}

export const useCheckAppVersion = () => {
  return useContext(checkAppVersionContext);
};

function useProvideCheckAppVersion() {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const { version: currentVersion } = packageJson;
  const { user } = useAuth();

  const companyId = useMemo(() => {
    const { companyId } = user || {};
    return companyId;
  }, [user]);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    const checkVersion = async () => {
      try {
        const version = await getVersion(companyId);

        if (!version) {
          return;
        }

        const isUpdateAvailable = currentVersion < version;
        setUpdateAvailable(isUpdateAvailable);
      } catch (error) {
        console.error(`Error checking app version: ${error}`);
      }
    }

    checkVersion();
  }, [companyId, currentVersion]);

  return {
    updateAvailable,
    version: currentVersion,
  };
}
