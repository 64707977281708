import React, { useContext, useState, useMemo } from 'react';
import Marquee from "react-fast-marquee";
/*import { deepPurple } from '@mui/material/colors';*/
import AnnouncementContext from 'features/context/announcementContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAuth } from 'hooks/useAuth';
import { get, template, includes } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

export default function Announcements(props) {
  const { data, ready } = useContext(AnnouncementContext);
  const [ playMarquee, setPlayMarquee ] = useState(true);
  const { user } = useAuth();
  const { t } = useTranslation();
  const nickname = get(user, 'nickname', t('visitor')) || 'dear';
  const theme = useTheme();

  const needBackground = useMemo(
    () => {
      const themeName = get(theme, 'custom.name');
      return includes(['blue'], themeName);
    }, [theme]
  );

  const handleMarqueeClicked = (event) => {
    event?.preventDefault();
    setPlayMarquee(prev => (!prev));
  };

  if (!ready || !data.length) return null;

  return (
    <Box id='announcement' variant='outlined' sx={{
      borderRadius: '4px',
      bgcolor: needBackground ? 'background.paper' : 'none',
      p: 0.5,
    }} onClick={handleMarqueeClicked}>
      {
        data.map(function (announcement) {
          const { id, isPlay = false, speed = 20, delay = 0, loop = 0 } = announcement;
          let compiled;
          try {
            compiled = template(announcement?.text || '');
          } catch (err) {
            return null;
          }

          const text = compiled({ nickname }).replace(/\s+$/, ' ');

          return (
            <Marquee key={id} gradient={false}
              {
                ...{
                  play: !isPlay ? false : playMarquee,
                  speed,
                  delay,
                  loop
                }
              }
            >
              <Typography sx={{ fontWeight: 700, color: theme => theme.palette.primary.light }} variant='body1'>
                {text}
              </Typography>
            </Marquee>
          )
        })
      }
    </Box>
  );
}