import { useMemo, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';

export default function useGameLogo(forceDarkPalette = false) {
  const theme = useTheme();

  const isDarkPalette = useMemo(
    () => {
      if (forceDarkPalette) {
        return true;
      }

      const { palette } = theme || {};
      const { mode } = palette || {};
      return mode === 'dark' ? true : false;
    }, [theme, forceDarkPalette]
  );

  const getGameImagePath = useCallback(
    (gameType, options = {}) => {
      const { forceDarkPalette = false } = options;
      let sanitizedGameType = gameType;

      // remove -api from gameType
      if (gameType.endsWith('-api')) {
        sanitizedGameType = gameType.substring(0, gameType.length - 4);
      } else if (gameType.endsWith('-sapi')) {
        sanitizedGameType = gameType.substring(0, gameType.length - 5);
      }

      switch (sanitizedGameType) {
        case 'amb-pgsoft':
          sanitizedGameType = 'pgsoft'
          break;

        case 'awc-sexy-baccarat':
          sanitizedGameType = 'sexy-baccarat'
          break;

        default:
          break;
      }

      const gameTypesWithDualVersion = [
        'cq9-gaming',
        'imperium-games',
        'naga-games',
        'pgsoft',
        'playtech',
        'pragmatic-play',
        'sa-gaming',
        'spribe',
      ];

      if (gameTypesWithDualVersion.includes(sanitizedGameType)) {
        const toUseDarkPalette = forceDarkPalette || isDarkPalette;
        const gameImagePath = `/images/games/${sanitizedGameType}-${toUseDarkPalette ? 'black' : 'white'}.webp`;
        return gameImagePath;
      }

      const gameImagePath = `/images/games/${sanitizedGameType}.webp`;
      return gameImagePath;
    }, [isDarkPalette]
  );

  return {
    getGameImagePath
  };
}