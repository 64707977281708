import React, { useState, useMemo, useRef, useEffect, useContext } from 'react';
import { _POINT_SYMBOL } from 'utils/symbol';
import PropTypes from 'prop-types';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ImageAvatar from 'features/avatar/ImageAvatar';
import LuckyDrawProgress from './LuckyDrawProgress.js';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { keyframes } from '@mui/system';
import CountUp from 'react-countup';
import { get, find } from 'lodash';
import CommonContext from 'features/context/commonContext';
import { useAuth } from 'hooks/useAuth';
import Decimal from 'decimal.js';
import {
  amber,
  green,
} from '@mui/material/colors';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';

export default function LuckyDrawCard(props) {
  const { luckyDraw, onWinnersPreview, onParticipantsPreview } = props;
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { t } = useTranslation();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ status, setStatus ] = useState('idle');
  const requirementRef = useRef(null);
  const [ notEligibleLastAnimated, setNotEligibleLastAnimated ] = useState(null);
  const [ cuParticipant, setCuParticipant ] = useState({ start: 0, end: 0 });
  const [ cuGiveaway, setCuGiveaway ] = useState({ start: 0, end: 0 });
  const [ cuRecent, setCuRecent ] = useState({ start: 0, end: 0 });
  const [ cuPrizeCount, setCuPrizeCount ] = useState({ start: 0, end: 0 });
  const { tiers } = useContext(CommonContext);

  const eventType = useMemo(
    () => {
      const { isVIPReward } = luckyDraw;
      return isVIPReward ? t('VIP Reward') : t('Lucky Draw');
    }, [luckyDraw, t]
  );

  const eventDate = useMemo(
    () => {
      const { djsStartsAt, djsEndsAt } = luckyDraw;
      const formattedStartsAt = djsStartsAt.locale(lang).format('MMM DD, hh:mm A');
      const formattedEndsAt = djsEndsAt.locale(lang).format('MMM DD, hh:mm A');
      return [formattedStartsAt, formattedEndsAt];
    }, [luckyDraw, lang]
  );

  const eventPrizeAmount = useMemo(
    () => {
      const { prizeDistribution, decPrizeAmount } = luckyDraw;
      if (prizeDistribution === 'fixed') {
        return `${_POINT_SYMBOL} ${decPrizeAmount.toFixed(2)}`;
      } else {
        return `${_POINT_SYMBOL} ${t('Random')}`;
      }
    }, [luckyDraw, t]
  );

  const eventWinningRate = useMemo(
    () => {
      const { participantsPerPrize } = luckyDraw;
      if (participantsPerPrize === 1) {
        return t('Guaranteed');
      } else {
        const decWinningRate = new Decimal(1).dividedBy(participantsPerPrize).times(100);
        return `${decWinningRate.toFixed(2)}%`;
      }
    }, [luckyDraw, t]
  );

  const eventRequirement = useMemo(
    () => {
      const { requirementType, requirementLevelMin, requirementLevelMax, requirementRanks } = luckyDraw;

      switch (requirementType) {
        case 'deposit':
          return `${t('Deposit')}: `;

        case 'turnover':
          return `${t('Turnover')}: `;

        case 'rank':
          if (!tiers?.length) return '';
          const mappedRanks = requirementRanks.map(function (r) {
            const findRank = find(tiers, { _id: r });
            return get(findRank, `name.${lang}`, '-');
          });

          const isAllRanks = mappedRanks.length === tiers.length;
          if (isAllRanks) return t('Any Rank');

          const combinedRanks = mappedRanks.join(', ');

          return `${t('Rank')}: ${combinedRanks}`

        case 'level':
          const minUnbound = requirementLevelMin === -1 ? true : false;
          const maxUnbound = requirementLevelMax === -1 ? true : false;

          if (minUnbound && maxUnbound) return t('Any Level');

          let levelRequirementText = '';

          if (minUnbound) {
            levelRequirementText = t('Level Requirement Up To', { max: requirementLevelMax });
          } else if (maxUnbound) {
            levelRequirementText = t('Level Requirement From', { min: requirementLevelMin });
          } else {
            levelRequirementText = t('Level Requirement', { min: requirementLevelMin, max: requirementLevelMax });
          }
          return `${t('Level')}: ${levelRequirementText}`;


        default:
          return '';
      }
    }, [luckyDraw, tiers, t, lang]
  );

  const shouldBlinkEligible = useMemo(
    () => {
      const { isEligible, isEnrolled, isEnded } = luckyDraw;

      if (isEligible && !isEnrolled && !isEnded) return true;
      return false;
    }, [luckyDraw]
  );

  const isEnrollmentState = useMemo(
    () => {
      return luckyDraw?.state === 'enrollment' ? true : false;
    }, [luckyDraw]
  );

  const cardElevation = useMemo(
    () => {
      return isEnrollmentState ? 8 : 0;
    }, [isEnrollmentState]
  );

  const isAmountType = useMemo(
    () => {
      const { requirementType = '' } = luckyDraw;
      return requirementType === 'deposit' || requirementType === 'turnover';
    }, [luckyDraw]
  );

  const wonAmount = useMemo(
    () => {
      return new Decimal(get(luckyDraw, 'wonAmount', '0')).toFixed(2);
    }, [luckyDraw]
  );

  const isJoined = useMemo(
    () => {
      const isEnded = get(luckyDraw, 'isEnded', false);
      const isEnrolled = get(luckyDraw, 'isEnrolled', false);

      return (!isEnded && isEnrolled) ? true : false;
    }, [luckyDraw]
  );

  const isLose = useMemo(
    () => {
      const isEnded = get(luckyDraw, 'isEnded', false);
      const isEnrolled = get(luckyDraw, 'isEnrolled', false);
      const isWon = get(luckyDraw, 'isWon', false);

      return (!!isEnded && isEnrolled && !isWon) ? true : false;
    }, [luckyDraw]
  );

  const blinkAnimation = useMemo(
    () => {
      return keyframes`
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
      `;
    }, []
  );

  useEffect(() => {
    if (!notEligibleLastAnimated) return;

    requirementRef?.current?.animate({
      transform: ['rotate(2deg)', 'rotate(4deg)', 'rotate(-2deg)']
    }, 100);
  }, [notEligibleLastAnimated]);

  useEffect(() => {
    const {
      participantCount: rawParticipantCount = 0,
      decRecentAmount = new Decimal(0),
      decRequirementAmount = new Decimal(0),
      decTotalFreePoint = new Decimal(0),
      prizeCount = 0,
    } = luckyDraw;

    const participantCount = rawParticipantCount > 99 ? 99.1 : rawParticipantCount;

    setCuParticipant(prev => {
      if (prev?.end === participantCount) {
        return prev;
      }

      return {
        start: prev?.end, end: participantCount
      }
    });

    setCuRecent(prev => {
      if (prev?.end === decRecentAmount.toNumber()) {
        return prev;
      }

      if (decRecentAmount.gte(decRequirementAmount)) {
        return {
          start: prev?.end, end: decRequirementAmount.toNumber()
        }
      }

      return {
        start: prev?.end, end: decRecentAmount.toNumber()
      }
    });

    setCuGiveaway(prev => {
      if (prev?.end === decTotalFreePoint.toNumber()) {
        return prev;
      }

      return {
        start: prev?.end, end: decTotalFreePoint.toNumber()
      }
    });

    setCuPrizeCount(prev => {
      if (prev?.end === prizeCount) {
        return prev;
      }

      return {
        start: prev?.end, end: prizeCount
      }
    });
  }, [luckyDraw]);

  function getAvatar() {
    return (
      <ImageAvatar
        variant='rounded'
        alt={`${luckyDraw.name}-${luckyDraw._id}`}
        imageId={luckyDraw.photo}
      />
    );
  }

  const handleOnWinnersPreviewClicked = (event) => {
    event?.preventDefault();
    onWinnersPreview(luckyDraw?._id);
  };

  const handleOnParticipantsPreviewClicked = (event) => {
    event?.preventDefault();
    onParticipantsPreview(luckyDraw?._id);
  };

  const submitJoinAsync = async (luckyDrawId) => {
    try {
      setStatus('pending');
      await feathers.service('lucky-draw-enrolls').create({ luckyDrawId });
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setStatus('idle');
    }
  };

  const handleOnJoinClicked = (event) => {
    event?.preventDefault();
    if (luckyDraw.isEligible) {
      submitJoinAsync(luckyDraw._id);
    } else {
      setNotEligibleLastAnimated(new Date());
    }
  };

  return (
    <Card elevation={cardElevation}>
      <CardHeader
        disableTypography={true}
        title={
          <Typography variant='title' sx={{ fontWeight: 700 }}>
            {luckyDraw.name}
          </Typography>
        }
        subheader={
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='caption' sx={{
              fontWeight: 700,
              fontStyle: 'oblique',
              textTransform: 'uppercase',
              color: luckyDraw.isEligible ? theme => (theme.palette.mode === 'light' ? green[700] : green[500]) : 'inherit',
              animation: shouldBlinkEligible ? `${blinkAnimation} 0.5s infinite ease` : 'none',
            }}>
              {luckyDraw.isEligible ? t('Eligible') : t('Not Eligible')}
            </Typography>
            <Typography variant='caption' sx={{
              fontWeight: 700,
              fontStyle: 'oblique',
              textTransform: 'uppercase',
              color: theme => (theme.palette.mode === 'light' ? amber[900] : amber[500]),
            }}>
              {luckyDraw.isVIPReward ? t('VIP Only') : ''}
          </Typography>
          </Box>
        }
        avatar={
          getAvatar()
        }
      />
      <Divider />
      <CardContent>
        {
          luckyDraw?.isWon &&
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 3 }}>
            <Typography variant='h5' sx={{
              fontWeight: 700,
              textTransform: 'uppercase',
              animation: `${blinkAnimation} 1s ease 8`,
              color: theme => (theme.palette.mode === 'light' ? amber[900] : amber[500]),
            }}>
              {t('Winner Alert')}
            </Typography>
            <Typography variant='h6' sx={{
              animation: `${blinkAnimation} 1s ease 8`,
              color: 'text.secondary',
            }}>
              {t('Won amount', { wonAmount, symbol: _POINT_SYMBOL })}
            </Typography>
          </Box>
        }
        {
          isLose &&
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 3 }}>
            <Typography variant='h5' sx={{
              fontWeight: 700,
              textTransform: 'uppercase',
            }}>
              {t('Not this time')}
            </Typography>
            <Typography variant='h6' sx={{
              color: 'text.secondary',
              fontStyle: 'oblique',
            }}>
              {t('Better luck next time')}
            </Typography>
          </Box>
        }
        <LuckyDrawProgress
          luckyDraw={luckyDraw}
          isEnrolled={luckyDraw.isEnrolled}
          isVIPReward={luckyDraw.isVIPReward}
        />
        <Paper elevation={4} sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
              {t('Event Type')}
            </Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
              {eventType}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
              {t('Starts At')}
            </Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
              {eventDate[0]}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
              {t('Ends At')}
            </Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
              {eventDate[1]}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
              {t('Prize')}
            </Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
              {eventPrizeAmount}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
              {t('Prize Pool')}
            </Typography>
            <CountUp
              start={cuGiveaway?.start}
              end={cuGiveaway?.end}
              duration={0.88}
              separator=" "
              decimals={2}
              decimal="."
              prefix={`${_POINT_SYMBOL} `}
              suffix=''
            >
              {({ countUpRef }) => (
                <Typography variant='subtitle2' sx={{ fontWeight: 700 }} ref={countUpRef} />
              )}
            </CountUp>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link component='button' variant='subtitle2' sx={{ color: 'text.secondary' }} onClick={handleOnParticipantsPreviewClicked}>
              {t('Participants')}
            </Link>
            <CountUp
              start={cuParticipant?.start}
              end={cuParticipant?.end}
              duration={0.88}
              separator=" "
              decimals={0}
              decimal="."
              prefix=''
              suffix={cuParticipant?.end > 99 ? '+' : ''}
            >
              {({ countUpRef }) => (
                <Typography variant='subtitle2' sx={{ fontWeight: 700 }} ref={countUpRef} />
              )}
            </CountUp>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link component='button' variant='subtitle2' sx={{ color: 'text.secondary' }} onClick={handleOnWinnersPreviewClicked}>
              {t('Winners')}
            </Link>
            <CountUp
              start={cuPrizeCount?.start}
              end={cuPrizeCount?.end}
              duration={0.88}
              separator=" "
              decimals={0}
              decimal="."
              prefix=''
              suffix=''
            >
              {({ countUpRef }) => (
                <Typography variant='subtitle2' sx={{ fontWeight: 700 }} ref={countUpRef} />
              )}
            </CountUp>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
              {t('Winning Rate')}
            </Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
              {eventWinningRate}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
              {t('Requirement')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }} ref={requirementRef}>
              <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
                {eventRequirement}
              </Typography>
              {
                isAmountType &&
                <CountUp
                  start={cuRecent?.start}
                  end={cuRecent?.end}
                  duration={0.88}
                  separator=" "
                  decimals={2}
                  decimal="."
                  prefix='&nbsp;'
                  suffix=''
                >
                  {({ countUpRef }) => (
                    <>
                      <Typography variant='subtitle2' sx={{ fontWeight: 700 }} ref={countUpRef} />
                      <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
                        {`/${luckyDraw.decRequirementAmount.toFixed(2)} `}
                      </Typography>
                    </>
                  )}
                </CountUp>
              }
            </Box>
          </Box>
        </Paper>
      </CardContent>
      <CardActions>
        <LoadingButton
          disabled={luckyDraw.isEnrolled || luckyDraw.isEnded}
          fullWidth
          onClick={handleOnJoinClicked}
          loading={status !== 'idle'}
          variant='contained'
          color='primary'
        >
          {
            isJoined ?
            t('Joined') :
            t('Join Now')
          }
        </LoadingButton>
      </CardActions>
    </Card>
  );
}

LuckyDrawCard.propTypes = {
  luckyDraw: PropTypes.object.isRequired,
  onWinnersPreview: PropTypes.func.isRequired,
  onParticipantsPreview: PropTypes.func.isRequired,
};
