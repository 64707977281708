import useShouldShowPrompt from './useShouldShowPrompt';

const _PROMPT_NAME = 'iosLastPrompt';

const isIOS = () => {
  //if (navigator.standalone) return false;
  if (window.navigator.standalone === true) return false;

  const ua = window.navigator.userAgent;
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);
  return isIPad || isIPhone;
};

const useIosPrompt = () => {
  const [ userShouldBePrompted, handleUserSeeingPrompt ] = useShouldShowPrompt(_PROMPT_NAME);
  return [ isIOS() && userShouldBePrompted, handleUserSeeingPrompt ];
};

export default useIosPrompt;
