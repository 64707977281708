import ContentContext from './commonContext';
import React, { useMemo } from 'react';
import useFeathers from 'hooks/useFeathers';
import { useAuth } from 'hooks/useAuth';
import { get, filter } from 'lodash';
import { logicizeLuckyDraws } from './CommonProvider.utils';
import Decimal from 'decimal.js';

const DEFAULT_BALANCE = '0';
const DECIMAL_PLACES = 2;
const ROUNDING_MODE = Decimal.ROUND_FLOOR;

function roundToDecimal(value) {
  return new Decimal(value).toDecimalPlaces(DECIMAL_PLACES, ROUNDING_MODE);
}

function CommonProvider({ children }) {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');

  const queryFetchAll = {
    query: {
      $limit: -1
    }
  };

  const queryFetchOne = {
    query: {
      $limit: 1
    }
  };

  const { data: companySettings, ready: companySettingsReady } = useFeathers('company-settings', queryFetchOne);
  const { data: wallets, ready: walletsReady } = useFeathers('wallets', queryFetchOne);
  const { data: userDatas, ready: userDatasReady } = useFeathers('user-data', queryFetchOne);
  const { data: games, ready: gamesReady } = useFeathers('games', queryFetchAll);
  const { data: gameIdsRaw, ready: gameIdsReady } = useFeathers('game-ids', queryFetchAll, { createAsUpdate: true });
  const { data: companyBanks, ready: companyBanksReady } = useFeathers('company-banks', queryFetchAll);
  const { data: pointBoosters, ready: pointBoostersReady } = useFeathers('point-boosters', queryFetchAll);
  const { data: redemptionDeals, ready: redemptionDealsReady } = useFeathers('point-redemption-deals', queryFetchAll);
  const { data: luckyDrawsOriginal, ready: luckyDrawsReady } = useFeathers('lucky-draws', queryFetchAll);
  const { data: tiers, ready: tiersReady } = useFeathers('member-tiers', queryFetchAll);
  const { data: themes, ready: themesReady } = useFeathers('themes', queryFetchAll);

  const luckyDraws = useMemo(
    () => {
      if (!userDatas.length || !luckyDrawsOriginal.length) return [];

      const userData = get(userDatas, '[0]', null);

      return logicizeLuckyDraws(luckyDrawsOriginal, userData, lang);
    }, [luckyDrawsOriginal, userDatas, lang]
  );

  const hasEligibleLuckyDraw = useMemo(
    () => {
      if (!luckyDraws.length) return false;
      const filterEligible = filter(luckyDraws, { isEnded: false, isEnrolled: false, isEligible: true });
      return !!filterEligible.length;
    }, [luckyDraws]
  );

  const wallet = useMemo(
    () => {
      if (!wallets.length) return null;

      const wallet = wallets[0];
      const cashBalance = roundToDecimal(wallet?.cashBalance?.$numberDecimal || DEFAULT_BALANCE);
      const pointBalance = roundToDecimal(wallet?.pointBalance?.$numberDecimal || DEFAULT_BALANCE);

      return {
        ...wallet,
        cashBalance: {
          $numberDecimal: cashBalance.toString()
        },
        pointBalance: {
          $numberDecimal: pointBalance.toString()
        }
      };
    }, [wallets]
  );

  const gameIds = useMemo(
    () => {
      if (!gameIdsRaw.length) return [];

      return gameIdsRaw.map(gameId => {
        const balance = roundToDecimal(gameId?.balance?.$numberDecimal || DEFAULT_BALANCE);

        return {
          ...gameId,
          balance: {
            $numberDecimal: balance.toString()
          }
        };
      });
    }, [gameIdsRaw]
  );

  const companySetting = useMemo(
    () => {
      if (!companySettings.length) return null;

      const companySetting = companySettings[0];
      const { country } = companySetting;
      let currencySymbol = '¤';

      switch (country) {
        case 'sg':
        case 'au':
        case 'nz':
          currencySymbol = '$';
          break;

        case 'my':
          currencySymbol = 'RM';
          break;

        case 'th':
          currencySymbol = '฿';
          break;

        default:
          break;
      }

      return {
        ...companySetting,
        currencySymbol
      };
    }, [companySettings]
  );

  return (
    <ContentContext.Provider value={{
      companySetting: companySetting,
      companySettingReady: companySettingsReady,
      wallet,
      walletReady: walletsReady,
      userData: get(userDatas, '[0]', null),
      userDataReady: userDatasReady,
      games,
      gamesReady,
      gameIds,
      gameIdsReady,
      companyBanks,
      companyBanksReady,
      pointBoosters,
      pointBoostersReady,
      redemptionDeals,
      redemptionDealsReady,
      luckyDraws,
      luckyDrawsReady,
      hasEligibleLuckyDraw,
      tiers,
      tiersReady,
      themes,
      themesReady,
    }}>
      {children}
    </ContentContext.Provider>
  );
}

export default CommonProvider;
