import React from 'react';
import PropTypes from 'prop-types';
import RotaryButton from './buttons/RotaryButton';
import PodiumButton from './buttons/PodiumButton';
import MandalaButton from './buttons/MandalaButton';
import RedMandalaButton from './buttons/RedMandalaButton';
import YellowMandalaButton from './buttons/YellowMandalaButton';
import GreenMandalaButton from './buttons/GreenMandalaButton';
import SimpleButton from './buttons/SimpleButton';
import PlainButton from './buttons/PlainButton';

export default function GameButton(props) {
  const { variant, ...otherProps } = props;

  switch (variant) {
    case 'simple':
      return <SimpleButton { ...otherProps } />;

    case 'rotary':
      return <RotaryButton { ...otherProps } />;

    case 'podium':
      return <PodiumButton { ...otherProps } />;

    case 'mandala':
      return <MandalaButton { ...otherProps } />;

    case 'mandala_red':
      return <RedMandalaButton { ...otherProps } />;

    case 'mandala_yellow':
      return <YellowMandalaButton { ...otherProps } />;

    case 'mandala_green':
      return <GreenMandalaButton { ...otherProps } />;

    case 'plain':
      return <PlainButton { ...otherProps } />;

    default:
      return null;
  }
}

GameButton.propTypes = {
  gameType: PropTypes.string.isRequired,
  gameBalance: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};
