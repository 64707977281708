import React, { useMemo } from 'react';
import {
  Box,
  Paper,
} from '@mui/material';
import useGameLogo from 'hooks/useGameLogo';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';

export default function Games(props) {
  const { getGameImagePath } = useGameLogo();
  const {
    isEnabled,
    //name,
    gameType,
    gameBalance,
    //gameIndex,
    onClick
  } = props;
  const theme = useTheme();

  const gameBalanceAnimation = useMemo(
    () => {
      return keyframes`
        from, to { transform: scale(1, 1); }
        25% { transform: scale(0.9, 1.1); }
        50% { transform: scale(1.1, 0.9); }
        75% { transform: scale(0.95, 1.05); }
      `;
    }, []
  );

  const showGameBalanceAnimation = useMemo(
    () => {
      return gameBalance > 0 && isEnabled;
    }, [gameBalance, isEnabled]
  );

  const isDarkPalette = useMemo(
    () => {
      const { palette } = theme || {};
      const { mode } = palette || {};
      return mode === 'dark' ? true : false;
    }, [theme]
  );

  const bgGradient = useMemo(() => {
    //const mod = gameIndex % 4;
    const mod = 0;

    const firstColor = isDarkPalette ? '#111' : '#e9e9e9';
    const secondColor = isDarkPalette ? '#333' : '#d4d4d4';

    switch (mod) {
      case 0:
        return `linear-gradient(45deg, ${firstColor}, ${secondColor})`;
      case 1:
        return `linear-gradient(135deg, ${firstColor}, ${secondColor})`;
      case 2:
        return `linear-gradient(225deg, ${firstColor}, ${secondColor})`;
      case 3:
        return `linear-gradient(315deg, ${firstColor}, ${secondColor})`;
      default:
        return `linear-gradient(45deg, ${firstColor}, ${secondColor})`;
    }
  }, [isDarkPalette]);

  return (
    <Paper
      elevation={ 3 }
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: {
          xs: 1.5,
          sm: 3.5,
        },
        ...!isEnabled && {
          filter: 'grayscale(100%)',
        },
        background: bgGradient,
        '&:hover': {
          transform: 'scale(1.05)',
          transition: 'transform 0.1s',
        },
      }}
      onClick={ onClick }
    >

      <Box
        component='img'
        src={ getGameImagePath(gameType) }
        alt={ gameType }
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          ...(
            showGameBalanceAnimation && {
              animation: `${gameBalanceAnimation} 0.5s infinite linear`,
            }
          )
        }}
      />
      {/* <Box>
        <Typography variant='h6' align='center'>
          { name }
        </Typography>
      </Box> */}

    </Paper>
  );
}
