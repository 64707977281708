import React, { useMemo, useContext } from 'react';
import { get } from 'lodash';
import Decimal from 'decimal.js';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import useFeathers from 'hooks/useFeathers';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import CommonContext from 'features/context/commonContext';
import numeral from 'numeral';

export default function LiveTransactionCard(props) {
  const { t } = useTranslation();
  const { data, ready: dataReady } = useFeathers('live-transactions', {
    query: {
      $limit: 20,
      $sort: {
        transactedAt: -1
      }
    }
  }, {
    createdAtTop: true
  });

  const latestData = useMemo(
    () => {
      return data.slice(0, 10);
    }, [data]
  );

  const { companySetting } = useContext(CommonContext);

  const currencySymbol = useMemo(
    () => {
      return companySetting?.currencySymbol || '';
    }, [companySetting]
  );

  return (
    <>
      { !dataReady && <LinearProgress /> }
      <Card>
        <CardHeader
          disableTypography
          title={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1'>
                {t('Live Transaction')}
              </Typography>
              <Box sx={{ ml: 2 }}>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </Box>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          <Timeline position="right">
            {
              latestData.map(function(data) {
                const decAmount = new Decimal(get(data, 'amount.$numberDecimal', '0'));
                const djsTransactedAt = dayjs(get(data, 'transactedAt'));
                const transactedAt = djsTransactedAt.format('hh:mm a');
                const type = get(data, 'type');
                const isWithdrawal = data?.type === 'withdrawal' ? true : false;

                return (
                  <TimelineItem key={data._id}>
                    <TimelineOppositeContent color="text.secondary">
                      <Typography variant='subtitle1'>{transactedAt}</Typography>
                      <Typography variant='caption'>{t(type)}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color={isWithdrawal ? 'success' : 'grey'} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant='subtitle1' sx={{ fontWeight: isWithdrawal ? 700 : 'inherit' }}>
                        {`${currencySymbol}${numeral(decAmount.toNumber()).format('0.00a')}`}
                      </Typography>
                      <Typography variant='caption'>{`${data.username}`}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })
            }
          </Timeline>
        </CardContent>
      </Card>
    </>
  );
}