import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { _CASH_SYMBOL } from 'utils/symbol';
import CommonContext from 'features/context/commonContext';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import { get, filter, find, endsWith, startsWith, includes } from 'lodash';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/HighlightOff';
import HelpIcon from '@mui/icons-material/HelpTwoTone';
import CopyIcon from '@mui/icons-material/ContentCopyTwoTone';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CopyToClipboard from 'features/copyToClipboard/CopyToClipboard';
import TextField from '@mui/material/TextField';
import Decimal from 'decimal.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router-dom";
import { getCompanyBankOptions, getFullBankLabel } from 'utils/bank';
import feathers from 'services/feathers';
import QRBackdrop from 'features/qrBackdrop/QRBackdrop';

export default function BankSelector() {
  const { companyBanks, companyBanksReady } = useContext(CommonContext);
  const { t } = useTranslation();
  const [ bankPreview, setBankPreview ] = useState(null);
  const [ designatedRef, setDesignatedRef ] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const [ qrBackdrop, setQRBackdrop ] = useState({
    open: false,
    data: null,
    fileName: '',
  });

  const bankSchema = Yup.object().shape({
    bankId: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      bankId: '',
    },
    validationSchema: bankSchema,
    onSubmit: async values => {
      const { bankId = 0 } = values;
      navigate(`${bankId}`);
    },
  });

  const amount = useMemo(
    () => {
      const { amount = 0 } = params;
      return amount;
    }, [params]
  );

  const decAmount = useMemo(
    () => {
      const ret = new Decimal(amount);
      return ret;
    }, [amount]
  );

  const banks = useMemo(
    () => {
      if (!companyBanksReady) return [];
      const filteredBanks = filter(companyBanks, function(b) {
        const {
          isEnabled = false,
          isInited = false,
          restrictions = {},
        } = b;

        const {
          minDepositAmount: {
            $numberDecimal: minDepositAmount = 0,
          } = {},
          maxDepositAmount: {
            $numberDecimal: maxDepositAmount = 0,
          } = {},
        } = restrictions;

        if (minDepositAmount > 0 && decAmount.lt(minDepositAmount)) return false;
        if (maxDepositAmount > 0 && decAmount.gt(maxDepositAmount)) return false;

        return (isEnabled && isInited);
      });

      return filteredBanks;
    }, [companyBanksReady, companyBanks, decAmount]
  );

  const bankList = useMemo(
    () => {
      if (!banks?.length) return null;
      return getCompanyBankOptions(t, banks);
    }, [t, banks]
  );

  const selectedBankId = useMemo(
    () => {
      return get(formik, 'values.bankId', '');
    }, [formik]
  );

  useEffect(
    () => {
      const bank = find(banks, { _id: selectedBankId });
      const humanBankName = getFullBankLabel(t, bank);

      const bankName = get(bank, 'bankName', '');
      const isDuitNow = startsWith(bankName, 'my_duitnow_');
      const isBsbType = endsWith(bankName, '_bsb');
      const isAuBank = startsWith(bankName, 'au_');
      const isNzBank = startsWith(bankName, 'nz_');
      const isBankWithPayID = includes(['my_duitnow_bank'], bankName);

      if (isBsbType) {
        const fullDisplayNumber = get(bank, 'accountNumber', '');
        const bsbNumber = fullDisplayNumber.substring(0, 6);
        const displayNumber = fullDisplayNumber.substring(6);

        setBankPreview({
          ...bank,
          bsbNumber,
          displayNumber,
          humanBankName,
          isDuitNow,
          isAuBank,
          isNzBank,
        });
      } else {
        setBankPreview({
          ...bank,
          humanBankName,
          isDuitNow,
          isAuBank,
          isNzBank,
          ...(
            isBankWithPayID && {
              displayNumber: get(bank, 'accountNumber')
            }
          )
        });
      }
    }, [banks, selectedBankId, t]
  );

  const bsbNumberChunks = useMemo(
    () => {
      const bsbNumber = get(bankPreview, 'bsbNumber');
      if (!bsbNumber) return [];

      const bsbNumberChunks = bsbNumber.match(/.{1,3}/g);
      return bsbNumberChunks;
    }, [bankPreview]
  );

  const accountNumberChunks = useMemo(
    () => {
      const displayNumber = get(bankPreview, 'displayNumber');
      if (!displayNumber) return [];

      const isAllNumber = displayNumber.match(/^\d+$/);
      if (!isAllNumber) return [displayNumber];

      const accountNumberChunks = displayNumber.match(/.{1,3}/g);
      return accountNumberChunks;
    }, [bankPreview]
  );

  useEffect(
    () => {
      const bankId = get(bankPreview, '_id');
      if (!bankId) return;

      async function fetch() {
        try {
          const data = await feathers.service('company-banks').get(bankId, {
            query: {
              __action__: 'add-reference',
            }
          });

          const designatedRef = get(data, 'designatedRef');
          setDesignatedRef(designatedRef);
        } catch (err) {
          console.error(err);
        }
      }

      fetch();
    }, [bankPreview]
  );

  const isAuNzBank = useMemo(
    () => {
      return (bankPreview?.isAuBank || bankPreview?.isNzBank);
    }, [bankPreview]
  );

  const designatedRefTips = useMemo(
    () => {
      if (isAuNzBank) return t('Instant approval code tips');
      return t('Instant approval tips');
    }, [t, isAuNzBank]
  );

  const copiedText = useMemo(
    () => {
      if (isAuNzBank) return t('Code copied');
      return t('Reference copied');
    }, [t, isAuNzBank]
  );

  const hasQrCode = useMemo(
    () => {
      return !!(get(bankPreview, 'qrCode'));
    }, [bankPreview]
  );

  function onResetClicked(event) {
    event.preventDefault();
    formik.resetForm();
  }

  const handleQRClicked = useCallback(
    (event) => {
      event?.preventDefault();

      const qrCode = get(bankPreview, 'qrCode');
      const name = get(bankPreview, 'name', '');
      const humanBankName = get(bankPreview, 'humanBankName', '');

      if (!qrCode) return;

      setQRBackdrop({
        open: true,
        data: qrCode,
        fileName: `${humanBankName} - ${name}`,
      });
    }, [bankPreview]
  );

  const handleQRBackdropClose = (event) => {
    event?.preventDefault();
    setQRBackdrop({
      open: false,
      data: null,
      fileName: '',
    });
  };

  if (!companyBanksReady) {
    return (
      <Paper elevation={2}>
        <LinearProgress />
      </Paper>
    );
  }

  return (
    <Box>
      <QRBackdrop {...qrBackdrop } onClose={handleQRBackdropClose} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card elevation={4} component="form" onSubmit={formik.handleSubmit}>
            <CardHeader
              title={t('Deposit')}
              action={ !!selectedBankId ?
                <IconButton color='inherit' onClick={onResetClicked}>
                  <CloseIcon />
                </IconButton> : null
              }
            />
            <Divider />
            {
              !!selectedBankId &&
              <CardContent>
                <Typography variant='h4' gutterBottom sx={{ fontWeight: 700, textAlign: 'right' }}>
                  {`${_CASH_SYMBOL} ${decAmount.toFixed(2)}`}
                </Typography>
                <Typography variant='body2' gutterBottom >
                  {bankPreview?.humanBankName}
                </Typography>
                <Typography variant='body2' gutterBottom sx={{ color: 'text.secondary', fontStyle: 'oblique', fontWeight: 300 }}>
                  {bankPreview?.accountName}
                </Typography>
                {
                  !!bankPreview?.bsbNumber && (
                    <Box>
                      <CopyToClipboard text={bankPreview?.bsbNumber} copiedText={t('BSB number copied')}>
                        <Button autoFocus color='info' sx={{ my: 1 }} variant="contained" startIcon={<CopyIcon />}>
                          <Typography variant='subtitle2' sx={{ mr: 1, fontStyle: 'oblique' }}>{`BSB`}</Typography>
                          <Typography variant='h5'>
                          {
                            bsbNumberChunks.map((p, i, chunks) => {
                              const fw = (i % 2 === 0) ? 700 : 300;
                              const text = (i + 1 === chunks.length) ? p : `${p} `;
                              return (
                                <Typography key={`${i}${p}`} component='span' variant='h5' sx={{ fontWeight: fw }}>{text}</Typography>
                              )
                            })
                          }
                          </Typography>
                        </Button>
                      </CopyToClipboard>
                    </Box>
                  )
                }
                <Box>
                  <CopyToClipboard text={bankPreview?.displayNumber} copiedText={t('Account number copied')}>
                    <Button autoFocus color='info' sx={{ my: 1 }} variant="contained" startIcon={<CopyIcon />}>
                      <Typography variant='h5'>
                      {
                        accountNumberChunks.map((p, i, chunks) => {
                          const fw = (i % 2 === 0) ? 700 : 300;
                          const text = (i + 1 === chunks.length) ? p : `${p} `;
                          return (
                            <Typography key={`${i}${p}`} component='span' variant='h5' sx={{ fontWeight: fw }}>{text}</Typography>
                          )
                        })
                      }
                      </Typography>
                    </Button>
                  </CopyToClipboard>
                </Box>
              </CardContent>
            }
            {
              !selectedBankId && <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      select
                      id="bankId"
                      name="bankId"
                      label={t('Select Bank')}
                      value={get(formik, 'values.bankId', '')}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.bankId && Boolean(formik.errors.bankId)}
                      helperText={formik.touched.bankId && formik.errors.bankId}
                      SelectProps={{
                        native: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                          {'🏦'}
                          </InputAdornment>
                        )
                      }}
                    >
                      <option key='' value=''></option>
                    {
                      bankList
                    }
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            }
            <Divider />
            <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              {
                hasQrCode &&
                <Box sx={{ width: '100%', textAlign: 'left' }}>
                  <IconButton color='inherit' onClick={handleQRClicked}>
                   <QrCodeIcon />
                  </IconButton>
                </Box>
              }
              <Button type="submit" variant='contained'>{t('Next')}</Button>
            </CardActions>
          </Card>
        </Grid>
        {
          (!!selectedBankId && !!designatedRef) &&
          (
            <Grid item xs={12}>
              <Card>
                <CardHeader title={t('Instant approval')} subheader={designatedRefTips} avatar={'⚡'} />
                <Divider />
                <CardContent>
                  <Box>
                    <CopyToClipboard text={designatedRef} copiedText={copiedText}>
                      <Button color='info' sx={{ my: 1, textTransform: 'none' }} variant="contained" startIcon={<CopyIcon />}>
                        <Typography variant='subtitle2'>
                        {
                          designatedRef
                        }
                        </Typography>
                      </Button>
                    </CopyToClipboard>
                  </Box>
                  {
                    !!bankPreview.isDuitNow &&
                    <Paper variant='outlined' sx={{ p: 1, mt: 1 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                            <Box component='img' src='/images/payment/tng.webp' alt='TNG Logo' sx={{ borderRadius: 50, width: '2.4rem', height: '2.4rem' }} />
                            <Typography variant='body2' sx={{ mx: 2, color: 'text.secondary', fontStyle: 'oblique' }}>
                              {t('DuitNow tng reference tips') }
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                            <Box component='img' src='/images/payment/shopee.webp' alt='TNG Logo' sx={{ borderRadius: 50, width: '2.4rem', height: '2.4rem' }} />
                            <Typography variant='body2' sx={{ mx: 2, color: 'text.secondary', fontStyle: 'oblique' }}>
                              {t('DuitNow shopee reference tips') }
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  }
                  {
                    !!isAuNzBank &&
                    <Paper variant='outlined' sx={{ p: 1, mt: 1 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                            <HelpIcon color='success' sx={{ fontSize: 30 }} />
                            <Typography variant='body2' sx={{ mx: 2, color: 'text.secondary', fontStyle: 'oblique' }}>
                              {t('AU reference tips') }
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  }
                </CardContent>
              </Card>
            </Grid>
        )
        }
      </Grid>
    </Box>
  );
}