import React, { useContext } from 'react';
import CompanySettingContext from 'features/context/companySettingContext';
import { Helmet } from 'react-helmet';

export default function PWAHelmet() {
  const {
    pageConfig = {},
    ready = false,
  } = useContext(CompanySettingContext);

  const {
    name,
    themeColor,
  } = pageConfig?.pwaManifest || {};

  if (!ready) return null;

  return (
    <Helmet>
      <title>{name}</title>
      <meta name="description" content={name} />
      <meta name="theme-color" content={themeColor} />
    </Helmet>
  );
}