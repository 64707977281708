import React, { useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import feathers from 'services/feathers';
import { get } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import NewsDialog from 'features/news/NewsDialog';
import {
  //useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';

export default function News(props) {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ status, setStatus ] = useState('idle');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ news, setNews ] = useState(null);
  const location = useLocation();
  //const navigate = useNavigate();
  const { newsId } = useParams();
  const [ openDialog, setOpenDialog ] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (status !== 'fetch') return;

    async function fetch() {
      try {
        const news = await feathers.service('news').get(newsId);
        if (isMounted) {
          setNews(news);
        }
      } catch (err) {
        if (isMounted) setGlobalErrorMessage({ err });
      } finally {
        if (isMounted) setStatus('idle');
      }
    }

    fetch();

    return () => {
      isMounted = false;
    }
  }, [setGlobalErrorMessage, lang, status, newsId]);

  useEffect(() => {
    if (status === 'fetch') return;
    if (!news) return;
    setOpenDialog(true);
  }, [status, news]);

  useEffect(() => {
    const state = get(location, 'state');
    if (!state) {
      setStatus('fetch');
    } else {
      setNews(state);
    }
  }, [location]);

  const handleOnClose = (event) => {
    event.preventDefault();
    setOpenDialog(false);
    setNews(null);
    //navigate(-1); // go back once
    //navigate('/'); // back to home
    window.close();
  };

  return (
    <NewsDialog open={openDialog} lang={lang} news={news} handleOnClose={handleOnClose} />
  );
}
