import React, { useMemo, useContext } from 'react';
import CommonContext from 'features/context/commonContext';
import Box from '@mui/material/Box';
import { get, find } from 'lodash';
import AmountSelector from './AmountSelector';
import BankSelector from './BankSelector';
import ReceiptUpload from './ReceiptUpload';
import { useParams } from "react-router-dom";
import Decimal from 'decimal.js';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

export default function Deposit() {
  const { companyBanks } = useContext(CommonContext);
  const params = useParams();

  const inputData = useMemo(
    () => {
      const { amount = 0, bankId = null } = params;
      let decAmount;

      try {
        decAmount = new Decimal(amount);
      } catch (err) {
        decAmount = new Decimal(0);
      }

      const findBank = find(companyBanks, { _id: bankId });

      return {
        amount: decAmount.toNumber(),
        bankId: get(findBank, '_id', ''),
      }
    }, [params, companyBanks]
  );

  function generateStep() {
    if (!!inputData.bankId) return (<ReceiptUpload />);

    if (!!inputData.amount) return (<BankSelector />);

    return (<AmountSelector />);
  }

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      {
        generateStep()
      }
    </Box>
  );
}
