import React, { useCallback, useState, useMemo, useContext, useEffect, useRef } from 'react';
import CommonContext from 'features/context/commonContext';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import PhotoCamera from '@mui/icons-material/AddPhotoAlternateTwoTone';
import Stack from '@mui/material/Stack';
import { get, find } from 'lodash';
import Cropper from 'react-easy-crop'
import { getCroppedImg, getRotatedImage } from 'utils/canvas-utils';
import { getOrientation } from 'get-orientation/browser';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import CloseIcon from '@mui/icons-material/HighlightOff';
import ZoomOutIcon from '@mui/icons-material/ZoomOutTwoTone';
import ZoomInIcon from '@mui/icons-material/ZoomInTwoTone';
import RotateLeftIcon from '@mui/icons-material/RotateLeftTwoTone';
import RotateRightIcon from '@mui/icons-material/RotateRightTwoTone';
import DoneIcon from '@mui/icons-material/CheckTwoTone';
import CancelIcon from '@mui/icons-material/ClearTwoTone';
import LinearProgress from '@mui/material/LinearProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import Decimal from 'decimal.js';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import { useParams } from "react-router-dom";
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ThanksDialog from 'features/thanksDialog/ThanksDialog';
import feathers from 'services/feathers';
import axiosClient from 'services/axios';
import axios from 'axios';
import Tesseract from 'tesseract.js';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CancelToken = axios.CancelToken;

const Input = styled('input')({
  display: 'none',
});

const CropperArea = styled('div', {})(
  ({ theme }) => ({
    position: 'relative',
    height: 300,
    [theme.breakpoints.up('sm')]: {
      height: 400
    },
    '& .reactEasyCrop_Container': {
    }
  })
);

const ViewingArea = styled('div', {})(
  ({ theme }) => ({
    position: 'relative',
    '& img': {
      width: '100%'
    },
    '& .MuiButton-root': {
      position: 'absolute',
      top: 5,
      right: 5,
    }
  })
);

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
};

export default function ReceiptUpload(props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [ isReading, setIsReading ] = useState(false);
  const [ isCropping, setIsCropping ] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [ aspectRatio, setAspectRatio ] = useState('1');
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const params = useParams();
  const { companyBanks } = useContext(CommonContext);
  const { t } = useTranslation();
  const [ status, setStatus ] = useState('idle');
  const [ openThanksDialog, setOpenThanksDialog ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ animateUpload, setAnimateUpload ] = useState(null);
  const buttonRef = useRef();
  const [ source ] = useState(CancelToken.source());
  const [ ocrRef, setOcrRef ] = useState(null);
  const [ needOCR, setNeedOCR ] = useState(false);
  const [ matchRect, setMatchRect ] = useState(null);

  useEffect(() => {
    return () => {
      if (source) source.cancel('Upload cancelled');
    }
  }, [source]);

  useEffect(() => {
    if (!animateUpload) return;

    buttonRef?.current?.animate({
      transform: ['rotate(2deg)', 'rotate(4deg)', 'rotate(-2deg)']
    }, 100);
  }, [animateUpload]);

  useEffect(
    () => {
      if (!!croppedImage) return;
      setMatchRect(null);
    }, [croppedImage]
  );

  const inputData = useMemo(
    () => {
      const { amount = 0, rewardFlag = 'false', bankId = null } = params;
      let decAmount;

      try {
        decAmount = new Decimal(amount);
      } catch (err) {
        decAmount = new Decimal(0);
      }

      const findBank = find(companyBanks, { _id: bankId });

      return {
        amount: decAmount.toNumber(),
        bank: findBank,
        rewardFlag: JSON.parse(rewardFlag.toLowerCase()),
        bankId,
      }
    }, [params, companyBanks]
  );

  const hasOCROption = useMemo(
    () => {
      const bankName = get(inputData, 'bank.bankName');
      if (bankName.indexOf('my_') === 0) return true;
      return false;
    }, [inputData]
  );

  const onFileChange = async (event) => {
    const file = get(event, 'target.files[0]');
    if (!file) return;

    event.currentTarget.value = null;

    setCrop({ x: 0, y: 0});
    setZoom(1);
    setRotation(0);
    setImageSrc(null);
    setCroppedAreaPixels(null);
    setCroppedImage(null);

    let imageDataUrl;

    try {
      setIsReading(true);
      imageDataUrl = await readFile(file);
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsReading(false);
    }

    setImageSrc(imageDataUrl);
  };

  const onClearClick = (event) => {
    event.preventDefault();
    setImageSrc(null);
    setCroppedAreaPixels(null);
    setCroppedImage(null);
    setUploadProgress(0);
  };

  const recognizeReference = useCallback(
    async (image) => {
      const { createWorker } = Tesseract;
      const worker = await createWorker();
      await worker.loadLanguage('eng+osd');
      await worker.initialize('eng+osd');

      const result = await worker.recognize(image, {
        rotateAndDetectOrientation: true,
      }, {
        //imageColor: true,
        //imageGrey: true,
        //imageBinary: true,
      });

      const text = get(result, 'data.text', '');
      const regexQrMY = /.*(MAE QR|Scan & Pay|QRPay).*/;
      const matchQrMY = text.match(regexQrMY);

      let regexRef = null;

      if (!!matchQrMY?.length) {
        regexRef = /.*([0-9]{9}Q).*/
      }

      const words = get(result, 'data.words', []);
      let ret = null;

      for (let i = 0; i < words.length; i++) {
        const text = words[i]?.text;
        const match = text.match(regexRef);

        if (match) {
          setMatchRect(words[i]?.bbox);
          ret = match[1];
          break;
        }
      }

      return ret;
    }, []
  );

  async function handleSubmit(event) {
    event.preventDefault();
    if (status !== 'idle') return;

    if (!croppedImage) {
      setAnimateUpload(new Date());
      return;
    }

    try {
      setStatus('submitting');

      const formData = new FormData();
      const blob = await fetch(croppedImage).then(r => r.blob());
      formData.append('uri', blob);

      function handleUploadProgress(pe) {
        const p = Math.round(pe.loaded / pe.total * 100);
        setUploadProgress(p);
      }

      axiosClient.post('pot-upload-dp', formData, {
        onUploadProgress: handleUploadProgress
      }).then(async function (response) {
        try {
          const potRefId = get(response, 'data._id');
          const { amount, rewardFlag, bankId } = inputData;

          await feathers.service('deposits').create({
            potRefId,
            amount,
            bankId,
            ...(
              !!ocrRef && {
                reference: ocrRef
              }
            ),
            ...(
              !rewardFlag && {
                skipReward: true
              }
            )
          });
          setStatus('idle');
          setOpenThanksDialog(true);
        } catch (err) {
          setGlobalErrorMessage({ err });
          setStatus('idle');
        }
      }).catch(err => {
        if (err && err.message === 'Upload cancelled') {
          setStatus('idle');
        } else {
          const errData = get(err, 'response.data');
          setGlobalErrorMessage({ err: errData });
          setStatus('idle');
        }
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
      setStatus('idle');
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onConfirmCrop = useCallback(async () => {
    try {
      setIsCropping(true);
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );

      if (needOCR) {
        const result = await recognizeReference(croppedImage);
        setOcrRef(result);
      }

      setCroppedImage(croppedImage);
      setImageSrc(null);
    } catch (e) {
      console.error(e)
    } finally {
      setIsCropping(false);
    }
  }, [imageSrc, setCroppedImage, croppedAreaPixels, rotation, needOCR, recognizeReference]);

  const onCancelCrop = useCallback(() => {
    setImageSrc(null);
    setCroppedAreaPixels(null);
    setCroppedImage(null);
  }, []);

  const handleAspectRatioChanged = (ar) => (event) => {
    event.preventDefault();
    setAspectRatio(ar);
  };

  const handleNeedOCR = (event) => {
    setNeedOCR(event.target.checked);
  };

  function parse(arStr) {
    const arArr = arStr.split('/');
    if (arArr.length !== 2) return 1;
    const a1 = new Decimal(arArr[0]);
    const a2 = new Decimal(arArr[1]);
    return a1.dividedBy(a2).toNumber();
  }

  return (
    <Box>
      <ThanksDialog open={openThanksDialog} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card elevation={4}>
            <CardHeader
              title={t('Deposit')}
              action={ !!croppedImage ?
                <IconButton color='inherit' onClick={onClearClick}>
                  <CloseIcon />
                </IconButton> : null
              }
            />
            <Divider />
            <CardContent>
              {
                !croppedImage ?
                <Box textAlign='center'>
                  <label htmlFor="icon-button-file">
                    <Input onChange={onFileChange} accept="image/*" id="icon-button-file" type="file" />
                    <Button ref={buttonRef} color='info' component='span' variant="contained" startIcon={<PhotoCamera />}>
                      {t('Upload receipt')}
                    </Button>
                  </label>
                </Box> :
                <Box>
                  <ViewingArea>
                    <img src={croppedImage} alt='Cropped Receipt' />
                    {
                      !!matchRect &&
                      <Box
                        component='div'
                        sx={{
                          mt: -1,
                          position: "absolute",
                          top: `${matchRect.y0}px`,
                          left: `${matchRect.x0}px`,
                          width: `${matchRect.x1 - matchRect.x0}px`,
                          height: `${matchRect.y1 - matchRect.y0}px`,
                          border: theme => (`1px dotted ${theme.palette.error.main}`),
                        }}
                      >
                        <Typography variant='h6' sx={{
                          mt: 1.5,
                          textAlign: 'center',
                          color: 'error.main',
                          fontWeight: 700,
                        }}>
                          {ocrRef}
                        </Typography>
                      </Box>
                    }
                  </ViewingArea>
                  {
                    !!uploadProgress &&
                    <LinearProgress color='error' variant='determinate' value={uploadProgress} />
                  }
                </Box>
              }
              {
                isReading && <Box mt={2}>
                  <LinearProgress />
                </Box>
              }
            </CardContent>
            <Divider />
            <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Button size='large' variant='contained' onClick={handleSubmit}>
                {t('Submit Now')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {
          !!imageSrc &&
          <Grid item xs={12}>
            <Card>
              <CardHeader title={t('Edit')} />
              <Divider />
              <CardContent>
                <Stack direction='column' spacing={1}>
                  <CropperArea>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      aspect={parse(aspectRatio)}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onRotationChange={setRotation}
                      onZoomChange={setZoom}
                    />
                  </CropperArea>
                  <Box textAlign='center'>
                    <ButtonGroup size='small'>
                      {
                        ['4/3', '3/4', '3/2', '2/3', '16/9', '9/16', '1'].map(function(ar) {
                          return <Button color={ar === aspectRatio ? 'info' : 'secondary' } variant='contained' key={ar} onClick={handleAspectRatioChanged(ar)}>{ar}</Button>
                        })
                      }
                    </ButtonGroup>
                  </Box>
                  <Box>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <ZoomOutIcon />
                      <Slider color='info' min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)} />
                      <ZoomInIcon />
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <RotateLeftIcon />
                      <Slider color='info' min={0} max={360} step={1} value={rotation} onChange={(e, rotation) => setRotation(rotation)} />
                      <RotateRightIcon />
                    </Stack>
                    {
                      !!hasOCROption &&
                      <FormControlLabel control={<Checkbox checked={needOCR} onChange={handleNeedOCR} />} label={t('Paid using QR code')} />
                    }
                    <Stack sx={{ m: 1 }} direction='row' spacing={1}>
                      <Button autoFocus color='info' variant='contained' fullWidth onClick={onConfirmCrop} startIcon={<DoneIcon />}>{t('Done')}</Button>
                      <Button variant='contained' fullWidth color='secondary' onClick={onCancelCrop} startIcon={<CancelIcon />}>{t('Cancel')}</Button>
                    </Stack>
                  </Box>
                  <Box>
                    {
                      !!isCropping && <LinearProgress />
                    }
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        }
      </Grid>
    </Box>
  );
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  })
}
