import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

function downloadStringAsFile(data, filename) {
  let a = document.createElement('a');
  a.download = filename;
  a.href = data;
  a.click();
}

//export default function QRBackdrop(props) {
const QRBackdrop = (props) => {
  const { open, onClose, data = null, logo = null, fileName = '' } = props;
  const { t } = useTranslation();
  const canvasRef = useRef(null);

  const handleClose = (event) => {
    event?.preventDefault();
    onClose();
  };

  const handleDownload = useCallback(
    (event) => {
      event?.stopPropagation();
      const node = canvasRef?.current?.querySelector('#qrCode');

      if (node == null) {
        return;
      }

      const dataURI = node.toDataURL('image/png');
      downloadStringAsFile(dataURI, `${fileName}.png`);
    }, [canvasRef, fileName]
  );

  if (!data) return null;

  return (
    <Backdrop
      sx={{ bgcolor: 'rgba(0, 0, 0, 0.8)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <Dialog open={open}>
        <DialogContent dividers ref={canvasRef}>
          <QRCodeCanvas
            id='qrCode'
            size={240}
            value={data}
            imageSettings=  {{
              ...(logo && {
                src: logo
              }),
              x: null,
              y: null,
              height: 48,
              width: 48,
              excavate: false,
            }}
          />
        </DialogContent>
        {
          !!fileName && <DialogActions>
            <Button variant='contained' size='small' onClick={handleDownload}>
              {t('Download')}
            </Button>
          </DialogActions>
        }
      </Dialog>
    </Backdrop>
  );
}

export default QRBackdrop;

QRBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
