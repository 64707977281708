import React, { useMemo } from 'react';
import RawButton from '@mui/material/Button';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';
import { hex2rgba } from 'utils/color';

const getSize = (size) => {
  if (size === 'xsmall') return { sizeInPx: '40px', fontSize: '.9rem' };
  if (size === 'small') return { sizeInPx: '64px', fontSize: '.9rem' };
  if (size === 'large') return { sizeInPx: '80px', fontSize: '1.3rem' };
  return { sizeInPx: '72px', fontSize: '1rem' };
};

const Button = styled(RawButton, {
  shouldForwardProp: (prop) => prop !== 'size'
})(({ theme, size = 'medium' }) => {
  const sizes = getSize(size);
  const { sizeInPx, fontSize } = sizes;

  const primaryColor = hex2rgba(get(theme, 'palette.primary.main', '#FFFFFF'), 0.8);

  const paletteMode = get(theme, 'palette.mode');
  const darkMode = paletteMode === 'dark' ? true : false;

  const colors = useMemo(
    () => {
      return {
        boxShadow: {
          color1: darkMode ? '#6C6C6C' : '#FCFCFC',
          color2: darkMode ? '#464749' : '#D6D7D9',
          color3: darkMode ? '#3E3F41' : '#CECFD1',
          color4: darkMode ? '#6E6E6E' : '#FEFEFE'
        },
        backgroundImage: {
          color1: darkMode ? '#48494B' : '#D8D9DB',
          color2: darkMode ? '#464646' : '#FFF',
          color3: darkMode ? '#6D6D6D' : '#FDFDFD'
        },
        rootColor: darkMode ? '#E5E5E5' : '#606060',
        rootTextShadow: darkMode ? '#666666' : '#FFF',
        rootBorder: darkMode ? '#1C1C1D' : '#8F9092'
      }
    }, [darkMode]
  );

  const activeAnimation = useMemo(
    () => {
      return keyframes`
        from {
          box-shadow: 0 4px 3px 1px ${colors.boxShadow.color1}, 0 6px 8px ${colors.boxShadow.color2}, 0 -4px 4px ${colors.boxShadow.color3}, 0 -6px 4px ${colors.boxShadow.color4}, inset 0 0 10px 0px ${primaryColor};
        }
        to {
          box-shadow: 0 4px 3px 1px ${colors.boxShadow.color1}, 0 6px 8px ${colors.boxShadow.color2}, 0 -4px 4px ${colors.boxShadow.color3}, 0 -6px 4px ${colors.boxShadow.color4}, inset 0 0 3px 3px ${colors.boxShadow.color3};
        }
      `;
    }, [colors, primaryColor]
  );

  return {
    minWidth: sizeInPx,
    maxWidth: sizeInPx,
    minHeight: sizeInPx,
    maxHeight: sizeInPx,
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    width: sizeInPx,
    height: sizeInPx,
    backgroundImage: `linear-gradient(to top, ${colors.backgroundImage.color1} 0%, ${colors.backgroundImage.color2} 80%, ${colors.backgroundImage.color3} 100%)`,
    borderRadius: sizeInPx,
    border: `1px solid ${colors.rootBorder}`,
    boxShadow: `0 4px 3px 1px ${colors.boxShadow.color1}, 0 6px 8px ${colors.boxShadow.color2}, 0 -4px 4px ${colors.boxShadow.color3}, 0 -6px 4px ${colors.boxShadow.color4}, inset 0 0 3px 0 ${colors.boxShadow.color3}`,
    transition: 'all 0.2s ease',
    fontSize: fontSize,
    fontWeight: 700,
    color: colors.rootColor,
    textShadow: `0 1px ${colors.rootTextShadow}`,
    '&:hover:not([disabled])': {
      boxShadow: `0 4px 3px 1px ${colors.boxShadow.color1}, 0 6px 8px ${colors.boxShadow.color2}, 0 -4px 4px ${colors.boxShadow.color3}, 0 -6px 4px ${colors.boxShadow.color4}, inset 0 0 3px 3px ${colors.boxShadow.color3}`,
      '& > *': {
        transform: 'scale(0.975)',
      }
    },
    '&:focus:not(:active)': {
      animation: `${activeAnimation} 0.9s alternate infinite`,
      outline: 'none',
    },
    '&:active:not([disabled])': {
      boxShadow: `0 4px 3px 1px ${colors.boxShadow.color1}, 0 6px 8px ${colors.boxShadow.color2}, 0 -4px 4px ${colors.boxShadow.color3}, 0 -6px 4px ${colors.boxShadow.color4}, inset 0 0 5px 3px #999, inset 0 0 30px #aaa`,
      '& > *': {
        transform: 'scale(0.95)',
      }
    },
    '&:disabled': {
      opacity: 0.6,
      cursor: 'not-allowed',
    },
    '&.icon': {
      width: '50px',
      '& svg': {
        marginTop: '3px',
        width: '30px',
        height: '30px',
      }
    }
  };
});

export default function GameButton(props) {
  const { children } = props;

  return (
    <Button
      {
        ...props
      }
    >
      {
        children
      }
    </Button>
  );
}