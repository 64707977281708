import React, { useState, useMemo, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { get, endsWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import feathers from 'services/feathers';
import CommonContext from 'features/context/commonContext';
import { getBankOptions, getBankLabel } from 'utils/bank';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAuth } from 'hooks/useAuth';

function maskSensitiveContent(str) {
  if (!str) return '';
  const chunks = str.match(/.{1,3}/g);
  let i = 0;
  let maskedChunks = [];

  for (const chunk of chunks) {
    if (i === 0 || i === chunks.length - 1) maskedChunks.push(chunk);
    else maskedChunks.push('***');
    i++;
  }

  return maskedChunks.join('');
}

export default function SetBankAccountDialog(props) {
  const {  open, onClose } = props;
  const { wallet } = useContext(CommonContext);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { t } = useTranslation();
  const walletId = useMemo(() => (wallet?._id), [wallet]);
  const [ cannotSaveLastAnimated, setCannotSaveLastAnimated ] = useState(null);
  const alertRef = useRef(null);
  const { user = {} } = useAuth();
  const { country } = user;

  const savedBank = useMemo(
    () => {
      return get(wallet, 'savedBank');
    }, [wallet]
  );

  const initialValues = useMemo(
    () => {
      if (!savedBank) {
        return {
          bankName: '',
          accountName: '',
          accountNumber: '',
          bsbNumber: '',
        }
      } else {
        const bankName = get(savedBank, 'bankName', '');
        const accountName = get(savedBank, 'accountName', '');
        const rawAccountNumber = get(savedBank, 'accountNumber', '');
        const isBsbType = endsWith(bankName, '_bsb');
        const accountNumber = isBsbType ? rawAccountNumber.substring(6) : rawAccountNumber;
        const bsbNumber = isBsbType ? rawAccountNumber.substring(0, 6) : '';

        return {
          bankName,
          accountName: maskSensitiveContent(accountName),
          accountNumber: maskSensitiveContent(accountNumber),
          bsbNumber
        }
      }
    }, [savedBank]
  );

  const [ checked1, setChecked1 ] = useState(false);
  const [ checked2, setChecked2 ] = useState(false);

  useEffect(
    () => {
      setChecked1(!!savedBank);
      setChecked2(!!savedBank);
    }, [savedBank]
  );

  const canSave = useMemo(
    () => {
      return (checked1 && checked2);
    }, [checked1, checked2]
  );

  useEffect(() => {
    if (!cannotSaveLastAnimated) return;

    alertRef?.current?.animate({
      transform: ['rotate(1deg)', 'rotate(3deg)', 'rotate(-3deg)']
    }, 100);
  }, [cannotSaveLastAnimated]);

  const bankList = useMemo(
    () => {
      return getBankOptions(t, country);
    }, [t, country]
  );

  const bankSchema = Yup.object().shape({
    bankName: Yup.string().required(t('Required')),
    accountName: Yup.string().required(t('Required')).matches(/^[\u0e00-\u0e7fa-zA-Z]{2,}/, t('Min string length', { length: 2 })),
    accountNumber: Yup.string()
    .when('bankName', {
        is: (val) => (
          val?.indexOf('au_') === 0 &&
          val?.indexOf('au_payid') < 0
        ),
        then: (schema) => schema.required(t('Required')).matches(/[0-9]{6}/, t('Min digit length', { length: 6 })),
        otherwise: Yup.string().when('bankName', {
          is: (val) => (
            val?.indexOf("my_duitnow") === 0 ||
            val?.indexOf("sg_paynow") === 0 ||
            val?.indexOf("au_payid") === 0 ||
            val?.indexOf("nz_payidnz") === 0
          ),
          then: (schema) =>
            schema
              .required(t('Required'))
              .matches(/[a-zA-Z0-9@.]{6,}/, t('Min string length', { length: 6 })),
          otherwise: (schema) =>
            schema
              .required(t('Required'))
              .matches(/[0-9]{8}/, t('Min digit length', { length: 8 })),
        }),
      }),
    bsbNumber: Yup.string().matches(/^[0-9]{6}$/, t('Digit exact length', { length: 6 })).nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: bankSchema,
    onSubmit: async values => {
      try {
        await feathers.service('wallets').patch({
          _id: walletId
        }, {
          savedBank: {
            bankName: values.bankName,
            accountName: values.accountName,
            accountNumber: `${values.bsbNumber}${values.accountNumber}`
          }
        });
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
        onClose();
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    },
  });

  const selectedBankName = useMemo(
    () => {
      return get(formik, 'values.bankName', 'None');
    }, [formik]
  );

  const accountNumberLabel = useMemo(
    () => {
      return getBankLabel(selectedBankName);
    }, [selectedBankName]
  );

  const isBsbType = useMemo(
    () => {
      if (endsWith(selectedBankName, '_bsb')) return true;
      return false;
    }, [selectedBankName]
  );

  const handleCheck1Change = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheck2Change = (event) => {
    setChecked2(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formik.isSubmitting) return;

    if (!!savedBank) {
      setCannotSaveLastAnimated(new Date());
      return;
    }

    formik.submitForm();
  };

  return (
    <Dialog maxWidth='xs' onClose={onClose} open={open}>
      <DialogTitle>
        {
          t('Set Bank Account')
        }
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12}>
            {
              !!savedBank ?
                <Alert ref={alertRef} severity='info'>{t('Bank account amendment')}</Alert> :
                <Alert severity='warning'>{t('Bank account reminder')}</Alert>
            }
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              id='bankName'
              name='bankName'
              label={t('Bank Name')}
              value={formik.values.bankName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.bankName && Boolean(formik.errors.bankName)}
              helperText={formik.touched.bankName && formik.errors.bankName}
              disabled={!!savedBank}
              SelectProps={{
                native: true,
              }}
            >
              <option key='' value=''></option>
              {
                bankList
              }
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='accountName'
              name='accountName'
              label={t('Account Name')}
              value={formik.values.accountName}
              autoComplete='off'
              onBlur={formik.handleBlur}
              onChange={
                (event) => {
                  const { value = '' } = event.target;
                  formik.setFieldValue('accountName', value.toUpperCase());
                }
              }
              error={formik.touched.accountName && Boolean(formik.errors.accountName)}
              helperText={formik.touched.accountName && formik.errors.accountName}
              disabled={!!savedBank}
            />
          </Grid>
          {
            !!isBsbType && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='text'
                  inputProps={{ inputMode: 'numeric' }}
                  inputMode='numeric'
                  id='bsbNumber'
                  name='bsbNumber'
                  label={t('BSB Number')}
                  value={formik.values.bsbNumber}
                  autoComplete='off'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.bsbNumber && Boolean(formik.errors.bsbNumber)}
                  helperText={formik.touched.bsbNumber && formik.errors.bsbNumber}
                  disabled={!!savedBank}
                />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <TextField
              fullWidth
              type='text'
              {
                ...(
                  !!(accountNumberLabel === 'Account Number') && {
                    inputProps: {
                      inputMode: 'numeric'
                    },
                    inputMode: 'numeric'
                  }
                )
              }
              id='accountNumber'
              name='accountNumber'
              label={t(accountNumberLabel)}
              value={formik.values.accountNumber}
              autoComplete='off'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
              helperText={formik.touched.accountNumber && formik.errors.accountNumber}
              disabled={!!savedBank}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel disabled={!!savedBank} control={<Checkbox checked={checked1} onChange={handleCheck1Change} />} label={t('Save bank check 1')} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel disabled={!!savedBank} control={<Checkbox checked={checked2} onChange={handleCheck2Change} />} label={t('Save bank check 2')} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' variant='contained' onClick={onClose} autoFocus>
          {t('Close')}
        </Button>
        <Button disabled={!canSave} variant='contained' onClick={handleSubmit}>
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SetBankAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
