import React, { useContext, useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Button, Typography, Divider } from '@mui/material';
import { Facebook, Telegram } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CommonContext from 'features/context/commonContext';

const SocialChannels = () => {
  const { t } = useTranslation();
  const { companySetting } = useContext(CommonContext);

  const { facebookLink, telegramLink } = useMemo(() => {
    return {
      facebookLink: companySetting?.socialChannels?.facebookLink || '',
      telegramLink: companySetting?.socialChannels?.telegramLink || ''
    };
  }, [companySetting]);


  const getDescription = () => {
    if (facebookLink || telegramLink) {
      return t('Stay connected for daily tips, promotions, and updates on our social channels!');
    } else {
      return t('No social channels available.');
    }
  };

  return (
   <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Card>
        <CardHeader title={t('Social Channels')} />
        <Divider />
        <CardContent>
          <Typography sx={{ mb: 2 }} variant="body1" color="textSecondary" gutterBottom>
            {getDescription()}
          </Typography>
          {facebookLink && (
            <Box mb={2}>
              <Button
                startIcon={<Facebook />}
                variant="contained"
                sx={{
                  bgcolor: '#4267B2',
                  '&:hover': { bgcolor: '#385898' },
                  color: theme => theme.palette.getContrastText('#4267B2')
                }}
                onClick={() => {
                  // Open the Facebook link
                  window.open(facebookLink, '_blank');
                }}
              >
                {t('Like Facebook Page')}
              </Button>
            </Box>
          )}
          {telegramLink && (
            <Box>
              <Button
                startIcon={<Telegram />}
                variant="contained"
                sx={{
                  bgcolor: '#0088CC',
                  '&:hover': { bgcolor: '#0077B5' },
                  color: theme => theme.palette.getContrastText('#0088CC')
                }}
                onClick={() => {
                  // Open the Telegram link
                  window.open(telegramLink, '_blank');
                }}
              >
                {t('Join Telegram Channel')}
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default SocialChannels;
