import React, { useState, useMemo, useContext } from 'react';
import Marquee from "react-fast-marquee";
import useFeathers from 'hooks/useFeathers';
import Box from '@mui/material/Box';
import { isInWebApp } from 'utils/pwa';
import useGameLogo from 'hooks/useGameLogo';
import CompanySettingContext from 'features/context/companySettingContext';

export default function Showcase(props) {
  const [ playMarquee, setPlayMarquee ] = useState(true);
  const { getGameImagePath } = useGameLogo();
  const { ready: contextReady, companyId: guestCompanyId } = useContext(CompanySettingContext);

  const queryFetchAll = useMemo(
    () => {
      return {
        query: {
          companyId: guestCompanyId,
          $limit: -1
        }
      };
    }, [guestCompanyId]
  );

  const { data } = useFeathers('games', queryFetchAll);

  const games = useMemo(
    () => {
      return data.filter(g => !!g.isEnabled);
    }, [data]
  );

  const handleMarqueeClicked = (event) => {
    event?.preventDefault();
    setPlayMarquee(prev => (!prev));
  };

  if (!games.length || isInWebApp || !contextReady) return null;

  return (
    <Box sx={{ p: 0.5 }} onClick={handleMarqueeClicked}>
      <Marquee gradient={false}
        {
          ...{
            play: playMarquee,
            speed: 20,
            delay: 0,
            loop: 0
          }
        }
      >
        {
          games.map(function (game) {
            const { _id: key, type } = game;
            return (
              <Box sx={{ width: '3rem', mx: 1 }} key={key} component='img' src={getGameImagePath(type)} alt={`${type} logo`} />
            )
          })
        }
      </Marquee>
    </Box>
  );
}
