import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const DATE_TYPES = [
  'today',
  'yesterday',
  'last24h',
  'last3d',
  'last7d',
  'last1M'
];

export default function DateDialog(props) {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const [ value, setValue ] = useState('today');

  const handleChange = (event) => {
    event?.preventDefault();
    setValue(event.target.value);
  };

  const handleClose = (event) => {
    event?.preventDefault();
    onClose(value);
  };

  return (
    <Dialog fullWidth maxWidth='xs' onClose={handleClose} open={open}>
      <DialogTitle>
        {
          t('Date')
        }
      </DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ m: 2 }}>
          <RadioGroup
            name='date'
            value={value}
            onChange={handleChange}
          >
            {
              DATE_TYPES.map(function(dateLabel) {
                return (<FormControlLabel key={dateLabel} value={dateLabel} control={<Radio color='info' />} label={t(dateLabel)} />)
              })
            }

          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' autoFocus onClick={handleClose}>
          {t('Apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
