import React, { useMemo, useCallback } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {
  Badge,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { get } from 'lodash';
import { useUserConfig } from 'hooks/useUserConfig';
import { useLocation } from "react-router-dom";
import BalanceBarCompact from './BalanceBarCompact';
import { useTranslation } from 'react-i18next';

import DarkModeIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeIcon from '@mui/icons-material/LightModeTwoTone';
import ShareIcon from '@mui/icons-material/ShareTwoTone';
import ChatIcon from '@mui/icons-material/ChatBubbleTwoTone';

import { useChats } from 'hooks/useChats';

export default function ExtraNavigation(props) {
  const { onItemClicked } = props;
  const location = useLocation();
  const { darkMode, toggleDarkMode, compactAppBar } = useUserConfig();
  const { t } = useTranslation();
  const { selectedChatUnreadCount = 0 } = useChats();

  const pathName = useMemo(
    () => {
      return get(location, 'pathname');
    }, [location]
  );

  const handleDarkModeToggle = useCallback(
    (event) => {
      event?.preventDefault();
      toggleDarkMode();
    }, [toggleDarkMode]
  );

  return (
    <>
      <Stack direction='row' spacing={1}>
        {
          !!compactAppBar ?
          <>
            <Tooltip title={t('Share')}>
              <IconButton color={pathName === '/referral' ? 'secondary' : 'inherit'} variant='contained' size='small' onClick={onItemClicked('referral')}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Chat')}>
              <IconButton color='inherit' variant='contained' size='small' onClick={onItemClicked('chat')}>
                <ChatIcon />
              </IconButton>
            </Tooltip>
          </> :
          <>
            <Button startIcon={<ShareIcon />} variant='contained' size='small' onClick={onItemClicked('referral')}>
              {t('Share')}
            </Button>
            <Button startIcon={
              <Badge badgeContent={selectedChatUnreadCount} color='error'>
                <ChatIcon />
              </Badge>
            } variant='contained' size='small' onClick={onItemClicked('chat')}>
              {t('Chat')}
            </Button>

          </>
        }
        <Tooltip title={t(darkMode ? 'Switch to light mode' : 'Switch to dark mode')}>
          <IconButton color='inherit' variant='contained' size='small' onClick={handleDarkModeToggle}>
          {
            darkMode ? <LightModeIcon /> : <DarkModeIcon />
          }
          </IconButton>
        </Tooltip>
      </Stack>
      {
        !!compactAppBar && <BalanceBarCompact />
      }
    </>
  );
}
