import React, { useState, useContext, createContext } from 'react';
import feathers from 'services/feathers';
import ability from 'casl/ability';
import { get } from 'lodash';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [isIdle, setIsIdle] = useState(true);
  const [user, setUser] = useState(null);

  const login = async (data) => {
    try {

      const { token, countryCode, username, password, tokenMode = true, companyId } = data;

      if (!token && !username && !password) throw new Error('Invalid credentials');
      if (!companyId) throw new Error('Invalid company id');

      const payload = tokenMode ? {
        strategy: 'token',
        token
      } : {
        strategy: 'local',
        username: `${countryCode}${username}@${companyId}`,
        password,
      };

      setIsIdle(false);
      const res = await feathers.authenticate(payload);
      const u = get(res, 'user', null);
      const rules = get(res, 'rules', []);
      const role = get(res, 'user.role', 'user');
      if (role !== 'user') throw new Error('Forbidden');
      setUser(u);
      ability.update(rules);
    } catch (err) {
      setUser(null);
      ability.update([]);
      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const relogin = async () => {
    try {
      setIsIdle(false);
      const res = await feathers.reAuthenticate();
      const u = get(res, 'user', null);
      const rules = get(res, 'rules', []);
      setUser(u);
      ability.update(rules);
    } catch (err) {
      setUser(null);
      ability.update([]);
      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const logout = async () => {
    try {
      setIsIdle(false);
      await feathers.logout();
      await feathers.io.close();
      await feathers.io.open();
      setUser(null);
      ability.update([]);
      localStorage.clear();
    } catch (err) {
      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const update = (user) => {
    setUser(user);
  };

  return {
    isIdle,
    user,
    login,
    relogin,
    logout,
    update,
  };
}
