import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Divider, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RecentPayoutsCard = ({ recentPayouts }) => {
  const { t } = useTranslation();
  const [emojiCount, setEmojiCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setEmojiCount(count => (count + 1) % 4);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!recentPayouts || recentPayouts.length === 0) {
    return null;
  }

  const getEmoji = (count) => {
    if (count === 1) {
      return '💰';
    } else if (count === 2) {
      return '💰💰';
    } else if (count === 3) {
      return '💰💰💰';
    } else {
      return '';
    }
  };

  const emoji = getEmoji(emojiCount);

  if (!recentPayouts || recentPayouts.length === 0) {
    return null;
  }

  const highestPayoutAmount = Math.max(...recentPayouts.map(payout => parseFloat(payout.amount.$numberDecimal)));
  const fireEmoji = '🔥';

  return (
    <Card elevation={4}>
      <CardHeader
        titleTypographyProps={{ variant: 'body1' }}
        title={
          <Box>
          {t('Recent Payouts')}
          <Box sx={{ ml: 1, fontSize: '0.8rem' }} component='span'>{emoji}</Box>
          </Box>
        }
      />
      <Divider />
      <CardContent sx={{ textAlign: 'center' }}>
        {recentPayouts.map((payout, index) => {
          const isHighestPayout = payout.amount.$numberDecimal === highestPayoutAmount.toString();
          return (
            <Typography
              gutterBottom
              key={index}
              variant="subtitle1"
              sx={{
                fontStyle: 'oblique',
                fontWeight: isHighestPayout ? 'bold' : 'normal',
              }}
            >
              {
                isHighestPayout ? (
                  <span>
                    {fireEmoji}
                    {' '}
                    {payout.gameName}
                    {' '}
                    {fireEmoji}
                  </span>
                ) : (
                  payout.gameName
                )
              }
            </Typography>
          )
        })}
      </CardContent>
    </Card>
  );
};

export default RecentPayoutsCard;
