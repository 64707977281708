const lookups = {
  "en": "English",
  "ms": "Melayu",
  "zh": "中文",
  "th": "ไทย",
};

const enums = Object.keys(lookups);

export {
  lookups,
  enums,
};
