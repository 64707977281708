import { useCallback, useEffect, useState, useMemo } from 'react';
import feathers from 'services/feathers';
import dayjs from 'dayjs';

export default function useFeathers(serviceName, params = {}, options = {}) {
  const service = feathers.service(`/${serviceName}`);
  const [ result, setResult ] = useState({
    total: 0,
    limit: 0,
    skip: 0,
    data: []
  });
  const [ isFetching, setIsFetching ] = useState(false);
  const [ error, setError ] = useState(null);

  const paramsStr = useMemo(
    () => {
      if (!params || typeof params !== 'object') return '';

      try {
        const p = JSON.stringify(params);
        return p;
      } catch (err) {
        return '';
      }
    }, [params]
  );

  const { liveUpdatePredicate, appendNewDataAtTop = false } = options;

  const predicateCallback = useCallback(
    (data) => {
      if (liveUpdatePredicate === undefined) return true;
      return liveUpdatePredicate(data);
    },
    [liveUpdatePredicate],
  );

  const onChangedCallback = useCallback(
    (patchedData) => {
      if (!predicateCallback(patchedData)) return;

      const { _id: itemId } = patchedData;

      // update data in result.data
      setResult((prevState) => {
        return {
          ...prevState,
          data: prevState.data.map(d => {
            if (d._id !== itemId) return d;

            const { updatedAt: prevUpdatedAt } = d;
            const { updatedAt: currUpdatedAt } = patchedData;

            if (dayjs(currUpdatedAt).isBefore(dayjs(prevUpdatedAt))) return d;
            return patchedData;
          })
        };
      });
    },
    [predicateCallback]
  );

  const onAddedCallback = useCallback(
    (newData) => {
      if (!predicateCallback(newData)) return;

      if (appendNewDataAtTop) {
        setResult((prevState) => {
          return {
            ...prevState,
            data: [newData, ...prevState.data]
          };
        });
      } else {
        setResult((prevState) => {
          return {
            ...prevState,
            data: [...prevState.data, newData]
          };
        });
      }
    },
    [predicateCallback, appendNewDataAtTop]
  );

  const onDeletedCallback = useCallback(
    (deletedData) => {
      if (!predicateCallback(deletedData)) return;

      const { _id: itemId } = deletedData;

      setResult((prevState) => {
        return {
          ...prevState,
          data: prevState.data.filter(d =>
            d._id !== itemId
          )
        };
      });
    },
    [predicateCallback]
  );

  useEffect(() => {
    if (!paramsStr) return;

    let isMounted = true;

    async function fetch () {
      try {
        setIsFetching(true);
        const p = JSON.parse(paramsStr);
        const fetchResult = await service.find(p);
        if (isMounted) setResult(fetchResult);
      } catch (err) {
        if (isMounted) setError(err);
      } finally {
        if (isMounted) setIsFetching(false);
      }
    };

    fetch();
    return () => {
      isMounted = false;
    };
  }, [paramsStr, service]);

  useEffect(() => {
    service.on('created', onAddedCallback);
    service.on('removed', onDeletedCallback);
    service.on('updated', onChangedCallback);
    service.on('patched', onChangedCallback);

    return () => {
      service.removeListener('created', onAddedCallback);
      service.removeListener('removed', onDeletedCallback);
      service.removeListener('updated', onChangedCallback);
      service.removeListener('patched', onChangedCallback);
    };
  }, [service, onAddedCallback, onChangedCallback, onDeletedCallback]);

  return {
    result,
    isFetching,
    error,
  };
}
