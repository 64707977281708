import React, { useContext, useState, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import WinnersPreviewDialog from './WinnersPreviewDialog';
import ParticipantsPreviewDialog from './ParticipantsPreviewDialog';
import LuckyDrawCard from './LuckyDrawCard.js';
import { useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import {
  Badge,
  Button,
  ButtonGroup,
} from '@mui/material';

export default function LuckyDraw(props) {
  const { t } = useTranslation();
  const { luckyDraws, luckyDrawsReady, hasEligibleLuckyDraw } = useContext(CommonContext);
  const [ winnersPreview, setWinnersPreview ] = useState(null);
  const [ participantsPreview, setParticipantsPreview ] = useState(null);
  const navigate = useNavigate();

  const query = useQuery();

  const filter = useMemo(
    () => {
      const filter = query.get('filter') || 'all';
      return filter;
    }, [query]
  );

  const filterLuckyDraws = useMemo(
    () => {
      switch (filter) {
        case 'all':
          return luckyDraws.filter((luckyDraw) => !luckyDraw.isEnded);

        case 'vip':
            return luckyDraws.filter((luckyDraw) => luckyDraw.isVIPReward);

        case 'eligible':
          return luckyDraws.filter((luckyDraw) => luckyDraw.isEligible);

        case 'ended':
          return luckyDraws.filter((luckyDraw) => luckyDraw.isEnded);

        default:
          return luckyDraws;
      }
    }, [filter, luckyDraws]
  );

  const handleFilterPressed = useCallback(
    (type) => (event) => {
      event?.preventDefault();
      navigate(`/free?filter=${type}`);
    }, [navigate]
  );

  const handleWinnersPreview = (luckyDrawId) => {
    setWinnersPreview(luckyDrawId);
  };

  const handleParticipantsPreview = (luckyDrawId) => {
    setParticipantsPreview(luckyDrawId);
  };

  function handleWinnersPreviewClose(event) {
    event.preventDefault();
    setWinnersPreview(null);
  }

  function handleParticipantsPreviewClose(event) {
    event.preventDefault();
    setParticipantsPreview(null);
  }

  if (!luckyDrawsReady) {
    return (
      <Loader open={true} />
    );
  }

  return (
    <Box
      sx={{
        maxWidth: {
          'sm': 'sm',
          'md': 'xl',
        },
        mx: 'auto'
      }}
    >
      <WinnersPreviewDialog onClose={handleWinnersPreviewClose} luckyDrawId={winnersPreview} />
      <ParticipantsPreviewDialog onClose={handleParticipantsPreviewClose} luckyDrawId={participantsPreview} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <ButtonGroup
          variant="contained"
          color="primary"
          size='small'
        >
          <Button
            onClick={handleFilterPressed('all')}
            sx={{
              fontWeight: filter === 'all' ? 'bold' : 'normal'
            }}
          >
            {t('All')}
          </Button>
          <Button
            onClick={handleFilterPressed('vip')}
            sx={{
              fontWeight: filter === 'vip' ? 'bold' : 'normal'
            }}
          >
            {t('VIP')}
          </Button>
          <Button
            onClick={handleFilterPressed('eligible')}
            sx={{
              fontWeight: filter === 'eligible' ? 'bold' : 'normal'
            }}
          >
            {
              hasEligibleLuckyDraw ?
                <Badge
                  color="error"
                  variant='dot'
                >
                  {t('Eligible')}
                </Badge> :
                t('Eligible')
            }
          </Button>
          <Button
            onClick={handleFilterPressed('ended')}
            sx={{
              fontWeight: filter === 'ended' ? 'bold' : 'normal'
            }}
          >
            {t('Ended')}
          </Button>
        </ButtonGroup>
      </Box>
      {
        !!filterLuckyDraws.length &&
        <Grid container spacing={2}>
          {
            filterLuckyDraws.map((r) => {
              return (
                <Grid item xs={12} sm={6} key={r._id}>
                  <LuckyDrawCard
                    luckyDraw={r}
                    onWinnersPreview={handleWinnersPreview}
                    onParticipantsPreview={handleParticipantsPreview}
                  />
                </Grid>
              );
            })
          }
        </Grid>
      }
      {
        !filterLuckyDraws.length &&
        <Paper elevation={0} sx={{ mt: 2 }}>
          <Alert severity="info">{t('No record')}</Alert>
        </Paper>
      }
    </Box>
  );
}
