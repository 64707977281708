//https://blog.wick.technology/pwa-install-prompt/
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useIosPrompt from './useIosPrompt';
import useWebPrompt from './useWebPrompt';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function PassivePwaPrompt(props) {
  const { open, onClose, onReady } = props;
  const [ iosInstallPrompt, /* handleIosInstallDeclined */ ] = useIosPrompt();
  const [ webInstallPrompt, /* handleWebInstallDeclined */, handleWebInstallAccepted ] = useWebPrompt();
  const { t } = useTranslation();

  useEffect(
    () => {
      const isReady = (iosInstallPrompt || webInstallPrompt) ? true : false;
      onReady(isReady);
    }, [onReady, iosInstallPrompt, webInstallPrompt]
  );

  const generateDialogContent = useCallback(
    () => {
      if (iosInstallPrompt) return (
        <Typography component='span'>
          {
            t(`Click`)
          }
          <Box sx={{ mx: 1, width: '1.5rem' }} component='img' alt="Action Button" src="/images/ios/action_button.png" />
          {
            t(`PWA Prompt Ios`)
          }
        </Typography>
      )

      if (webInstallPrompt) return (
        <Typography component='span'>
          {
            t(`PWA Prompt Web`)
          }
        </Typography>
      );

      return null;
    }, [iosInstallPrompt, webInstallPrompt, t]
  );

  if (!iosInstallPrompt && !webInstallPrompt) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t(`PWA Prompt Title`)}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {
            generateDialogContent()
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          iosInstallPrompt ?
          <Button variant='contained' onClick={onClose} autoFocus>
            { t(`Ok`) }
          </Button> :
          <>
            <Button
              color='secondary'
              variant='contained'
              onClick={onClose}
            >
              { t(`No`) }
            </Button>
            <Button variant='contained' onClick={handleWebInstallAccepted} autoFocus>
              { t(`Ok`) }
            </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
}

PassivePwaPrompt.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReady: PropTypes.func.isRequired,
};