import React, { useEffect, useState, useCallback, useMemo } from 'react';
import feathers from 'services/feathers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function GameWithCaptcha(props) {
  const { captchaPattern } = props;
  const { t } = useTranslation();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [captchaRequest, setCaptchaRequest] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const CaptchaSchema = Yup.object().shape({
    captchaAnswer: Yup.string().required(t('Required')).matches(captchaPattern, t("Invalid captcha pattern"))
  });

  const captchInputMode = useMemo(() => {
    if (!captchaPattern) return 'numeric';
    const pattern = captchaPattern.toString();

    const alphaPattern = pattern.match(/[a-zA-Z]/g);

    if (!alphaPattern) return 'numeric';

    return 'text';
  }, [captchaPattern]);

  const formik = useFormik({
    initialValues: {
      captchaAnswer: '',
    },
    validationSchema: CaptchaSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsSubmitting(true);
        await feathers.service('/captcha').update(null, {
          ...values,
          ...captchaRequest
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        setCaptchaRequest(null);
        resetForm();
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    setOpen(!!captchaRequest);
  }, [captchaRequest]);

  useEffect(() => {
    const service = feathers.service('/captcha');

    const onRequest = (data) => {
      setCaptchaRequest(data);
    };

    const onResponse = () => {
      setCaptchaRequest(null);
      formik.resetForm();
    };

    service.on('request', onRequest);
    service.on('response', onResponse);

    return () => {
       service.removeListener('request', onRequest);
       service.removeListener('response', onResponse);
    };
  }, [formik]);

  useEffect(() => {
    if (!captchaRequest) return;
    const cleaner = setTimeout(() => {
      setCaptchaRequest(null);
    }, 15 * 1000);

    return () => {
      clearTimeout(cleaner);
    };

  }, [captchaRequest]);

  const handleReset = useCallback(
    () => {
      formik.resetForm();
    }, [formik]
  );

  const handleClose = () => {
    setCaptchaRequest(null);
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    formik.submitForm();
  };

  if (!captchaRequest) return null;

  return (
    <Dialog fullWidth={true} maxWidth='xs' open={open} onClose={handleClose}>
      <form onSubmit={ event => event.preventDefault() } autoComplete='off' noValidate>
        <DialogTitle>{t("Kindly answer the captcha")}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={8} sx={{ p: 1 }}>
                <Box
                  sx={{
                    width: '100%'
                  }}
                  component='img'
                  alt={`Captcha for ${captchaRequest ? captchaRequest.type : ''}`}
                  src={`data:image/png;base64,${captchaRequest ? captchaRequest.captcha : null}`}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                inputProps={{ inputMode: captchInputMode }}
                inputMode={captchInputMode}
                id="captchaAnswer"
                name="captchaAnswer"
                label={t('Answer')}
                value={formik.values.captchaAnswer}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.captchaAnswer && Boolean(formik.errors.captchaAnswer)}
                helperText={formik.touched.captchaAnswer && formik.errors.captchaAnswer}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleReset}
          >
            {t('Clear')}
          </Button>
          <Button
            disabled={isSubmitting}
            type='submit'
            size="large"
            variant="contained"
            onClick={handleSubmit}
          >
            {t('Submit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
