import { createContext } from 'react';

const SupportContext = createContext({
  waBots: [],
  waBotsReady: false,
  tgBots: [],
  tgBotsReady: false,
  lineBots: [],
  lineBotsReady: false,
  customerServices: [],
  customerServicesReady: false,
  ready: false,
});

export default SupportContext;
