import { createContext } from 'react';

const NewsContext = createContext({
  data: [],
  allTags: [],
  total: 0,
  totalPage: 0,
  page: 0,
  isFetching: false,
  errorMessage: '',
  gotoPage: () => {},
  setFilter: () => {},
  setSort: () => {},
});

export default NewsContext;
