import ContentContext from './supportContext';
import React, { useMemo, useContext } from 'react';
import useFeathers from 'hooks/useFeathers';
import CompanySettingContext from 'features/context/companySettingContext';

function SupportProvider({ children }) {
  const { companyId: guestCompanyId } = useContext(CompanySettingContext);

  const queryFetchAll = useMemo(
    () => {
      return {
        query: {
          ...(guestCompanyId && {
            companyId: guestCompanyId
          }),
          $limit: -1
        }
      };
    }, [guestCompanyId]
  );

  const { data: waBots, ready: waBotsReady } = useFeathers('whatsapp-bots', queryFetchAll);
  const { data: tgBots, ready: tgBotsReady } = useFeathers('telegram-bots', queryFetchAll);
  const { data: lineBots, ready: lineBotsReady } = useFeathers('line-bots', queryFetchAll);
  const { data: customerServices, ready: customerServicesReady } = useFeathers('customer-services', queryFetchAll);

  return (
    <ContentContext.Provider value={{
      waBots, // no live event
      waBotsReady,
      tgBots, // no live event
      tgBotsReady,
      lineBots, // no live event
      lineBotsReady,
      customerServices,
      customerServicesReady,
      ready: (waBotsReady && tgBotsReady && customerServicesReady) ? true : false
    }}>
      {children}
    </ContentContext.Provider>
  );
}

export default SupportProvider;
