import { useEffect, useState } from 'react';
import axiosCache from 'services/axios-cache';
import { Buffer } from 'buffer';

const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

function useImageDownloader (options = {}) {
  const {
    serviceName = 'images',
    id: resourceId,
    variant = 'thumbnail'
  } = options;

  const [status, setStatus] = useState('idle');
  const [image, setImage] = useState(EMPTY_IMAGE);

  useEffect (() => {
    if (!resourceId) return;
    let isMounted = true;
    async function fetchData () {
      setStatus('fetching');
      try {
        const axiosImage = await axiosCache.get(`/${serviceName}/${resourceId}`, {
          responseType: 'arraybuffer',
          params: {
            download: 1,
            variant
          },
          transformResponse: (data) => {
            return Buffer.from(data, 'binary').toString('base64');
          }
        });

        const { data: dataBase64, headers } = axiosImage;
        const { 'content-type': mimeType } = headers;
        const imageData = `data:${mimeType};base64,${dataBase64}`;

        if (isMounted) {
          setImage(imageData);
          setStatus('idle');
        }
        //await new Promise(r => setTimeout(r, 3000));
      } catch (err) {
        console.error(`Error getting image: ${resourceId} - ${err}`);
        if (!isMounted) return;
        setImage(EMPTY_IMAGE);
        setStatus('idle');
      };
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [resourceId, variant, serviceName]);

  return { image, imageReady: status === 'idle' };
}

export default useImageDownloader;
