import { get, map, includes, ceil, reduce, filter, orderBy, uniqBy } from 'lodash';
import Decimal from 'decimal.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const SUPPORTED_STATES = [
  'enrollment',
  'done',
];

export function logicizeLuckyDraws(luckyDraws, userData, lang) {
  const filtered = filter(uniqBy(luckyDraws, '_id'), function(luckyDraw) {
    const { state, isArchived = false } = luckyDraw;
    if (!SUPPORTED_STATES.includes(state) || isArchived) return false;
    return true;
  });

  const sorted = orderBy(filtered, [
    function(o) {
      const { state } = o;
      return SUPPORTED_STATES.indexOf(state);
    },
    'weight',
    function(o) {
      return dayjs(o.endsAt).unix();
    }
  ], ['asc', 'asc', 'asc']);

  const ret = map(sorted, function(luckyDraw) {
    const _id = get(luckyDraw, '_id');
    const name = get(luckyDraw, `name.${lang}`, '');
    const photo = get(luckyDraw, `photo.${lang}`, '');
    const decPrizeAmount = new Decimal(get(luckyDraw, 'prizeAmount.$numberDecimal', '0'));
    const decRequirementAmount = new Decimal(get(luckyDraw, 'requirementAmount.$numberDecimal', '0'));
    const requirementType = get(luckyDraw, 'requirementType', 'deposit');
    const djsStartsAt = dayjs(get(luckyDraw, 'startsAt'));
    const djsEndsAt = dayjs(get(luckyDraw, 'endsAt'));
    const requirementLevelMin = get(luckyDraw, 'requirementLevel.min');
    const requirementLevelMax = get(luckyDraw, 'requirementLevel.max');
    const requirementRanks = get(luckyDraw, 'requirementRanks', []);
    const isVIPReward = get(luckyDraw, 'isVIPReward', false);

    const recentEnroll = get(userData, 'luckyDraw.recentEnroll', []);
    const recentWin = get(userData, 'luckyDraw.recentWin', []);
    const isEnrolled = includes(recentEnroll, _id);
    const [ isWon, wonIndex, wonAmount ] = checkIsWon(recentWin, _id);
    const state = get(luckyDraw, 'state', '');
    const isEnded = (state === 'enrollment') ? false : true;
    const participants = get(luckyDraw, 'participants', []);
    const participantCount = get(luckyDraw, 'participantCount', 0);
    const participantsPerPrize = get(luckyDraw, 'participantsPerPrize', 0);
    const prizeCount = ceil(participantCount/ participantsPerPrize);
    const winners = get(luckyDraw, 'winners', []);
    const winnerCount = winners.length;
    const prizes = get(luckyDraw, 'prizes', []);
    const prizeDistribution = get(luckyDraw, 'prizeDistribution', 'fixed');
    const decTotalFreePoint = decPrizeAmount.times(prizeCount);
    const timezone = get(userData, 'timezone', 'UTC');

    const recentUnit = getRecentUnit(djsStartsAt, djsEndsAt);
    let allRecentAmount;

    if (requirementType === 'turnover') {
      allRecentAmount = get(userData, `recentTurnover.${recentUnit}`, []);
    } else {
      allRecentAmount = get(userData, `recentCashIn.${recentUnit}`, []);
    }

    const recentAmount = calRecentAmount(allRecentAmount, djsStartsAt, djsEndsAt, timezone);
    const decRecentAmount = new Decimal(recentAmount);
    const decRequiredAmount = decRequirementAmount.minus(decRecentAmount);

    let isEligible = false;

    if (includes(['deposit', 'turnover'], requirementType)) {
      isEligible = decRecentAmount.gte(decRequirementAmount) ? true : false;
    } else if (requirementType === 'level') {
      isEligible = checkLevel(luckyDraw, userData);
    } else if (requirementType === 'rank') {
      isEligible = checkRank(luckyDraw, userData);
    }

    return {
      _id,
      name,
      photo,
      decRequirementAmount,
      requirementType,
      requirementLevelMin,
      requirementLevelMax,
      requirementRanks,
      djsStartsAt,
      djsEndsAt,
      isEnrolled,
      isWon,
      wonIndex,
      wonAmount,
      state,
      isEnded,
      participants,
      participantCount,
      participantsPerPrize,
      prizeCount,
      winners,
      winnerCount,
      prizes,
      prizeDistribution,
      //recentUnit, // use at progress bar
      decRecentAmount, // use at card
      isEligible,
      decTotalFreePoint,
      decRequiredAmount,
      decPrizeAmount,
      isVIPReward,
    }
  });
  return ret;
}

function checkIsWon(recentWin, luckyDrawId) {
  for (let i = 0; i < recentWin.length; i++) {
    const data = recentWin[i].split('|');
    const id = get(data, '[0]');
    const index = get(data, '[1]');
    const prize = get(data, '[2]');

    if (id === luckyDrawId) {
      return [ true, index, prize ];
    }
  }

  return [ false ];
}

function checkLevel(luckyDraw, userData) {
  const minLevel = get(luckyDraw, 'requirementLevel.min', 9999999999);
  const maxLevel = get(luckyDraw, 'requirementLevel.max', 9999999999);

  const userLevel = get(userData, 'level');

  const isIgnoreMin = minLevel === -1 ? true : false;
  const isIgnoreMax = maxLevel === -1 ? true : false;

  if (isIgnoreMin && isIgnoreMax) return false;

  const minLevelPassed = (isIgnoreMin || (userLevel >= minLevel));
  const maxLevelPassed = (isIgnoreMax || (userLevel <= maxLevel));

  return minLevelPassed && maxLevelPassed;
}

function checkRank(luckyDraw, userData) {
  const userRank = get(userData, 'tierId');
  const requirementRanks = get(luckyDraw, 'requirementRanks', []);

  const findRankIndex = requirementRanks.indexOf(userRank);

  if (findRankIndex >= 0) return true;
  return false;
}

function getRecentUnit(djsStartsAt, djsEndsAt) {
  if (djsEndsAt.diff(djsStartsAt, 'm') <= 60) return 'minute';
  if (djsEndsAt.diff(djsStartsAt, 'h') <= 24) return 'hour';
  if (djsEndsAt.diff(djsStartsAt, 'd') <= 30) return 'day';
  if (djsEndsAt.diff(djsStartsAt, 'w') <= 12) return 'week';
  if (djsEndsAt.diff(djsStartsAt, 'M') <= 3) return 'month';
  return 'month';
}

function calRecentAmount(recentCashIn, djsStartsAt, djsEndsAt, timezone) {
  const cashInSum = reduce(recentCashIn, function(sum, n) {
    const date = get(n, 'date');
    if (date === undefined) return sum;

    const decAmount = new Decimal(get(n, 'amount.$numberDecimal', '0'));
    const adjustedDate = dayjs(date).utc().tz(timezone, true);

    const isQualified = adjustedDate.isBetween(djsStartsAt, djsEndsAt, null, '[)');
    if (!isQualified) return sum;
    return new Decimal(sum).plus(decAmount).toNumber();
  }, 0);

  return cashInSum;
}
