import { get, camelCase, groupBy } from 'lodash';
import { isBetweenHour } from './dt';
import { lookups as lookupBanks, getLookupsByCountry } from 'lookups/banks';

// id type account number
const _ID_PATTERN = /^(my_duitnow_|sg_paynow_|au_payid_|nz_payidnz_)/g;

export function checkIsBankIdType(bankName) {
  return !!_ID_PATTERN.test(bankName);
}

export function getFullBankLabel(t, bank) {
  const bankName = get(bank, 'bankName', '');
  const displayNumber = get(bank, 'displayNumber',)
  const matches = bankName.match(_ID_PATTERN);
  let label = get(lookupBanks, bankName, '');

  if (!matches) return label;

  const type = camelCase(matches[0]);
  label = bankName.replace(_ID_PATTERN, 'pay_');

  if (label === 'pay_bank') {
    return `${t(type)} - ${t(label)} - ${displayNumber}`;
  }

  return `${t(type)} - ${t(label)}`;
}

export function getBankLabel(bankName) {
  const isIdPattern = _ID_PATTERN.test(bankName);
  if (!isIdPattern) return 'Account Number';
  return 'pay_' + bankName.replace(_ID_PATTERN, '');
}

export function getBankOptions(t, country) {
  let mappedBankList = [];

  const targetLookupBanks = getLookupsByCountry(country);

  Object.keys(targetLookupBanks).forEach((key, index) => {
    const matches = key.match(_ID_PATTERN);
    let type = 'bank';
    let label = targetLookupBanks[key];

    if (matches) {
      type = camelCase(matches[0]);
      label = key.replace(_ID_PATTERN, 'pay_');
    }

    if (label === 'pay_bank') return;

    mappedBankList.push({
      type,
      key,
      label
    });
  });

  const groupByType = groupBy(mappedBankList, 'type');

  let ret = [];

  Object.keys(groupByType).forEach((key, index) => {
    ret.push(
      <optgroup key={key} label={t(key)}>
        {
          groupByType[key].map(function (item) {
            const label = item.type === 'bank' ? item.label : t(item.label);
            return (
              <option key={item.key} value={item.key}>{label}</option>
            );
          })
        }
      </optgroup>
    );
  });

  return ret;
}

export function getCompanyBankOptions(t, banks) {
  const mappedBankList = banks.map(function (bank) {
    const { bankName = '', name = '' } = bank;
    const matchesPayId = bankName.match(_ID_PATTERN);

    const dailyMaintenance = get(bank, 'dailyMaintenance');
    const { startsAtTime, endsAtTime } = dailyMaintenance || {};
    let isDailyMaintenance = isBetweenHour(startsAtTime, endsAtTime);

    let type, label;
    if (matchesPayId) {
      type = camelCase(matchesPayId[0]);
      const payIdLabel = bankName.replace(_ID_PATTERN, 'pay_');
      label = `${t(payIdLabel)} - ${name}`;
    } else {
      type = 'bank';
      const bankNameLabel = lookupBanks[bankName];
      label = `${bankNameLabel} - ${name}`;
    }

    label = isDailyMaintenance ? `🛠️ ${label}` : label;

    return {
      ...bank,
      type,
      label,
      isDailyMaintenance,
    }
  });

  const groupByType = groupBy(mappedBankList, 'type');

  let ret = [];
  Object.keys(groupByType).forEach((key, index) => {
    ret.push(
      <optgroup key={key} label={t(key)}>
        {
          groupByType[key].map(function (item) {
            return (
              <option disabled={item.isDailyMaintenance} key={item._id} value={item._id}>{item.label}</option>
            );
          })
        }
      </optgroup>
    );
  });

  return ret;
}
