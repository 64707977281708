import React, { useContext, useMemo } from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import CompanySettingContext from 'features/context/companySettingContext';

export default function Copyright(props) {
  const { t } = useTranslation();
  const {
    pageConfig = {},
    ready,
  } = useContext(CompanySettingContext);

  const homeUrl = useMemo(() => {
    if (!ready) return '#';
    return pageConfig?.homeUrl || '#';
  }, [pageConfig, ready]);

  const homeAnchorText = useMemo(() => {
    if (!ready) return '';
    return pageConfig?.homeAnchorText || '';
  }, [pageConfig, ready]);

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('Copyright © ')}
      <Link color="inherit" href={homeUrl}>
        {homeAnchorText}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
