import ContentContext from './themeContext';
import feathers from 'services/feathers';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import CompanySettingContext from 'features/context/companySettingContext';
import { useAuth } from 'hooks/useAuth';

function ThemeProvider({ children }) {
  const { user, isIdle: authIdle } = useAuth();
  const { pageConfig, ready: companySettingReady } = useContext(CompanySettingContext);
  const [ theme, setTheme ] = useState(null);
  const [ lastThemeName, setLastThemeName ] = useState('');
  const [ fontImportUrls, setFontImportUrls ] = useState([]);
  const [ status, setStatus ] = useState('idle');

  const { defaultDarkThemeName, defaultThemeName } = pageConfig;

  const isAuthed = useMemo(
    () => {
      return user !== null;
    }, [user]
  );

  const darkModeFromUser = useMemo(
    () => {
      if (!user) return false;
      return user?.settings?.darkMode || false;
    }, [user]
  );

  const themeNameFromUser = useMemo(
    () => {
      if (!user) return '';
      return user?.settings?.themeName || '';
    }, [user]
  );

  useEffect(
    () => {
      if (!companySettingReady || !authIdle) {
        return;
      }

      let name;

      if (isAuthed) {
        if (darkModeFromUser) {
          name = defaultDarkThemeName;
        } else {
          name = themeNameFromUser || defaultThemeName;
        }
      } else {
        const url = new URL(window.location.href);
        const darkModeFromQuery = url.searchParams.get('darkMode') === 'true';
        const themeNameFromQuery = url.searchParams.get('themeName');

        if (darkModeFromQuery) {
          name = defaultDarkThemeName;
        } else {
          name = themeNameFromQuery || defaultThemeName;
        }
      }

      if (!name || lastThemeName === name) {
        return;
      }

      const fetchTheme = async () => {
        try {
          setLastThemeName(name);
          setStatus('loading');
          const fetchedTheme = await feathers.service('theme').get(name);
          const { data: themeDataString, fontImportUrls = [], gameButtonVariant, luckyWheelConfig, forDarkMode = false } = fetchedTheme;
          const themeData = JSON.parse(themeDataString);

          setTheme({
            ...themeData,
            custom: {
              gameButtonVariant,
              luckyWheelConfig,
              forDarkMode,
            },
          });
          setFontImportUrls(fontImportUrls);
        } catch (error) {
          console.error('Error fetching theme', error);
        } finally {
          setStatus('idle');
        }
      };

      fetchTheme();

    }, [authIdle, isAuthed, darkModeFromUser, themeNameFromUser, defaultDarkThemeName, defaultThemeName, companySettingReady, lastThemeName]
  );

  return (
    <ContentContext.Provider value={{
      theme,
      fontImportUrls,
      ready: status === 'idle',
    }}>
      {children}
    </ContentContext.Provider>
  );
}

export default ThemeProvider;
