import React, { useCallback, createContext, useContext, useMemo } from 'react';
import { get, startsWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(duration);
dayjs.extend(relativeTime);

const GlobalMessageActionsContext = createContext();

/* eslint-disable */
const useContextFactory = (name, context) => {
  return () => {
    const ctx = useContext(context);
    if (ctx === undefined) {
      throw new Error(`use${name}Context must be used within a ${name}ContextProvider.`)
    }
    return ctx;
  }
};

export const useGlobalMessageActionsContext = useContextFactory('GlobalMessageActions', GlobalMessageActionsContext);

/* eslint-enable */
const GlobalMessageContextProvider = (props) => {
  const { i18n, t } = useTranslation('error');
  const auth = useAuth();
  const lang = useMemo(
    () => {
      return i18n.language;
    }, [i18n.language]
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const setGlobalErrorMessage = useCallback(
    (options) => {
      function formatErrorMessage(err) {
        const ttl = get(err, 'data.ttl');
        const errorName = get(err, 'name');
        const errorMessage = get(err, 'message', 'Unknown error');

        const translatedTimeoutErrorName = [
          'TimeoutError',
          'Timeout',
        ];

        const translatedErrorName = [
          'Forbidden',
          'KioskInactiveError',
          'PendingTransactionError',
          'PendingBalanceTransferError',
          'WithdrawNowError',
          'AmountError',
          'GeneralError',
        ];

        const translatedMessageForErrorName = [
          'WithdrawalLimitError',
          'ClientBankError',
        ];

        const translatedMessage = [
          'Failed to start game',
        ];

        if (errorName === 'NotAuthenticated') {
          auth.logout();
          return t(errorMessage);
        } else if (translatedTimeoutErrorName.includes(errorName)) {
          return t('TimeoutError');
        } else if (translatedErrorName.includes(errorName)) {
          return t(errorName);
        } else if (translatedMessageForErrorName.includes(errorName)) {
          return t(errorMessage);
        } else if (translatedMessage.includes(errorMessage)) {
          return t(errorMessage);
        } else if (ttl !== undefined) {
          const duration = dayjs.duration(ttl, 'seconds').locale(lang).humanize();
          return t('Retry in', { duration: duration });
        } else if (startsWith(errorMessage, 'E11000')) {
          return t('DuplicateError');
        } else if (startsWith(errorMessage, 'Validation failed:')) {
          return t('ValidationError');
        } else {
          console.error(`[Error] name: ${errorName}, message: ${errorMessage}`);
          return t(errorMessage);
        }
      }
      const errorMessage = formatErrorMessage(options?.err);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        action: (key) => (
          <>
            <Button sx={{ color: 'white' }} size='small' onClick={() => closeSnackbar(key)}>
              {t('Dismiss')}
            </Button>
          </>
        )
      });
    }, [auth, closeSnackbar, enqueueSnackbar, t, lang]
  );

  const setGlobalMessage = useCallback(
    (options) => {
      const { message = '', severity = 'info' } = options;
      enqueueSnackbar(message, {
        variant: severity,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        action: (key) => (
          <>
            <Button sx={{ color: 'white' }} size='small' onClick={() => closeSnackbar(key)}>
              {t('Dismiss')}
            </Button>
          </>
        )
      });
    }, [closeSnackbar, enqueueSnackbar, t]
  );

  return (
    <GlobalMessageActionsContext.Provider value={{
      setGlobalMessage,
      setGlobalErrorMessage,
    }}>
      {props.children}
    </GlobalMessageActionsContext.Provider>
  )
}

export default GlobalMessageContextProvider;
