import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import Decimal from 'decimal.js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const VVIPLinearProgress = styled(LinearProgress)(({ theme, isenrolled = 'false' }) => {
  const isEnrolled = isenrolled === 'true' ? true : false;

  const gradientAnimation = useMemo(
    () => {
      return keyframes`
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      `;
    }, []
  );

  return {
    backgroundColor: theme.palette.secondary.dark,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#ffd700', // gold
      backgroundImage: `linear-gradient(-45deg, #a020f0, #ffd700, #a020f0)`,
      backgroundSize: '400% 400%',
      animation: `${gradientAnimation} ${!!isEnrolled ? '5s' : '15s'} ease infinite`
    }
  };
});

const LuckyLinearProgress = styled(LinearProgress)(({ theme, isenrolled = 'false' }) => {
  const isEnrolled = isenrolled === 'true' ? true : false;

  const gradientAnimation = useMemo(
    () => {
      return keyframes`
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      `;
    }, []
  );

  return {
    backgroundColor: theme.palette.secondary.dark,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#ffd700', // gold
      backgroundImage: `linear-gradient(-45deg, #7fff00, #ffd700, #7fff00)`,
      backgroundSize: '400% 400%',
      animation: `${gradientAnimation} ${!!isEnrolled ? '5s' : '15s'} ease infinite`
    }
  };
});

export default function LuckyDrawProgress(props) {
  const { luckyDraw, isEnrolled = false, isVIPReward = false } = props;
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ countdown, setCountdown ] = useState(null);
  const [ percentage, setPercentage ] = useState(0);

  const isEnded = useMemo(
    () => {
      const state = get(luckyDraw, 'state', '');
      return state === 'enrollment' ? false : true;
    }, [luckyDraw]
  );

  useEffect(() => {
    if (isEnded) {
      setCountdown(null);
      setPercentage(100);
      return;
    }

    function tick() {
      const now = dayjs();
      const djsStartsAt = get(luckyDraw, 'djsStartsAt');
      const djsEndsAt = get(luckyDraw, 'djsEndsAt');

      const totalSeconds = new Decimal(djsEndsAt.diff(djsStartsAt, 's'));
      const elapsedSeconds = new Decimal(now.diff(djsStartsAt, 's'));

      const decCompletedPercentage = elapsedSeconds.dividedBy(totalSeconds).times(100);
      const completedPercentage = decCompletedPercentage.gt(100) ? 100 : decCompletedPercentage.toNumber();

      const countdown = djsEndsAt.locale(lang).fromNow();
      setCountdown(countdown);
      setPercentage(completedPercentage);
    }

    const tickInterval = setInterval(tick, 10000);
    tick();

    return () => {
      clearInterval(tickInterval);
    };
  }, [isEnded, luckyDraw, lang]);

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container>
        <Grid item xs={12}>
          {
            isVIPReward ?
            <VVIPLinearProgress
              isenrolled={isEnrolled.toString()}
              color={!!isEnded ? 'secondary' : 'primary' }
              sx={{ height: 30 }}
              variant='determinate'
              value={percentage}
            /> :
            <LuckyLinearProgress
              isenrolled={isEnrolled.toString()}
              color={!!isEnded ? 'secondary' : 'primary' }
              sx={{ height: 30 }}
              variant='determinate'
              value={percentage}
            />
          }
          <Box sx={{ width: '100%', position: 'relative' }}>
            <Box sx={{
              position: 'absolute',
              width: '100%',
              height: 30,
              bottom: 0
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}>
                <Typography variant='caption' sx={{
                  color:
                    theme => !!isEnded ?
                    theme.palette.getContrastText(theme.palette.secondary.main) :
                    'white',
                  textTransform: 'uppercase',
                  fontWeight: 700
                }}>
                  {
                    !isEnded ? t('Event countdown', { countdown }) : t('Event ended')
                  }
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

LuckyDrawProgress.propTypes = {
  luckyDraw: PropTypes.object.isRequired,
  isEnrolled: PropTypes.bool.isRequired,
};
