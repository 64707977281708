import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import SupportContext from 'features/context/supportContext';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { RadioGroup, Radio } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Loader from 'features/loader/Loader';
import { get, shuffle, pickBy } from 'lodash';
import { generateBotUrl, generateCsUrl } from 'utils/social';
import { useUserConfig } from 'hooks/useUserConfig';
import OutlinedInput from '@mui/material/OutlinedInput';
import Announcements from 'features/announcements/Announcements';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import GameShowcase from 'features/games/Showcase';
import Country from './Country';
import SupportDialog from './SupportDialog.js';
import CustomCardHeader from './CardHeader';
import Relogin from 'features/login/Relogin';
import CompanySettingContext from 'features/context/companySettingContext';
import Copyright from './Copyright';

export default function Login() {
  const { t } = useTranslation();
  const { token: urlToken = '' } = useParams();
  const [ isRevealSensitive, setIsRevealSensitive ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { lang: userLang } = useUserConfig();
  const fromOrigin = get(location, 'state.from.pathname', '/');
  const from = fromOrigin === '/' ? '/games' : fromOrigin;
  const { waBots, tgBots, lineBots, customerServices, ready: contextReady } = useContext(SupportContext);
  const [ passwordLogin, setPasswordLogin ] = useState(false);
  const [ supportDialog, setSupportDialog ] = useState({
    open: false,
    title: '',
    description: '',
    tgLink: null,
    waLink: null,
    lineLink: null,
  });

  const {
    companyId,
    supportedCountryCodes,
  } = useContext(CompanySettingContext);

  const isSupportedMultiCountryCode = useMemo(
    () => {
      return supportedCountryCodes.length > 1 ? true : false;
    }, [supportedCountryCodes]
  );

  const LoginSchema = Yup.object().shape({
    token: Yup.string().required(t("Required")),
  });

  const PasswordLoginSchema = Yup.object().shape({
    countryCode: Yup.string().required(t("Required")),
    username: Yup.string().required(t("Required")),
    password: Yup.string().required(t("Required")),
  });

  const defaultCountryCode = useMemo(
    () => {
      const firstCountryCode = get(supportedCountryCodes, '[0]', '#');
      return `${firstCountryCode}`;
    }, [supportedCountryCodes]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      token: urlToken,
      countryCode: defaultCountryCode,
      username: '',
      password: '',
    },
    validationSchema: !!passwordLogin ? PasswordLoginSchema : LoginSchema,
    onSubmit: async values => {
      try {
        const tokenMode = !!passwordLogin ? false : true;
        await auth.login({ ...values, tokenMode, companyId });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    },
  });

  const selectedCountryCode = useMemo(
    () => {
      const formikCountryCode = get(formik, 'values.countryCode', '');
      return formikCountryCode;
    }, [formik]
  );

  useEffect(() => {
    setPasswordLogin(!!urlToken ? false : true);
  }, [urlToken]);

  const supports = useMemo(
    () => {
      if (!contextReady) return null;
      const waBot = shuffle(waBots)[0];
      const tgBot = shuffle(tgBots)[0];
      const lineBot = shuffle(lineBots)[0];

      const wa = shuffle(pickBy(customerServices, cs => cs.whatsapp))[0];
      const tg = shuffle(pickBy(customerServices, cs => cs.telegram))[0];
      const line = shuffle(pickBy(customerServices, cs => cs.line))[0];

      return {
        waBot: generateBotUrl(waBot, 'whatsapp', userLang),
        tgBot: generateBotUrl(tgBot, 'telegram', userLang),
        lineBot: generateBotUrl(lineBot, 'line', userLang),
        wa: generateCsUrl(wa, 'whatsapp'),
        tg: generateCsUrl(tg, 'telegram'),
        line: generateCsUrl(line, 'line'),
      };
    }, [contextReady, waBots, tgBots, lineBots, customerServices, userLang]
  );

  const handleRevealToggle = (event) => {
    event.preventDefault();
    setIsRevealSensitive(!isRevealSensitive);
  };

  const handlePasswordLoginChange = (event) => {
    setPasswordLogin(prev => !prev);
  };

  const onSupportDialogClose = (event) => {
    event?.preventDefault();
    setSupportDialog(prev => ({ ...prev, open: false }));
  };

  const onSupportOpen = useCallback((event) => {
    event?.preventDefault();
    setSupportDialog({
      open: true,
      title: t('Contact us now'),
      description: t("Need help with your account, games, or payments? We're here for you."),
      tgLink: get(supports, 'tg'),
      waLink: get(supports, 'wa'),
      lineLink: get(supports, 'line'),
      defaultLocale: userLang,
    });
  }, [supports, userLang, t]);

  const onRegisterOpen = useCallback((event) => {
    event?.preventDefault();
    setSupportDialog({
      open: true,
      title: t('Join us'),
      description: t('Experience fast and secure bot-assisted registration.'),
      tgLink: get(supports, 'tgBot'),
      waLink: get(supports, 'waBot'),
      lineLink: get(supports, 'lineBot'),
      defaultLocale: userLang
    });
  }, [supports, userLang, t]);

  const isAuthenticated = useMemo(
    () => {
      return !!auth?.user;
    }, [auth]
  );

  const hasToken = useMemo(
    () => {
      return !!localStorage.getItem('feathers-jwt');
    }, []
  );

  useEffect(
    () => {
      if (!isAuthenticated) return;
      navigate(from, { replace: true });
    }, [isAuthenticated, from, navigate]
  );

  if (hasToken) {
    return (<Relogin from={from} />);
  }

  return (
    <Container component="main" maxWidth="sm">
      <Loader open={!auth.isIdle || !contextReady} />
      <Box sx={{ my: 2 }}>
        <Announcements />
      </Box>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card elevation={4} component="form" onSubmit={formik.handleSubmit} noValidate>
              <CustomCardHeader />
              <CardContent>
                <Box >
                {
                  !!passwordLogin ?
                    (
                      <Grid container spacing={1}>
                        {
                          !!isSupportedMultiCountryCode && <Grid sx={{ display: supportedCountryCodes.length }} item xs={3}>
                            <FormControl fullWidth>
                              <InputLabel id="country-code-label">{t('Country')}</InputLabel>
                              <Select
                                labelId="country-code-label"
                                id="country-code"
                                value={selectedCountryCode}
                                label={t('Country')}
                                onChange={function(event) {
                                  const value = get(event, 'target.value');
                                  if (value) formik.setFieldValue('countryCode', value);
                                }}
                              >
                                {
                                  supportedCountryCodes.map(function (cc) {
                                    const countryName = get(Country, cc);
                                    if (!countryName) return null;
                                    return (
                                      <MenuItem key={cc} value={cc}>{countryName}</MenuItem>
                                    );
                                  })
                                }
                              </Select>
                              {
                                formik.errors.countryCode ?
                                <FormHelperText error>{formik.errors.countryCode}</FormHelperText> :
                                null
                              }
                            </FormControl>
                          </Grid>
                        }
                        <Grid item xs={isSupportedMultiCountryCode ? 9 : 12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="username-input" error={formik.errors.username ? true : false}>{t("Username")}</InputLabel>
                            <OutlinedInput
                              autoComplete='off'
                              label={t("Username")}
                              id="username"
                              name="username"
                              type='text'
                              placeholder={t('mobile number')}
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              error={formik.errors.username ? true : false}
                              inputProps={{ inputMode: 'numeric' }}
                              inputMode='numeric'
                              startAdornment={
                                <InputAdornment position="start">
                                  {selectedCountryCode}
                                </InputAdornment>
                              }
                            />
                            {
                              formik.errors.username ?
                              <FormHelperText error>{formik.errors.username}</FormHelperText> :
                              null
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="password-input" error={formik.errors.password ? true : false}>{t("Password")}</InputLabel>
                          <OutlinedInput
                            autoComplete='off'
                            label={t("Password")}
                            id="password-input"
                            name="password"
                            type={isRevealSensitive? 'text' : 'password'}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.errors.password ? true : false}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleRevealToggle}
                                  onMouseDown={handleRevealToggle}
                                >
                                  {isRevealSensitive ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            /*placeholder={t('Where to set password')}*/
                          />
                          {
                            formik.errors.password ?
                            <FormHelperText error>{formik.errors.password}</FormHelperText> :
                            null
                          }
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="token-input" error={formik.errors.token ? true : false}>{t("Token")}</InputLabel>
                      <OutlinedInput
                        autoComplete='off'
                        label={t("Token")}
                        id="token-input"
                        name="token"
                        type={isRevealSensitive? 'text' : 'password'}
                        value={formik.values.token}
                        onChange={formik.handleChange}
                        error={formik.errors.token ? true : false}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleRevealToggle}
                              onMouseDown={handleRevealToggle}
                            >
                              {isRevealSensitive ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {
                        formik.errors.token ?
                        <FormHelperText error>{formik.errors.token}</FormHelperText> :
                        null
                      }
                    </FormControl>
                  )
                }
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box sx={{ ml: 2 }}>
                        <RadioGroup
                          aria-label="login-mode"
                          name="login-mode"
                          value={passwordLogin ? 'password' : 'token'}
                          onChange={handlePasswordLoginChange}
                          row
                        >
                          <FormControlLabel
                            value="token"
                            control={<Radio color="primary" />}
                            label={
                              <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 700, textTransform: 'uppercase' }}>
                                {t('Login by token')}
                              </Typography>
                            }
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="password"
                            control={<Radio color="primary" />}
                            label={
                              <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 700, textTransform: 'uppercase' }}>
                                {t('Login by password')}
                              </Typography>
                            }
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ textAlign: 'right' }}>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                        >
                          {t('Login')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/*<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    {t('Login')}
                  </Button>
                </Box>*/}
              </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container>
                  <Grid item xs>
                    <Button onClick={onSupportOpen} endIcon={<ContactSupportIcon />}>
                      {t("Contact Us")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={onRegisterOpen}>
                      {t("Register")}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <SupportDialog {...supportDialog} onClose={onSupportDialogClose} />
      <Box>
        <GameShowcase />
      </Box>
      <Box sx={{ my: 2 }}>
        <Copyright />
      </Box>
    </Container>
  );
}
