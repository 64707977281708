import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import CommonContext from 'features/context/commonContext';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { orderBy } from 'lodash';
import Decimal from 'decimal.js';
import { _POINT_SYMBOL } from 'utils/symbol';

export default function WinnersPreviewDialog(props) {
  const { onClose, luckyDrawId } = props;
  const { t } = useTranslation();

  const { luckyDraws } = useContext(CommonContext);

  const luckyDraw = useMemo(
    () => {
      if (!luckyDrawId) return {};

      const findLuckyDraw = luckyDraws.find((luckyDraw) => luckyDraw._id === luckyDrawId) || null;
      return findLuckyDraw;
    }, [luckyDraws, luckyDrawId]
  );

  const isEndedWithNoWinners = useMemo(
    () => {
      if (!luckyDraw) return false;

      const { state, winnerCount = 0 } = luckyDraw || {};

      if (state !== 'done') return false;

      return winnerCount === 0;
    }, [luckyDraw]
  );

  const data = useMemo(
    () => {
      if (!luckyDraw) return [];

      const {
        winners: usernames = [],
        prizes = [],
        decPrizeAmount = new Decimal(0),
        wonIndex = -1,
        prizeDistribution = 'fixed'
      } = luckyDraw || {};

      const ret = usernames.map(function(u, index) {
        const prize = prizeDistribution === 'fixed' ?
          decPrizeAmount.toNumber() :
          prizes[index]?.$numberDecimal || 0;

        const wonIndexMatch = new Decimal(wonIndex).eq(index);

        return {
          username: u,
          ...(!!prize && {
            prize
          }),
          ...(!!wonIndexMatch && {
            isMyPrize: true
          })
        }
      });

      if (prizeDistribution === 'fixed') return ret;

      return orderBy(ret, [
        function (d) {
          const prize = d?.prize || 0;
          return new Decimal(prize).toNumber();
        }
      ], ['desc'])
    }, [luckyDraw]
  );

  const handleClose = (event) => {
    onClose(event);
  };

  const Row = ({ index, style }) => {
    const { username, prize, isMyPrize = false } = data[index];
    const prizeAmount = !!prize ? new Decimal(prize).toFixed(2) : '';
    return (
      <Box style={style} sx={{ display: 'flex', p: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography
          variant='subtitle1' sx={{
            color: isMyPrize ? 'primary.main' : 'text.secondary',
            fontWeight: isMyPrize ? 700 : 'inherit'
          }}>
          {username}
        </Typography>
        <Typography variant='h6' sx={{
          fontWeight: 700,
          color: isMyPrize ? 'primary.main' : 'text.secondary'
        }}>
          {`${_POINT_SYMBOL} ${prizeAmount}`}
        </Typography>
      </Box>
    );
  };

  return (
    <Dialog maxWidth='xs' fullWidth open={!!luckyDrawId} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>{t('Winner Preview')}</DialogTitle>
      {
        !!data.length ?
        <DialogContent dividers sx={{ overflowX: 'hidden', minHeight: '50vh' }}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="list-username"
                height={height}
                itemCount={data.length}
                itemSize={50}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </DialogContent> :
        <DialogContent dividers>
          <Typography variant='body1' color={'text.secondary'}>
            {
              isEndedWithNoWinners ?
              t('Event ended with no winner') :
              t('No winner')
            }
          </Typography>
        </DialogContent>
      }
      <DialogActions>
        <Button variant='contained' autoFocus onClick={handleClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

WinnersPreviewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};
