import { useEffect, useState } from 'react';
import axios from 'services/axios';
import { Buffer } from 'buffer';

const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

function useImageAssetDownloader (options = {}) {
  const {
    assetId,
    variant,
  } = options;

  const [imageData, setImageData] = useState(EMPTY_IMAGE);
  const [ isLoaded, setIsLoaded ] = useState(false);

  useEffect (() => {
    if (!assetId) {
      setImageData(EMPTY_IMAGE);
      setIsLoaded(true);
      return;
    }

    if (assetId.indexOf('/') !== -1) {
      setImageData(assetId);
      setIsLoaded(true);
      return;
    }

    let isMounted = true;
    async function fetchData () {
      try {
        const axiosImage = await axios.get(`/asset/${assetId}`, {
          responseType: 'arraybuffer',
          params: {
            ...(variant && { variant }), // if variant is defined, add it to the params
          },
          transformResponse: (data) => {
            return Buffer.from(data, 'binary').toString('base64');
          }
        });

        const { data: dataBase64, headers } = axiosImage;
        const { 'content-type': mimeType } = headers;
        const imageData = `data:${mimeType};base64,${dataBase64}`;

        if (isMounted) {
          setImageData(imageData);
        }
      } catch (err) {
        console.log(err)
        if (!isMounted) return;
        setImageData(EMPTY_IMAGE);
      } finally {
        if (isMounted) {
          setIsLoaded(true);
        }
      }
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [assetId, variant]);

  return {
    image: imageData,
    isLoaded,
  };
}

export default useImageAssetDownloader;
