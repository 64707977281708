import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { reduce } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FILTER_TYPES = [
  'deposit',
  'withdrawal',
  'cashrebate',
  'tier',
  'booster',
  'referral',
  'luckydraw',
  'vip',
  'redemption',
  'adjustment',
  'balancetransfer',
];

const INITIAL_FILTERS = reduce(FILTER_TYPES, function(result, filter) {
  return {
    ...result,
    [filter]: true
  };
}, {});

const INITIAL_FILTERS_INVERT = reduce(FILTER_TYPES, function(result, filter) {
  return {
    ...result,
    [filter]: false
  };
}, {});

export default function FilterDialog(props) {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const [ state, setState ] = useState(INITIAL_FILTERS);

  const isAllSelected = useMemo(
    () => {
      let isAll = true;
      for (let prop in state) {
        if (state[prop] === false) {
          isAll = false;
          break;
        }
      }
      return isAll;
    }, [state]
  );

  const handleChange = (event) => {
    event?.preventDefault();
    setState({
      ...state,
      [event.target.name]: event.target.checked
    })
  };

  const handleSelectAll = (event) => {
    event?.preventDefault();
    setState(INITIAL_FILTERS);
  };

  const handleSelectNone = (event) => {
    event?.preventDefault();
    setState(INITIAL_FILTERS_INVERT);
  };

  const handleClose = (event) => {
    event?.preventDefault();
    let filters = [];

    for (let prop in state) {
      if (state[prop] === true) filters.push(prop);
    }

    onClose(filters);
  };

  return (
    <Dialog fullWidth maxWidth='xs' onClose={handleClose} open={open}>
      <DialogTitle>
        {
          t('Filters')
        }
      </DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ m: 2 }} component='fieldset' variant='standard'>
          <FormGroup>
            {
              FILTER_TYPES.map(function(filter) {
                return (
                  <FormControlLabel
                    key={filter}
                    control={
                      <Checkbox color='info' checked={state[filter]} onChange={handleChange} name={filter} />
                    }
                    label={t(filter)}
                  />
                );
              })
            }
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {
          isAllSelected ?
          <Button color='info' variant='contained' onClick={handleSelectNone}>
            {t('Select None')}
          </Button> :
          <Button color='info' variant='contained' onClick={handleSelectAll}>
            {t('Select All')}
          </Button>
        }
        <Button variant='contained' autoFocus onClick={handleClose}>
          {t('Apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
