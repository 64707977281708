 import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useAuth } from 'hooks/useAuth';
import { get, orderBy, find, maxBy } from 'lodash';
import Decimal from 'decimal.js';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import ImageAvatar from 'features/avatar/ImageAvatar';
import Divider from '@mui/material/Divider';
import CommonContext from 'features/context/commonContext';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoTwoTone';
import CloseIcon from '@mui/icons-material/HighlightOff';
import Slider from '@mui/material/Slider';
import Grow from '@mui/material/Grow';
import { Alert } from '@mui/material';
import numeral from 'numeral';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';

function LinearProgressWithLabel(props) {
  const { centerlabel = '', value, hidevalue = '' } = props;
  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ position: 'absolute', left: '50%', mt: '.9rem', transform: 'translate(-50%, -50%)' }}>
        <Typography sx={{
          color: theme => theme.palette.getContrastText(theme.palette.primary.main)
        }}
        variant="caption">{`${centerlabel}`}</Typography>
      </Box>
      <Box sx={{
        display: hidevalue ? 'none' : 'block',
        position: 'absolute',
        right: 0,
        mt: '.9rem',
        transform: 'translate(-50%, -50%)'
      }}>
        <Typography sx={{
          color: theme => theme.palette.getContrastText(theme.palette.primary.main),
          fontWeight: 700
        }}
        variant="caption" color="text.secondary">{`${value}%`}</Typography>
      </Box>
    </Box>
  );
}

function getExpByLevel(level, levelConfig) {
  const { baseExp, exponent } = levelConfig || {};
  if (baseExp === undefined || exponent === undefined) return 0;
  const exp = Math.floor(baseExp * Math.pow(level, exponent));
  return exp;
}

export default function TierCard(props) {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { userData, userDataReady, tiers, tiersReady, companySetting } = useContext(CommonContext);
  const { t } = useTranslation();
  const [ infoMode, setInfoMode ] = useState(false);
  const [ previewLevel, setPreviewLevel ] = useState(-1);
  const [ sliderValue, setSliderValue ] = useState(0);

  const hideUnlimitedCashOut = useMemo(
    () => {
      return companySetting?.walletConfig?.hideUnlimitedCashOut || false;
    }, [companySetting]
  );

  const currencySymbol = useMemo(
    () => {
      return companySetting?.currencySymbol || '';
    }, [companySetting]
  );

  const levelConfig = useMemo(
    () => {
      const baseExp = get(companySetting, 'levelConfig.level.baseExp');
      const exponent = get(companySetting, 'levelConfig.level.exponent');

      return [
        baseExp,
        exponent
      ];
    }, [companySetting]
  );

  const isReady = useMemo(
    () => {
      return (!!userDataReady && !!tiersReady);
    }, [userDataReady, tiersReady]
  );

  const derankInfo = useMemo(
    () => {
      const thresholdValue = get(companySetting, 'levelConfig.derank.thresholdValue');
      const thresholdUnit = get(companySetting, 'levelConfig.derank.thresholdUnit');

      if (!thresholdValue || !thresholdUnit) return null;

      return {
        value: thresholdValue,
        unit: thresholdUnit
      }
    }, [companySetting]
  );

  const lastDeposit = useMemo(
    () => {
      // Get last deposit date from recentCashIn
      const recentCashIn = get(userData, 'recentCashIn.minute', []);
      if (recentCashIn.length === 0) return null;

      const lastItem = recentCashIn[recentCashIn.length - 1];
      const lastDepositDate = get(lastItem, 'date');

      return lastDepositDate;
    }, [userData]
  );

  /*
    Calculate a date to indicate when the user will be deranked
    based on the last deposit date and the derank threshold
  */
  const derankDate = useMemo(
    () => {
      if (!derankInfo || !lastDeposit) return null;
      const { value, unit } = derankInfo;
      const derankDate = dayjs(lastDeposit).add(value, unit);

      if (dayjs().isAfter(derankDate)) return null;

      // If the derank date is more than 1 week away, don't show the warning
      const warningCutOffDate = dayjs().add(1, 'week');
      if (dayjs(derankDate).isAfter(warningCutOffDate)) return null;

      return derankDate.locale(lang).format('DD MMM YYYY');
    }, [derankInfo, lastDeposit, lang]
  );

  const sliderMarks = useMemo(
    () => {
      if (!tiersReady) return [];

      const marks = tiers.map(function (t) {
        return {
          value: t?.minLevel,
          //label: `${t?.minLevel}`
        }
      });
    return marks;
    }, [tiers, tiersReady]
  );

  const maxSliderValue = useMemo(
    () => {
      if (!tiersReady) return 100;
      const maxTier = maxBy(tiers, 'minLevel');
      return maxTier?.minLevel || 100;
    }, [tiers, tiersReady]
  );

  const levelInfo = useMemo(
    () => {
      return {
        level: get(userData, 'level', 0),
        percentage: get(userData, 'levelPercentage', 0),
        totalExp: new Decimal(get(userData, 'totalExp.$numberDecimal', '0')).round(),
        nextLevelExp: new Decimal(get(userData, 'nextLevelExp.$numberDecimal', '0')).round()
      }
    }, [userData]
  );

  const currentTierData = useMemo(
    () => {
      const orderedTiers = orderBy(tiers, 'minLevel', ['desc']);
      const findTier = find(orderedTiers, function (t) {
        return t.minLevel <= previewLevel;
      });

      const [ baseExp, exponent ] = levelConfig;
      const minExp = getExpByLevel(previewLevel, {
        baseExp,
        exponent
      });

      const { cashOutLimit, cashRebateSettings } = findTier || {};

      const decDailyCashOutCountLimit = new Decimal(get(cashOutLimit, 'day.count', '0'));
      const decDailyCashOutAmountLimit = new Decimal(get(cashOutLimit, 'day.amount.$numberDecimal', '0'));
      const decMonthlyCashOutCountLimit = new Decimal(get(cashOutLimit, 'month.count', '0'));
      const decMonthlyCashOutAmountLimit = new Decimal(get(cashOutLimit, 'month.amount.$numberDecimal', '0'));

      // if dailyCashOutCountLimit is 0, and dailyCashOutAmountLimit is 0, then hide the daily cash out limit if the hideUnlimitedCashOut is true
      const hideDailyCashOutCountLimit = (
        hideUnlimitedCashOut &&
        decDailyCashOutCountLimit.eq(0)
      );

      const hideDailyCashOutAmountLimit = (
        hideUnlimitedCashOut &&
        decDailyCashOutAmountLimit.eq(0)
      );

      const hideMonthlyCashOutCountLimit = (
        hideUnlimitedCashOut &&
        decMonthlyCashOutCountLimit.eq(0)
      );

      const hideMonthlyCashOutAmountLimit = (
        hideUnlimitedCashOut &&
        decMonthlyCashOutAmountLimit.eq(0)
      );

      const dailyCashOutLimitStrArr = [];
      !hideDailyCashOutCountLimit && dailyCashOutLimitStrArr.push(
        t('Count limit', {
          count: decDailyCashOutCountLimit.toNumber()
        })
      );
      !hideDailyCashOutAmountLimit && dailyCashOutLimitStrArr.push(
        t('Amount limit', {
          count: decDailyCashOutAmountLimit.toNumber(),
          amount: `${currencySymbol}${numeral(decDailyCashOutAmountLimit.toNumber()).format('0.00a')}`
        })
      );
      const dailyCashOutLimitStr = dailyCashOutLimitStrArr.join(', ');

      const monthlyCashOutLimitStrArr = [];
      !hideMonthlyCashOutCountLimit && monthlyCashOutLimitStrArr.push(
        t('Count limit', {
          count: decMonthlyCashOutCountLimit.toNumber()
        })
      );
      !hideMonthlyCashOutAmountLimit && monthlyCashOutLimitStrArr.push(
        t('Amount limit', {
          count: decMonthlyCashOutAmountLimit.toNumber(),
          amount: `${currencySymbol}${numeral(decMonthlyCashOutAmountLimit.toNumber()).format('0.00a')}`
        })
      );
      const monthlyCashOutLimitStr = monthlyCashOutLimitStrArr.join(', ');

      const showCashOutLimit = (
        !hideDailyCashOutCountLimit ||
        !hideDailyCashOutAmountLimit ||
        !hideMonthlyCashOutCountLimit ||
        !hideMonthlyCashOutAmountLimit
      );

      const dailySlotLossSkipWallet = get(cashRebateSettings, 'dailySlotLoss.skipWallet', false);
      const dailySlotTurnoverSkipWallet = get(cashRebateSettings, 'dailySlotTurnover.skipWallet', false);
      const dailyLiveTurnoverSkipWallet = get(cashRebateSettings, 'dailyLiveTurnover.skipWallet', false);

      const decDailySlotLossPercentage = new Decimal(get(cashRebateSettings, 'dailySlotLoss.multiplier.$numberDecimal', '0')).times(100);
      const decDailySlotLossMaxAmount = new Decimal(get(cashRebateSettings, 'dailySlotLoss.maxAmount.$numberDecimal', '0'));
      const decDailySlotTurnoverPercentage = new Decimal(get(cashRebateSettings, 'dailySlotTurnover.multiplier.$numberDecimal', '0')).times(100);
      const decDailySlotTurnoverMaxAmount = new Decimal(get(cashRebateSettings, 'dailySlotTurnover.maxAmount.$numberDecimal', '0'));
      const decDailyLiveTurnoverPercentage = new Decimal(get(cashRebateSettings, 'dailyLiveTurnover.multiplier.$numberDecimal', '0')).times(100);
      const decDailyLiveTurnoverMaxAmount = new Decimal(get(cashRebateSettings, 'dailyLiveTurnover.maxAmount.$numberDecimal', '0'));

      const dailySlotLossStrArr = [];
      !dailySlotLossSkipWallet && dailySlotLossStrArr.push(`${decDailySlotLossPercentage.toNumber()}%`);
      !dailySlotLossSkipWallet && dailySlotLossStrArr.push(
        t('Amount limit', {
          count: decDailySlotLossMaxAmount.toNumber(),
          amount: `${currencySymbol}${numeral(decDailySlotLossMaxAmount.toNumber()).format('0.00a')}`
        })
      );
      const dailySlotLossStr = dailySlotLossStrArr.join(', ');

      const dailySlotTurnoverStrArr = [];
      !dailySlotTurnoverSkipWallet && dailySlotTurnoverStrArr.push(`${decDailySlotTurnoverPercentage.toNumber()}%`);
      !dailySlotTurnoverSkipWallet && dailySlotTurnoverStrArr.push(
        t('Amount limit', {
          count: decDailySlotTurnoverMaxAmount.toNumber(),
          amount: `${currencySymbol}${numeral(decDailySlotTurnoverMaxAmount.toNumber()).format('0.00a')}`
        })
      );
      const dailySlotTurnoverStr = dailySlotTurnoverStrArr.join(', ');

      const dailyLiveTurnoverStrArr = [];
      !dailyLiveTurnoverSkipWallet && dailyLiveTurnoverStrArr.push(`${decDailyLiveTurnoverPercentage.toNumber()}%`);
      !dailyLiveTurnoverSkipWallet && dailyLiveTurnoverStrArr.push(
        t('Amount limit', {
          count: decDailyLiveTurnoverMaxAmount.toNumber(),
          amount: `${currencySymbol}${numeral(decDailyLiveTurnoverMaxAmount.toNumber()).format('0.00a')}`
        })
      );
      const dailyLiveTurnoverStr = dailyLiveTurnoverStrArr.join(', ');

      const showDailyRebate = (
        !dailySlotLossSkipWallet ||
        !dailySlotTurnoverSkipWallet ||
        !dailyLiveTurnoverSkipWallet
      );

      return {
        id: get(findTier, '_id'),
        minLevel: previewLevel,
        minExp,
        photo: get(findTier, `photo[${lang}]`),
        title: get(findTier, `name[${lang}]`, '-'),
        rewardPercentage: new Decimal(get(findTier, 'pointMultiplier.$numberDecimal', '0')).times(100).toNumber(),
        dailyCashOutLimitStr,
        monthlyCashOutLimitStr,
        showCashOutLimit,
        dailySlotLossStr,
        dailySlotTurnoverStr,
        dailyLiveTurnoverStr,
        showDailyRebate,
      }
    }, [tiers, previewLevel, levelConfig, lang, hideUnlimitedCashOut, t, currencySymbol]
  );

  useEffect(() => {
    if (infoMode) {
      setPreviewLevel(sliderValue);
    } else {
      const { level } = levelInfo;
      setPreviewLevel(level);
    }
  }, [infoMode, levelInfo, sliderValue])

  function onInfoClicked(event) {
    event.preventDefault();
    setInfoMode(prev => (!prev));
  }

  function handleChange(event) {
    const level = event?.target?.value;
    setSliderValue(level);
  }

  return (
    <>
      { !isReady && <LinearProgress /> }
      <Card>
        <CardContent>
          <Box sx={{
            position: 'relative',
          }}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
            }}>
              <IconButton color='inherit' onClick={onInfoClicked}>
                {
                  infoMode ? <CloseIcon /> : <InfoIcon />
                }
              </IconButton>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <ImageAvatar
                variant='rounded' sx={{ width: '5rem', height: '5rem' }}
                imageId={currentTierData.photo}
              />
              <Typography variant='h6' sx={{ fontWeight: 700 }}>
                {t('Membership card', { tierTitle: currentTierData.title })}
              </Typography>
              <Typography variant='subtitle1' sx={{ fontWeight: 500, color: 'text.secondary' }}>
                {
                  infoMode ?
                    t('From Lvl', { level: currentTierData.minLevel }) :
                    t('Lvl', { level: levelInfo.level })
                }
              </Typography>
            </Box>
            <Box sx={{
              my: 1,
            }}>
              {
                infoMode ?
                <LinearProgressWithLabel
                  sx={{ height: 20, mb: 1 }}
                  size='large'
                  variant="determinate"
                  centerlabel={t('From Exp', { exp: currentTierData.minExp })}
                  value={100}
                  hidevalue={'true'} /> :
                <LinearProgressWithLabel
                  sx={{ height: 20, mb: 1 }}
                  size='large'
                  variant="determinate"
                  centerlabel={`${t('Exp')} ${levelInfo.totalExp}/${levelInfo.nextLevelExp}`}
                  value={levelInfo.percentage}
                />
              }
            </Box>
            {
              !!infoMode && derankDate &&
              <Box>
                <Alert severity='warning' sx={{ mb: 1 }}>
                  {t('Derank reminder', { derankDate })}
                </Alert>
              </Box>
            }
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='body2' sx={{ fontWeight: 500, fontStyle: 'oblique', color: 'text.secondary' }}>
                {t('Point reward on deposit')}
              </Typography>
              <Typography variant='body2' sx={{ fontWeight: 700 }}>
                { currentTierData.rewardPercentage }%
              </Typography>
            </Box>
            <Box
              sx={{
              display: currentTierData.showDailyRebate ? 'flex' : 'none',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              <Typography variant='body2' sx={{ fontWeight: 500, fontStyle: 'oblique', color: 'text.secondary' }}>
                {t('Daily cash rebate')}
              </Typography>
              <Box
                sx={{
                display: currentTierData.dailySlotLossStr ? 'flex' : 'none',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography variant='body2' sx={{ fontWeight: 500, color: 'text.secondary', ml: 1 }}>
                  {t('Slot loss')}
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                  { currentTierData.dailySlotLossStr }
                </Typography>
              </Box>
              <Box
                sx={{
                display: currentTierData.dailySlotTurnoverStr ? 'flex' : 'none',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography variant='body2' sx={{ fontWeight: 500, color: 'text.secondary', ml: 1 }}>
                  {t('Slot turnover')}
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                  { currentTierData.dailySlotTurnoverStr }
                </Typography>
              </Box>
              <Box
                sx={{
                display: currentTierData.dailyLiveTurnoverStr ? 'flex' : 'none',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography variant='body2' sx={{ fontWeight: 500, color: 'text.secondary', ml: 1 }}>
                  {t('Live turnover')}
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                  { currentTierData.dailyLiveTurnoverStr }
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
              display: (currentTierData.showCashOutLimit && infoMode) ? 'flex' : 'none',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              <Typography variant='body2' sx={{ fontWeight: 500, fontStyle: 'oblique', color: 'text.secondary' }}>
                {t('Withdrawal limit')}
              </Typography>
              <Box
                sx={{
                display: currentTierData.dailyCashOutLimitStr ? 'flex' : 'none',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography variant='body2' sx={{ fontWeight: 500, color: 'text.secondary', ml: 1 }}>
                  {t('Daily')}
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                  { currentTierData.dailyCashOutLimitStr }
                </Typography>
              </Box>
              <Box
                sx={{
                display: currentTierData.monthlyCashOutLimitStr ? 'flex' : 'none',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography variant='body2' sx={{ fontWeight: 500, color: 'text.secondary', ml: 1 }}>
                  {t('Monthly')}
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                  { currentTierData.monthlyCashOutLimitStr }
                </Typography>
              </Box>

            </Box>
            {/* <Box sx={{
              mt: 2,
              display: infoMode ? 'flex' : 'none',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              visibility: infoMode ? 'visible' : 'hidden',
            }}>
              {
                tiers.map(function (t) {
                  return (
                    <Box key={t._id} sx={{ mx: 1, filter: t._id === currentTierData.id ? 'none' : 'grayscale(100%)' }}>
                      <ImageAvatar
                        variant='rounded' sx={{ width: '2rem', height: '2rem' }}
                        imageId={get(t, `photo[${lang}]`)}
                      />
                    </Box>
                  );
                })
              }
            </Box> */}
          </Box>
        </CardContent>
        {
          !!infoMode &&
          <Grow in={!!infoMode}>
            <Box>
              <Divider />
              <CardActions>
                <Box sx={{ mx: 1.5, width: '100%' }}>
                  <Slider
                    value={sliderValue}
                    onChange={handleChange}
                    step={null}
                    valueLabelDisplay="auto"
                    marks={sliderMarks}
                    max={maxSliderValue}
                  />
                </Box>
              </CardActions>
            </Box>
          </Grow>
        }
      </Card>
    </>
  );
}