//https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const hex2HueRotationAngle = (hexColor, offset = 0) => {
  // Remove the "#" if it's included in the hexColor
  hexColor = hexColor.replace(/^#/, '');

  // Parse the hexadecimal color into RGB components
  const r = parseInt(hexColor.slice(0, 2), 16) / 255;
  const g = parseInt(hexColor.slice(2, 4), 16) / 255;
  const b = parseInt(hexColor.slice(4, 6), 16) / 255;

  // Calculate the maximum and minimum values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h;

  if (max === min) {
    h = 0; // achromatic (no hue)
  } else {
    const d = max - min;
    switch (max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) / 6;
        break;
      case g:
        h = ((b - r) / d + 2) / 6;
        break;
      case b:
        h = ((r - g) / d + 4) / 6;
        break;
      default:
    }
  }

  // Convert hue to degrees
  const hueDegrees = Math.round(h * 360) + offset;

  return hueDegrees;
}