import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function Loader(props) {
  const { open, text } = props;
  return (
    <Backdrop sx={{ color: 'primary.main', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress color='inherit' />
        {
          text ?
          <Box>
            <Typography
              color='inherit'
              sx={{
                top: 0,
                left: 0,
                bottom: -80,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              component='div' variant='subtitle2'>{text}</Typography>
          </Box> :
          null
        }
      </Box>
    </Backdrop>
  );
};

Loader.propTypes = {
  open: PropTypes.bool.isRequired
};
