import { createContext } from 'react';

const CompanySettingContext = createContext({
  companyId: '',
  supportedLocales: [],
  defaultLocale: '',
  supportedCountryCodes: [],
  pageConfig: {},
  ready: false,
});

export default CompanySettingContext;
