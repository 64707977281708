import React, { useMemo, useCallback } from 'react';
import { useUserFavourite } from 'hooks/useUserFavourite';

import {
  Box,
} from '@mui/material';

import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
} from '@mui/icons-material';

import {
  pink
} from '@mui/material/colors';

export default function FavouriteGameTitle(props) {
  const { gameType, gameCode } = props;
  const { userFavouriteGameTitles, addFavouriteGameTitle, removeFavouriteGameTitle } = useUserFavourite();

  const isFavourite = useMemo(() => {
    return userFavouriteGameTitles.some((favourite) => {
      return favourite.gameType === gameType && favourite.gameCode === `${gameCode}`;
    });
  }, [userFavouriteGameTitles, gameType, gameCode]);

  const onFavouriteGameTitleClick = useCallback((event) => {
    if (isFavourite) {
      removeFavouriteGameTitle(gameType, gameCode);
    } else {
      addFavouriteGameTitle(gameType, gameCode);
    }
    event.stopPropagation();
  }, [isFavourite, gameType, gameCode, addFavouriteGameTitle, removeFavouriteGameTitle]);

  return (
    <Box
      onClick={onFavouriteGameTitleClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: isFavourite ? 'warning.main' : 'grey.500',
      }}
    >
      {isFavourite ? <FavoriteIcon sx={{ color: pink[500] }} /> : <FavoriteBorderIcon />}
    </Box>
  );
}