import axios from 'axios';
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';

const HOST = process.env.REACT_APP_SOCKET_HOST || 'localhost:3030';

const axiosInstance = axios.create({
  baseURL: HOST
});

axiosInstance.interceptors.request.use(function (config) {
  const jwtToken = localStorage.getItem('feathers-jwt');
  config['headers']['Authorization'] = `JWT ${jwtToken}`;
  return config;
});

setupCache(axiosInstance, {
  storage: buildWebStorage(localStorage, 'local-cache')
});

export default axiosInstance;
