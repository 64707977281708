import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { keyframes } from '@mui/system';
import useGameLogo from 'hooks/useGameLogo';
import { useTheme } from '@mui/material/styles';

export default function Games(props) {
  const { isEnabled, gameType, gameBalance, onClick, name } = props;
  const { getGameImagePath } = useGameLogo();
  const theme = useTheme();

  const balanceAnimation = useMemo(
    () => {
      return keyframes`
        from, to { transform: translate(-50%, -55%) scale(1, 1); }
        25% { transform: translate(-50%, -55%) scale(0.9, 1.1); }
        50% { transform: translate(-50%, -55%) scale(1.1, 0.9); }
        75% { transform: translate(-50%, -55%) scale(0.95, 1.05); }
      `;
    }, []
  );

  return (
    <Box
      onClick={onClick}
      className='game-button'
      sx={{
        ...(
          !isEnabled && {
            filter: 'grayscale(100%)'
          }
        ),
        position: 'relative',
        textAlign: 'center',
        //backgroundImage: 'url(/images/bg-games/golden_hex/base.png)',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: { xs: theme.spacing(12), sm: theme.spacing(16), md: theme.spacing(20) },
        height: { xs: theme.spacing(12), sm: theme.spacing(16), md: theme.spacing(20) },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}>
      <Box
        className='game-button-label'
        component='box'
        variant='box'
        sx={{
          //backgroundImage: 'url(/images/bg-games/golden_hex/label.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          position: 'absolute',
          width: '100%',
          left: '50%',
          bottom: 0,
          zIndex: 19,
          transform: 'translate(-50%, 0%)',
        }}
      >
        <Typography
          className='game-button-label-text'
          variant='subtitle2'
          sx={{
            fontWeight: 700,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
            color: 'white',
            textShadow: '2px 2px 2px #000',
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box
        className='game-button-image'
        component='img'
        src={getGameImagePath(gameType)}
        alt={`${gameType} logo`}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '50%',
          transform: 'translate(-50%, -55%)',
          zIndex: 20,
          ...(
            isEnabled && !!gameBalance && {
              animation: `${balanceAnimation} 0.5s infinite linear`
            }
          ),
        }}
      />
    </Box>
  );
}
