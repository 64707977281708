import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { get, filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import AndroidIcon from '@mui/icons-material/AndroidTwoTone';
import AppleIcon from '@mui/icons-material/Apple';
import WebIcon from '@mui/icons-material/WebTwoTone';
import DesktopIcon from '@mui/icons-material/DesktopWindowsTwoTone';
import Alert from '@mui/material/Alert';
import useFeathers from 'hooks/useFeathers';
import LinearProgress from '@mui/material/LinearProgress';
/*import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';*/

export default function DownloadDialog(props) {
  const { game, lang, onClose } = props;
  const [ open, setOpen ] = useState(false);
  const gameType = get(game, 'type', '');
  const { t } = useTranslation();
  const { data, ready } = useFeathers('game-urls', { query: { type: gameType, $limit: -1 }});

  const downloadUrls = useMemo(
    () => {
      return filter(data, { isEnabled: true });
    }, [data]
  );

  useEffect(() => {
    setOpen(game ? true : false);
  }, [game]);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (url) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  function getIconByType(type) {
    if (type === 'android') return <AndroidIcon />;
    else if (type === 'ios') return <AppleIcon />;
    else if (type === 'web') return <WebIcon />;
    return <DesktopIcon />;
  }

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>
        {
          t('Game Applications')
        }
      </DialogTitle>
      <DialogContent dividers>
        {
          !ready &&
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        }
        <Grid container spacing={2}>
          {
            downloadUrls.map(dl => {
              const _id = get(dl, '_id');
              const name = get(dl, `name.${lang}`, '');
              const url = get(dl, 'url');
              const appType = get(dl, 'appType');
              const isEnabled = get(dl, 'isEnabled');
              if (!isEnabled) return null;

              return (
                <Grid item xs={12} key={_id}>
                  <Button
                    size='large'
                    fullWidth
                    color='info'
                    variant='contained'
                    onClick={() => handleListItemClick(url)}
                    startIcon={getIconByType(appType)}
                    sx={{ justifyContent: 'flex-start' }}
                  >
                    {name}
                  </Button>
                </Grid>
              );
            })
          }
        </Grid>
        {
          !downloadUrls.length &&
          <Box sx={{ mt: 2}}>
            <Alert severity="info">{t('No record')}</Alert>
          </Box>
        }
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} autoFocus>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DownloadDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  game: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};
