import React, { useContext, useEffect, useState, useMemo } from 'react';
import { _CASH_SYMBOL, _POINT_SYMBOL } from 'utils/symbol';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Decimal from 'decimal.js';
import CommonContext from 'features/context/commonContext';
import CountUp from 'react-countup';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function BalanceBar(props) {
  const theme = useTheme();
  const isMediumSize = useMediaQuery('(min-width:600px)');
  const [cashBalance, setCashBalance] = useState({ start: 0, end: 0 });
  const [pointBalance, setPointBalance] = useState({ start: 0, end: 0 });
  const { t } = useTranslation();

  const { wallet, walletReady } = useContext(CommonContext);

  const topbarHeight = useMemo(
    () => {
      const { mixins } = theme;
      let ret;
      if (isMediumSize) {
        ret = get(mixins, 'toolbar.[@media (min-width:600px)].minHeight', 100);
      } else {
        ret = get(mixins, 'toolbar.minHeight', 100);
      }
      return ret;
    }, [isMediumSize, theme]
  );

  useEffect(() => {
    if (!walletReady) return;

    const cashBalStr = get(wallet, 'cashBalance.$numberDecimal', '0');
    const cashBal = new Decimal(cashBalStr);
    setCashBalance(prev => ({
      start: prev?.end,
      end: cashBal
    }));

    const pointBalStr = get(wallet, 'pointBalance.$numberDecimal', '0');
    const pointBal = new Decimal(pointBalStr);

    setPointBalance(prev => ({
      start: prev?.end,
      end: pointBal
    }));
  }, [wallet, walletReady]);

  return (
    <Box sx={{ zIndex: 40 }}>
      <Paper elevation={16} square sx={{
          py: 1,
          position: 'fixed',
          left: 0,
          top: topbarHeight,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: isMediumSize ? 'right' : 'center',
          px: isMediumSize ? 1 : 0
        }} id="balanceBar">
        <Stack direction='row' spacing={1}>
          <Paper sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 2 }}>
            <Typography variant='overline' sx={{ color: 'text.secondary', mr: .5 }}>
              {`${t('Point')} ${_POINT_SYMBOL}`}
            </Typography>
            <CountUp
              start={pointBalance.start}
              end={pointBalance.end}
              duration={0.88}
              separator=" "
              decimals={2}
              decimal="."
              prefix=''
              suffix=''
            >
              {({ countUpRef, start }) => (
                <Typography variant={ isMediumSize ? 'h5' : 'body1' } ref={countUpRef} />
              )}
            </CountUp>
          </Paper>
          <Paper sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 2 }}>
            <Typography variant='overline' sx={{ color: 'text.secondary', mr: .5 }}>
              {`${t('Cash')} ${_CASH_SYMBOL}`}
            </Typography>
            <CountUp
              start={cashBalance.start}
              end={cashBalance.end}
              duration={0.88}
              separator=" "
              decimals={2}
              decimal="."
              prefix=''
              suffix=''
            >
              {({ countUpRef, start }) => (
                <Typography variant={ isMediumSize ? 'h5' : 'body1' } ref={countUpRef} />
              )}
            </CountUp>
          </Paper>
          {/* <CountUp
            start={pointBalance.start}
            end={pointBalance.end}
            duration={0.88}
            separator=" "
            decimals={2}
            decimal="."
            prefix={`${_POINT_SYMBOL} `}
            suffix=''
          >
            {({ countUpRef, start }) => (
              <Paper variant='outlined' sx={{ px: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant={ isMediumSize ? 'h5' : 'h6' } ref={countUpRef} />
              </Paper>
            )}
          </CountUp>
          <CountUp
            start={cashBalance.start}
            end={cashBalance.end}
            duration={0.88}
            separator=" "
            decimals={2}
            decimal="."
            prefix={`${_CASH_SYMBOL} `}
            suffix=''
          >
            {({ countUpRef, start }) => (
              <Paper variant='outlined' sx={{ px: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant={ isMediumSize ? 'h5' : 'h6' } ref={countUpRef} />
              </Paper>
            )}
          </CountUp> */}
        </Stack>
      </Paper>
    </Box>
  );
}
