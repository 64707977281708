import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCheckAppVersion } from 'hooks/useCheckAppVersion';

/*
  Get latest version from public/version.json
  If update is available, display dialog for user to refresh page
*/
const CheckAppVersion = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const { updateAvailable = false } = useCheckAppVersion() || {};

  useEffect(() => {
    setOpen(updateAvailable);
  }, [updateAvailable]);

  const handlePageRefresh = () => {
    window.location.reload(true);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>{t('Update Available')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
            t('Refresh to update')
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePageRefresh}
          color="primary"
          autoFocus
        >
          {t('Refresh')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckAppVersion;
