import React, { useContext, useMemo, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import CommonContext from 'features/context/commonContext';
import { get } from 'lodash';

export default function BankReminderDialog(props) {
  const { wallet, walletReady } = useContext(CommonContext);
  const [ open, setOpen ] = useState(false);
  const [ skipPrompt, setSkipPrompt ] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const routePath = '/account';

  const forcePrompt = useMemo(
    () => {
      return get(location, 'state.lastBankReminderPrompt', null);
    }, [location]
  );

  const skipRouting = useMemo(
    () => {
      const ret = get(location, 'pathname') === routePath ? true : false;
      return ret;
    }, [location]
  );

  const clientBankIsSet = useMemo(
    () => {
      return !!(get(wallet, 'savedBank'));
    }, [wallet]
  );

  useEffect(
    () => {
      if (!walletReady) return; // no prompt before wallet data is ready
      if (clientBankIsSet) return; // client bank is saved
      if (skipPrompt) return; // user ignored
      if (skipRouting) return; // current route equals to target route
      setOpen(true);
    }, [clientBankIsSet, walletReady, skipRouting, skipPrompt]
  );

  useEffect(
    () => {
      if (!forcePrompt) return;
      setOpen(true);
    }, [forcePrompt]
  );

  function handleClose() {
    setOpen(false);
    setSkipPrompt(true);
  }

  function handleClicked(event) {
    event.preventDefault();
    setOpen(false);
    navigate(routePath, { replace: false });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {t('Reminder')}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t(`You havent't set your bank account yet.`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' variant='contained' onClick={handleClose}>
          {t('Close')}
        </Button>
        <Button variant='contained' onClick={handleClicked} autoFocus>
          {t('Set Now')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
