import React from 'react';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import copyToClipboard from 'utils/copy-to-clipboard';
import {
  Box,
} from '@mui/material';

export default function CustomCopyToClipboard({ children, text, copiedText = 'Copied' }) {
  const { setGlobalMessage } = useGlobalMessageActionsContext();
  const { t } = useTranslation();

  function handleOnCopy() {
    copyToClipboard(text);
    setGlobalMessage({
      message: t(copiedText),
      severity: 'info',
      duration: 1000
    })
  }

  return (
    <Box onClick={handleOnCopy}>
      {children}
    </Box>
  );
}
